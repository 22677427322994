<template>
  <div class="w-full h-full">
    <file-cover
      :community-path="communityPath"
      :community="community"
      :access="access"
      :file="file"
      file-type="audio"
      :file-url="fileUrl"
      :force-visible="false"
      @play="play"
      :subscribers-only="subscribersOnly"
      :file-price="filePrice"
    />

    <audio
      ref="audioEl"
      v-if="fileUrl"
      :src="fileUrl"
      class="hidden w-0 h-0"
    />
    <div
      v-show="started"
      class="absolute top-0 flex justify-center w-full"
      :class="delayedStart ? 'gret-visible-3s': 'gret-invisible-3s'"
    >
      <div class="w-full pt-20 sm:w-2/3 ">
        <div
          class="w-full bg-cover bg-gret-gray aspect-w-16 aspect-h-9"
          :class="delayedStart ? 'gret-visible-3s': 'gret-invisible-3s'"
          :style="file.thumbnail.large.url && { backgroundImage: `url(${file.thumbnail.large.url})` }"
        >
          <div
            class="flex items-end flex-1 w-full"
          >
            <div class="flex items-center w-full px-2 py-2 bg-opacity-50 sm:px-5 bg-gradient-to-t from-gret-gray-dark">
              <div
                @click="play"
                class="flex items-center justify-center h-8 mr-4 rounded-lg cursor-pointer hover:bg-gret-black sm:h-10 sm:w-10 sm:mr-6"
              >
                <img
                  v-svg-inline
                  viewBox="0 0 24 24"
                  class="w-8 h-8 text-white fill-current"
                  :src="require(`../../assets/images/${playingIcon}.svg`)"
                >
              </div>
              <div class="relative flex flex-row items-center flex-1 w-full">
                <div class="w-16 text-sm text-left text-gret-text-gray sm:text-base sm:text-center sm:w-20">
                  {{ formattedPosition }}
                </div>
                <div
                  class="flex items-center flex-1 w-full h-8 cursor-pointer sm:mx-4"
                  @mouseenter="startPreSeek"
                  @mouseleave="finishPreSeek"
                  @mousedown="startSeek"
                  @mouseup="finishSeek"
                  @mousemove="updateSeekPosition"
                  @touchstart="startSeek"
                  @touchend="finishSeek"
                  @touchmove="updateSeekPosition"
                >
                  <div class="relative w-full h-1 bg-gray-400 rounded-full pointer-events-none">
                    <div
                      v-if="preSeeking && duration > 0"
                      class="absolute seek-tooltip"
                      :style="{ left: seekPositionAsPercentage }"
                    >
                      {{ formattedSeekPosition }}
                    </div>
                    <div
                      v-if="duration > 0"
                      class="absolute z-10 h-full rounded-full bg-gret"
                      :style="{ width: seeking ? seekPositionAsPercentage : positionAsPercentage }"
                    />
                    <div
                      class="absolute z-30 flex items-center justify-center w-4 h-4 bg-white rounded-full shadow-md cursor-pointer scrubber"
                      :style="{ left: seeking ? seekPositionAsPercentage : positionAsPercentage }"
                    >
                      <div class="w-2 h-2 rounded-full" />
                    </div>
                  </div>
                </div>
                <div class="w-16 text-sm text-right text-gret-text-gray sm:text-base sm:text-center sm:w-20">
                  {{ formattedDuration }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <file-content
      :file="file"
      :long-description="longDescription"
      :questions="questions"
    />
  </div>
</template>

<script>
import { formatHHMMSS, formatMMSS } from '../utils/format-hhmmss';
import playbackTracking from '../mixins/playback-tracking';
import FileContent from './file-content';
import FileCover from './file-cover';

export default {
  name: 'AudioPlayer',
  mixins: [playbackTracking],
  components: {
    FileContent,
    FileCover,
  },
  props: {
    communityPath: {
      type: String,
      default: null,
    },
    community: {
      type: Object,
      default: null,
    },
    audio: {
      type: Object,
      required: true,
    },
    longDescription: {
      type: Object,
      default: null,
    },
    questions: {
      type: Object,
      default: null,
    },
    fileUrl: {
      type: String,
      default: null,
    },
    subscribersOnly: {
      type: Boolean,
      default: false,
    },
    filePrice: {
        type: String,
        default: '',
      },
  },
  data() {
    return {
      started: false,
      delayedStart: false,
      playing: false,
      position: 0,
      duration: 0,
      seekPosition: 0,
      preSeeking: false,
      seeking: false,
    };
  },
  mounted() {
    if (this.$refs.audioEl) {
      this.$refs.audioEl.addEventListener('play', () => {
        this.playing = true;
        if (this.trackPlaybackMetrics) this.setupPlaybackTrackers();
      });

      this.$refs.audioEl.addEventListener('pause', () => {
        this.playing = false;
        if (this.trackPlaybackMetrics) this.clearStreamTimeoutAndRecalculateTimeLeftForStream();
      });

      this.$refs.audioEl.addEventListener('timeupdate', (event) => {
        this.position = event.target.currentTime;
      });

      this.$refs.audioEl.addEventListener('durationchange', (event) => {
        this.duration = event.target.duration;
      });
    }
  },
  methods: {
    play() {
      if (this.fileUrl) {
        if (this.$refs.audioEl.paused) {
          this.started = true;
          this.$refs.audioEl.play();
        } else {
          this.$refs.audioEl.pause();
        }
      }
    },
    updateSeekPosition(event) {
      const width = event.target.clientWidth;
      let offsetX;

      if (event.type === 'mousemove') {
        offsetX = event.offsetX;
      } else if (event.type === 'touchmove') {
        const rect = event.target.getBoundingClientRect();
        offsetX = event.targetTouches[0].pageX - rect.left;
      }

      const pos = Math.min(width, Math.max(0, offsetX));
      this.seekPosition = (pos / width) * this.duration;
    },
    startPreSeek() {
      this.preSeeking = true;
    },
    finishPreSeek() {
      this.preSeeking = false;
    },
    startSeek() {
      this.preSeeking = true;
      this.seeking = true;
      this.$refs.audioEl.pause();
    },
    finishSeek() {
      this.$refs.audioEl.currentTime = this.seekPosition;
      this.$refs.audioEl.play();
      setTimeout(() => {
        this.preSeeking = false;
        this.seeking = false;
      /* eslint-disable-next-line no-magic-numbers */
      }, 200);
    },
  },
  computed: {
    access() {
      return !!this.fileUrl;
    },
    forceVisible() {
      return !this.fileUrl;
    },
    hourOrLonger() {
      const HOUR = 3600;

      return this.duration > HOUR;
    },
    formatter() {
      return this.hourOrLonger ? formatHHMMSS : formatMMSS;
    },
    positionAsPercentage() {
      /* eslint-disable-next-line no-magic-numbers */
      return `${(this.position / this.duration) * 100}%`;
    },
    seekPositionAsPercentage() {
      /* eslint-disable-next-line no-magic-numbers */
      return `${(this.seekPosition / this.duration) * 100}%`;
    },
    formattedSeekPosition() {
      return this.formatter(this.seekPosition);
    },
    formattedPosition() {
      return this.formatter(this.position);
    },
    formattedDuration() {
      return this.formatter(this.duration);
    },
    file() {
      return { ...this.audio, info: JSON.parse(this.audio.audioData), type: 'audio' };
    },
    playingIcon() {
      return this.playing ? 'pause' : 'play_arrow';
    },
  },
  watch: {
    started(newStarted) {
      const delay = 500;
      setTimeout(() => {
        this.delayedStart = newStarted;
      }, delay);
    },
  },
};
</script>

<style>
.seek-tooltip {
  @apply text-white text-sm font-medium px-2 py-1 rounded-md shadow;
  background-color: rgba(0, 0, 0, .8);
  transform: translateX(-50%);
  bottom: 16px;
}

.scrubber {
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.audio-thumbnail {
  max-height: 250px;
  max-width: 250px;
}
</style>
