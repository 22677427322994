<template>
  <div>
    <div
      class="z-10 flex flex-col-reverse sm:flex-row"
      :class="visible || forceVisible ? 'gret-visible' : 'gret-invisible'"
    >
      <div
        class="sm:w-1/3"
        v-if="breakpoints.is !== 'xs' || visible || forceVisible"
      >
        <div
          class="w-full h-full px-2 pt-2 md:pl-8 bg-gret-gray bg-gradient-to-b from-gret-black sm:from-gret-gray-dark sm:bg-gradient-to-r"
        >
          <div class="flex flex-col justify-center h-full px-3 pb-4">
            <div
              class="flex flex-row"
              v-if="subscribersOnly"
            >
              <div
                class="inline-flex items-center h-6 px-2 text-xs font-medium text-white uppercase align-middle rounded-sm bg-gret sm:mb-2"
              >
                {{ $t('message.files.subscribersOnly') }}
              </div>
            </div>
            <div class="flex items-center mb-8 font-medium text-sm">
              <a :href="communityPath"><div class="flex-1 text-gret-text-gray hover:text-white whitespace-no-wrap">{{ community.name }}</div></a>
              <div class="px-2 text-lg text-gret-text-gray sm:px-3">&rsaquo;</div>
              <div class="flex-1 text-white whitespace-no-wrap">{{ file.title }}</div>
            </div>
            <div class="flex justify-center p-1 text-xs font-semibold capitalize items-center bg-gret-label text-center text-white w-1/4 rounded-sm">
              <gret-svg 
                :icon="fileType == 'text' ? 'feed' : fileType == 'service' ? 'service' : fileType == 'video' ? 'Play2' : fileType"
                class="pr-1 h-3/4"
              />
              {{ fileType == "text" ? 'Archivo' : fileType == "service" ? 'Servicios' : fileType == "product" ? 'Productos' : fileType }}
            </div>
            <div class="h-auto text-xl font-semibold tracking-wide text-white sm:z-10 sm:text-shadow-lg sm:w-3/5-screen sm:mt-6 md:text-xl lg:text-2xl xl:text-4xl">
              {{ file.title }}
            </div>
            <div
              v-if="serie"
              class="self-start mt-2 mb-1 text-white md:mt-5 sm:text-md md:text-base lg:text-xl xl:text-2xl font-semi-bold"
            >
              {{ episodesMessage }}
            </div>
            <div
              v-if="file.subtitle"
              class="self-start mt-2 mb-1 text-white md:mt-5 sm:z-10 sm:text-shadow-lg sm:w-3/5-screen sm:text-md md:text-base lg:text-xl xl:text-2xl font-semi-bold"
            >
              {{ file.subtitle }}
            </div>
            <div
              v-if="file.description "
              class="mt-2 text-sm md:mt-5 md:text-base sm:z-10 sm:text-shadow-lg sm:w-3/5-screen lg:text-md xl:text-xl text-white line-clamp-5 lg:line-clamp-6"
            >
              {{ file.description }}
            </div>
            <div
              v-if="file.creator"
              class="mt-2 text-sm md:mt-5 md:text-base sm:z-10 sm:text-shadow-lg sm:w-3/5-screen lg:text-base xl:text-base text-white line-clamp-5 lg:line-clamp-6"
            >
              Creado por:<label class="font-semibold leading-5 tracking-wider text-gret-label"> {{ file.creator }} </label>
            </div>
            <div
              v-if="fileType == 'audio' | fileType == 'video' | fileType == 'text'"
              class="flex items-center self-start mt-2 text-sm font-medium text-white md:mt-5 sm:font-bold lg:text-md xl:text-lg"
            >
              <gret-svg 
                :icon="fileType == 'text' ? 'folder' : 'clock'"
                class="mr-2"
              />
              {{ formatFileInfo(file) }}
            </div>
            <div
              v-if="filePrice && file.privacy === 'manual_authorization'"
              class="flex items-center self-start mt-2 text-sm font-bold text-white md:mt-5 sm:font-bold lg:text-2xl xl:text-3xl"
            >
              $ {{ filePrice }}
            </div>
            <div
              :class="downloadable ? 'flex w-full sm:w-4/5 grid-cols-1 gap-2' : 'grid w-full sm:w-4/5 grid-cols-1 gap-2'"
            >
              <button
                v-if="showPlay & (fileType == 'audio' | fileType == 'video' | fileType == 'text')"
                class="inline-flex flex-row items-center justify-between w-full h-12 px-2 mt-6 rounded cursor-pointer opacity-90 hover:opacity-100 gret-button download sm:w-3/5 sm:h-2/3"
                @click="play"
              >
                <div
                  class="flex-1 font-medium text-center text-white normal-case xl:text-lg"
                >
                  {{ fileType == "video" ? "VER AHORA" : fileType == "audio" ? "REPRODUCIR" : "VISUALIZAR"}}
                </div>
              </button>
              <file-download-button
                v-if="downloadable & fileType == 'text'"
                :file-url="fileUrl"
                :key="file.id"
                :download-event-identifier="downloadEventIdentifier"
              />
              <button
                v-if="showPlay & (fileType == 'service' | fileType == 'product')"
                class="inline-flex flex-row items-center justify-between w-full h-12 px-2 mt-6 rounded cursor-pointer opacity-90 hover:opacity-100 gret-button download md:w-3/5 md:h-2/3"
                @click="want"
                >
                <div
                  class="flex-1 font-medium text-center text-white normal-case xl:text-lg"
                >
                  LO QUIERO
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="relative sm:w-2/3">
        <lazy-image
          class="w-full h-full aspect-w-16 aspect-h-9"
          :blurhash="file.blurhash"
          :src="file.thumbnail.large.url"
          :webp-src="file.thumbnail.webpLarge.url"
          alt="file cover"
        />
        <div class="absolute top-0 grid w-full h-full grid-cols-12 gap-0">
          <div class="w-full col-start-1 col-span-3 sm:bg-gradient-to-r from-gret-gray to-transparent" />
          <div class="w-full col-span-9 sm:bg-transparent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breakpoints from '../utils/breakpoints';
import fileIcons from '../../assets/json/file_icons.json';
import formatYear from '../utils/format-year';
import formatDuration from '../utils/format-duration';
import formatFileSize from '../utils/format-filesize';
import FileDownloadButton from './file-download-button.vue';
import GretSvg from './gret-svg.vue';

export default {
  name: 'FileCover',
  components: {
    FileDownloadButton,
    GretSvg,
  },
  data() {
    return {
      breakpoints,
      visible: true,
    };
  },
  props: {
    access: {
      type: Boolean,
      default: false,
    },
    communityPath: {
      type: String,
      default: null,
    },
    community: {
      type: Object,
      default: null,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    downloadEventIdentifier: {
      type: String,
      default: 'file_download',
    },
    file: {
      type: Object,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
    fileUrl: {
      type: String,
      default: null,
    },
    forceVisible: {
      type: Boolean,
      default: false,
    },
    playing: {
      type: Boolean,
      default: false,
    },
    showPlay: {
      type: Boolean,
      default: true,
    },
    serie: {
      type: Boolean,
      default: false,
    },
    filePrice: {
      type: String,
      default: '',
    },
    subscribersOnly: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    icons() {
      return fileIcons;
    },
    buttonMessage() {
      let message = this.$t(`message.files.${this.fileType}.play`);
      if (!this.downloadable) {
        message = `${message} ${this.$t('message.files.now')}`;
      }

      return message;
    },
    episodesMessage() {
      if (this.serie && this.fileType) {
        return this.$tc(`message.files.${this.fileType}.episodes`,
          this.file.episodesCount,
          { count: this.file.episodesCount });
      } else if (this.serie) {
        return this.$t('message.series.noEpisodes');
      }

      return '';
    },
  },
  methods: {
    formatFileSize,
    formatYear,
    formatDuration,
    formatYearSize(file) {
      if (file.info && file.info.metadata && file.info.metadata.size) {
        return `${formatFileSize(file.info.metadata.size)}`;
      }

      return this.formatYear(file.created_at);
    },
    formatYearDuration(file) {
      if (file.duration) return `${this.formatDuration(file.duration)}`;

      return this.formatYearSize(file);
    },
    formatFileInfo(file) {
      if (this.serie) return formatYear(file);
      else if (this.fileType === 'text') return this.formatYearSize(file);

      return this.formatYearDuration(file);
    },
    play() {
      if (this.serie) return;
      if (this.access) {
        this.visible = false;
        this.$emit('play');
      } else {
        this.$root.$emit('showAccessModal');
      }
    },
    want() {
      if (this.serie) return;
      if (this.access) {
        //hacer algo cuando pueda acceder
        console.log("acceder")
        console.log("file", this.file, this.community, this.filePrice);
      } else {
        this.$root.$emit('showAccessModal');
      }
    },
  },
};
</script>
