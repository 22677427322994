<template>
  <div class="grid gap-x-4 md:grid-cols-2">
    <template
      v-if="showForm"
    >
      <serie-form-fields
        :file-type="fileType"
        :require-payment-options="requirePaymentOptions"
        :privacy-options="privacyOptions"
        :payment-type-options="paymentTypeOptions"
        :initial-phone-number="initialPhoneNumber"
        :enable-phone-number-change="enablePhoneNumberChange"
        :visible-by-consumers="visibleByConsumers"
        @close="close"
      >
        <slot />
      </serie-form-fields>
      <input
        v-for="fileId in selectedFilesIds"
        :key="`hidden-file-id-${fileId}`"
        type="hidden"
        :value="fileId"
        :name="filesIdsName"
      >
      <input
        type="hidden"
        :value="fileType"
        :name="fileTypeName"
      >
    </template>
    <draggable
      v-else
      class="col-span-1 md:col-start-2 md:row-start-1"
      :list="unselectedFiles"
      group="files"
    >
      <managership-file
        v-for="(file, id) in unselectedFiles"
        :key="`unselected-${id}`"
        :file="file"
        :icon="fileIcon"
        shake
      />
    </draggable>
    <div
      class="h-auto col-span-1 row-start-1 mt-2 overflow-y-auto rounded-md md:min-h-2/3-screen md:max-h-max disabled md:col-start-1 md:mt-8 bg-gret bg-opacity-10"
      :class="showForm || 'border border-white '"
    >
      <div
        v-if="showForm"
        class="flex justify-end"
        @click="edit"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" v-svg-inline="" v-bind:class="'w-8 h-8 p-1 ml-2 fill-current text-gret-text-gray'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
      </div>
      <div
        v-else
        class="flex justify-end"
        @click="close"
      >
        <img
          inline-svg
          class="w-8 h-8 ml-2 text-white fill-current"
          src="../../assets/images/close.svg"
        >
      </div>
      <draggable
        class="overflow-y-auto"
        :class="showForm ||'h-5/6'"
        :list="selectedFiles"
        group="files"
        :options="{animation: 500}"
      >
        <managership-file
          v-for="(file, id) in selectedFiles"
          :key="`selected-${id}`"
          :id="id"
          :file="file"
          :icon="fileIcon"
          selected
        />
        <div
          v-if="!showDragMessage"
          class="flex flex-col justify-center w-full py-2 text-base text-center text-white md:py-8 md:text-lg"
        >
          <img
            :src="require('../../assets/images/drag.svg')"
            class="h-6 my-2 "
          >
          {{ $t('message.series.drag') }}
        </div>
      </draggable>
      <div
        v-if="showNextButton"
        class="w-full justify-self-end"
      >
        <div
          class="flex items-center justify-center w-auto px-4 py-2.5 my-4 text-white rounded-md cursor-pointer  mx-2 md:w-auto bg-gret hover:bg-gret"
          @click="next"
        >
          <div class="flex items-center justify-center">
            <div>{{ $t('message.series.create', { fileType: $t(`message.files.${fileType}.type`)}) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import ManagershipFile from './managership-file.vue';
import SerieFormFields from './serie-form-fields.vue';

export default {
  name: 'SerieForm',
  components: {
    draggable,
    ManagershipFile,
    SerieFormFields,
  },
  props: {
    fileType: { type: String, default: null },
    files: { type: Array, default: () => [] },
    filesIdsName: { type: String, default: null },
    fileTypeName: { type: String, default: null },
    requirePaymentOptions: { type: Array, required: true },
    privacyOptions: { type: Array, required: true },
    paymentTypeOptions: { type: Array, required: true },
    initialPhoneNumber: { type: String, default: '' },
    enablePhoneNumberChange: { type: Boolean, default: false },
    editCommunityPath: { type: String, default: '' },
    visibleByConsumers: { type: Boolean, required: true },
  },
  data() {
    return {
      unselectedFiles: [...this.files],
      selectedFiles: [],
      fileIcons: { video: 'videocam', audio: 'mic', text: 'feed', service: 'service', product: 'product' },
      showForm: false,
      title: '',
      description: '',
      thumbnail: null,
    };
  },
  computed: {
    fileIcon() {
      return this.fileType ? this.fileIcons[this.fileType] : null;
    },
    noSelectedFiles() {
      return !this.selectedFiles.length;
    },
    showNextButton() {
      return this.selectedFiles.length > 1 && !this.showForm;
    },
    showDragMessage() {
      return !!this.selectedFiles.length;
    },
    selectedFilesNumber() {
      return this.selectedFiles.length;
    },
    unselectedFilesNumber() {
      return this.unselectedFiles.length;
    },
    selectedFilesIds() {
      return this.selectedFiles.map((file) => (file.id));
    },
  },
  methods: {
    submit(serie) {
      this.title = serie.title;
      this.description = serie.description;
      this.thumbnail = serie.thumbnail;
      this.createNewSerie();
    },
    close() {
      this.$emit('close');
    },
    edit() {
      this.showForm = false;
    },
    next() {
      this.showForm = true;
    },
  },
  watch: {
    fileType() {
      this.showForm = false;
      this.unselectedFiles = [...this.files];
      this.selectedFiles = [];
    },
    selectedFilesNumber() {
      this.selectedFiles = this.selectedFiles.filter(Boolean);
    },
    unselectedFilesNumber() {
      this.unselecteFiles = this.unselectedFiles.filter(Boolean);
    },
  },
};
</script>
