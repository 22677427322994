<template>
  <div class="flex flex-col items-center w-full min-h-screen px-8 pt-32 pb-8 bg-gret-gray-dark">
    <div class="w-full max-w-screen-lg">
      <a
        class="inline-flex text-gray-300"
        :href="backUrl"
      >
        <i class="mr-2 material-icons">
          keyboard_arrow_left
        </i>
        Volver
      </a>
      <div
        class="space-y-2 md:space-x-2 md:space-y-0 md:flex-row md:flex"
        v-if="enablePayoutOptions"
      >
        <div class="flex flex-col justify-between space-y-2 md:space-y-1 md:w-2/3">
          <PayoutInformation
            :title-text="$t('message.payouts.currentPayout')"
            :payments="payments"
            :community-id="communityId"
            :currency="country ? country.currency : null"
            :country-id="country ? country.id : null"
            :last-payout="lastPayout"
          />
        </div>
        <ShowBankAccountInfo
          class="md:w-1/3"
          :bank-account-information="bankAccountInformation"
          :country="country"
        />
      </div>
      <div class="mt-12 text-3xl font-bold text-white">
        {{ $t('message.paymentList.movements') }}
      </div>
      <div
        v-if="orderedPayments.length === 0"
        class="px-4 py-2 mt-5 space-y-2 text-center text-white rounded-lg md:space-x-4 md:space-y-0 bg-gret-gray"
      >
        {{ $t('message.payments.noMovements') }}
      </div>
      <div class="mt-5 space-y-3">
        <payment-list-item
          v-for="payment in orderedPayments"
          :key="payment.id"
          :payment="payment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import PaymentListItem from './payment-list-item.vue';
import PayoutInformation from './payout-information.vue';
import ShowBankAccountInfo from './show-bank-account-info.vue';

const enablePayoutOptions = process.env.ENABLE_PAYOUT_OPTIONS === 'true';
export default {
  components: { PaymentListItem, ShowBankAccountInfo, PayoutInformation },
  props: {
    payments: { type: Array, required: true },
    backUrl: { type: String, required: true },
    communityId: { type: Number, required: true },
    country: { type: Object, default: null },
    bankAccountInformation: { type: Object, default: null },
    lastPayout: { type: Object, default: null },
  },
  data() {
    return ({
      enablePayoutOptions,
      errors: null,
      loading: false,
    });
  },
  computed: {
    orderedPayments() {
      return [...this.payments].sort((a, b) => moment(b.createdAt).diff(a.createdAt));
    },
  },
};
</script>
