<template>
  <div
    class="h-auto col-span-1 row-start-1 mt-2 overflow-y-auto border border-white rounded-md md:col-start-2 md:min-h-2/3-screen md:max-h-max disabled md:mt-8 bg-gret bg-opacity-10"
  >
    <input
      v-for="episodeId in hiddenEpisodesIds"
      :key="`episode-id-${episodeId}`"
      ref="hiddenInput"
      type="hidden"
      :value="episodeId"
      :name="name"
    >
    <draggable
      class="overflow-y-auto"
      :list="orderedEpisodes"
      group="episodes"
      :options="{animation: 500}"
    >
      <managership-file
        v-for="(file, id) in orderedEpisodes"
        :key="`selected-${id}`"
        :id="id"
        :file="file"
        :icon="fileIcon"
        selected
      />
    </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import ManagershipFile from './managership-file.vue';

export default {
  name: 'SerieFormEpisodes',
  components: {
    draggable,
    ManagershipFile,
  },
  props: {
    fileType: { type: String, default: null },
    episodes: { type: Array, default: () => [] },
    name: { type: String, default: null },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      fileIcons: { video: 'videocam', audio: 'mic', text: 'feed', service: 'service', product: 'product' },
      orderedEpisodes: [...this.episodes],
      hiddenEpisodesIds: [],
    };
  },
  computed: {
    fileIcon() {
      return this.fileType ? this.fileIcons[this.fileType] : null;
    },
    orderedEpisodesNumber() {
      return this.orderedEpisodes.length;
    },
  },
  mounted() {
    this.hiddenEpisodesIds = this.orderedEpisodes.map((file) => (file.id));
  },
  watch: {
    orderedEpisodesNumber() {
      this.orderedEpisodes = this.orderedEpisodes.filter(Boolean);
    },
    orderedEpisodes() {
      this.hiddenEpisodesIds = this.orderedEpisodes.map((file) => (file.id));
    },
  },
};
</script>
]
