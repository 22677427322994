<template>
  <div class="md:max-w-lg">
    <div
      class="mb-2 overflow-hidden bg-gray-200 rounded-lg"
      v-for="question in questions"
      :key="question.id"
    >
      <div
        class="flex items-center p-4 font-medium cursor-pointer hover:bg-gray-300 last:mb-0"
        :class="{ 'bg-gray-300': activeQuestion === question }"
        @click="toggleQuestion(question)"
      >
        <div class="flex-1">
          {{ question.question }}
        </div>
        <img
          v-svg-inline
          viewBox="0 0 24 24"
          class="w-8 h-8 text-gray-600 fill-current"
          :src="require(`../../assets/images/${keyboardArrow(question)}.svg`)"
        >
      </div>
      <div
        class="flex-1 p-4 text-gray-700"
        v-if="activeQuestion === question"
      >
        {{ question.answer }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventFaq',
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeQuestion: null,
    };
  },
  methods: {
    toggleQuestion(question) {
      this.activeQuestion = this.activeQuestion === question ? null : question;
    },
    keyboardArrow(question) {
      return this.activeQuestion === question ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
  },
};
</script>
