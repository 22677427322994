<template>
  <div
    class="w-full h-full"
  >
    <file-cover
      :community-path="communityPath"
      :community="community"
      :access="access"
      :file="file"
      file-type="text"
      :downloadable="downloadable"
      :file-url="fileUrl"
      :show-play="previewable"
      @play="preview"
      :download-event-identifier="downloadEventIdentifier"
      :force-visible="!previewing"
      :subscribers-only="subscribersOnly"
      :file-price="filePrice"
    />
    <div
      class="absolute top-0 w-full h-full pt-20 overflow-hidden bg-opacity-50 bg-gret-black"
      :class="previewing ||'invisible'"
    >
      <div
        class="absolute top-0 right-0 z-20 px-1 mt-20 bg-opacity-50 rounded bg-gret-black"
        @click="closePreview()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" v-bind:svg-inline="''" v-bind:class="'w-8 h-8 text-white fill-current'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="#2B3D4F"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
      </div>
      <div
        class="flex flex-col items-center justify-center w-full h-full bg-gret-gray-dark"
      >
        <template v-if="fileUrl && fileType === 'application/pdf'">
          <div
            class="items-center justify-center h-full text-white sm:w-9/12 w-90 pdf-container"
          >
            <pdf
              v-for="page in pdfPages"
              :key="`pdf-page-${page}`"
              :page="page"
              :src="pdfSrc"
              style="width: 100%; margin-top: 10px;"
            />
          </div>
        </template>
        <template v-else-if="fileUrl && fileType.indexOf('image') === 0">
          <div class="flex flex-col justify-center max-h-full p-6 overflow-x-hidden overflow-y-auto sm:h-full">
            <img
              @click="toggleImageZoom"
              :src="fileUrl"
              :class="zoomedImage
                ? 'zoom-out w-auto max-w-full mx-auto'
                : 'zoom-in  w-full h-full object-contain'"
            >
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center justify-center w-full h-full text-white">
            <template v-if="fileUrl">
              <div>No podemos mostrar este archivo directamente en Gret.</div>
              <file-download-button
                :filesize="fileSize"
                :file-url="fileUrl"
                :file-id="fileId"
                :download-event-identifier="downloadEventIdentifier"
              />
            </template>
            <template v-if="!fileUrl">
              <div>Debes ser miembro del portal para ver este archivo.</div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div
      :class="previewing && breakpoints.is === 'xs' && 'hidden'"
    >
      <file-content
        v-if="!previewing"
        :file="file"
        :long-description="longDescription"
        :questions="questions"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import breakpoints from '../utils/breakpoints';
import FileContent from './file-content';
import FileCover from './file-cover';
import FileDownloadButton from './file-download-button.vue';

export default {
  name: 'DocumentViewer',
  components: {
    FileContent,
    FileCover,
    FileDownloadButton,
    pdf,
  },
  data() {
    return {
      breakpoints,
      previewing: false,
      zoomedImage: false,
      pdfSrc: '',
      pdfPages: 0,
    };
  },
  props: {
    subscribersOnly: {
      type: Boolean,
      default: false,
    },
    communityPath: {
      type: String,
      default: null,
    },
    community: {
      type: Object,
      default: null,
    },
    document: {
      type: Object,
      required: true,
    },
    fileTitle: {
      type: String,
      default: null,
    },
    fileName: {
      type: String,
      default: null,
    },
    longDescription: {
      type: Object,
      default: null,
    },
    questions: {
      type: Object,
      default: null,
    },
    fileUrl: {
      type: String,
      default: '',
    },
    fileType: {
      type: String,
      default: null,
    },
    fileSize: {
      type: String,
      default: null,
    },
    downloadable: {
      type: Boolean,
      default: true,
    },
    fileId: {
      type: Number,
      default: null,
    },
    downloadEventIdentifier: {
      type: String,
      default: 'file_download',
    },
    filePrice: {
      type: String,
      default: '',
    },
  },
  methods: {
    preview() {
      this.previewing = true;
    },
    closePreview() {
      this.previewing = false;
    },
    toggleImageZoom() {
      this.zoomedImage = !this.zoomedImage;
    },
  },
  computed: {
    access() {
      return !!this.fileUrl;
    },
    previewable() {
      return (
        this.fileType === 'application/pdf' || this.fileType.indexOf('image') === 0
      );
    },
    file() {
      return { ...this.document, info: JSON.parse(this.document.textDocumentData), type: 'text' };
    },
  },
  created() {
    this.pdfSrc = pdf.createLoadingTask(this.fileUrl);
  },
  mounted() {
    if (this.pdfSrc) {
      this.pdfSrc.promise.then(pdfLoaded => {
        this.pdfPages = pdfLoaded.numPages;
      });
    }
  },
};
</script>

<style>
.zoom-in {
  cursor: zoom-in;
}

.zoom-out {
  cursor: zoom-out;
}

.pdf-container {
  overflow-y: auto;
}

.file-thumbnail {
  max-width: 40vh;
  max-height: 40vh;
}
</style>
