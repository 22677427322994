import api from './index';

export default {
  createSerie(communityId, data) {
    return api({
      method: 'POST',
      url: `/api/v1/communities/${communityId}/series`,
      data,
    });
  },
  deleteSerie(communityId, serieId) {
    return api({
      method: 'DELETE',
      url: `/api/v1/communities/${communityId}/series/${serieId}`,
    });
  },
};
