<template>
  <div class="w-full min-h-screen py-8 pt-32 bg-gret-gray-dark">
    <validation-observer
      slim
      v-slot="{ invalid, handleSubmit }"
    >
      <form
        ref="form"
        :action="submitPath"
        method="post"
        class="max-w-lg mx-auto"
        @submit.prevent="handleSubmit(submitForm)"
      >
        <slot />
        <h1 class="mb-6 text-2xl font-bold tracking-wide text-white">
          {{ title }}
        </h1>
        <payment-form-personal-data-inputs
          :country-options="countryOptions"
          :initial-country-id="initialCountryId"
          :initial-user-name="initialUserName"
          @country-change="getAmountInNewCurrency"
        />
        <input
          type="hidden"
          name="return_url"
          :value="cancelPath"
        >
        <csrf-input />
        <div class="flex flex-col items-end pt-6 border-t border-white">
          <div class="mb-2 text-white">
            Total:
            <span class="text-xl font-bold">
              {{ amount | currencyWithCode(currency) }}
            </span>
          </div>
          <div class="ml-auto">
            <spinner
              v-if="loading"
              class="inline w-4 h-4 mr-2"
            />
            <button
              type="submit"
              class="mr-2 gret-button small"
              key="submit-button"
              :disabled="invalid || loading"
            >
              Pagar con tarjeta
            </button>
            <a
              :href="cancelPath"
              class="text-gret hover:underline"
            >
              Cancelar
            </a>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import PaymentFormPersonalDataInputs from './payment-form-personal-data-inputs.vue';
import Spinner from './spinner.vue';
import exchangesApi from '../api/exchanges';

export default {
  components: { PaymentFormPersonalDataInputs, Spinner },
  props: {
    title: { type: String, required: true },
    countryOptions: { type: Array, required: true },
    initialCountryId: { type: Number, required: true },
    initialAmount: { type: Number, required: true },
    initialCurrency: { type: String, required: true },
    initialUserName: { type: String, required: true },
    submitPath: { type: String, required: true },
    cancelPath: { type: String, required: true },
    contentPriceUsd: { type: Number, required: true },
  },
  data() {
    return {
      amount: this.initialAmount,
      currency: this.initialCurrency,
      loading: false,
    };
  },
  methods: {
    submitForm() {
      if (this.loading) return;

      this.loading = true;

      this.$refs.form.submit();
    },
    async getAmountInNewCurrency(countryId) {
      const { amount, currency } = await exchangesApi.getExchange(countryId, this.contentPriceUsd, 'payin');
      this.amount = amount;
      this.currency = currency;
    },
  },
};
</script>
