import Rails, { $ } from '@rails/ujs';
import Vue from 'vue/dist/vue.esm';
import VueI18n from 'vue-i18n';
import VTooltip from 'v-tooltip';
import Vue2TouchEvents from 'vue2-touch-events';
import linkify from 'vue-linkify';
import Carousel from 'buefy';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import { camelizeKeys } from 'humps';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import App from '../app.vue';
import trixConfig from './trix-config.js';
import '../css/application.css';
import Locales from '../locales/locales.js';

import Dropdown from '../components/dropdown.vue';
import DropdownItem from '../components/dropdown-item.vue';
import EventFaq from '../components/event-faq.vue';
import ContentGallery from '../components/content-gallery.vue';
import ContentAccessModal from '../components/content-access-modal.vue';
import FileDownloadButton from '../components/file-download-button.vue';
import AudioPlayer from '../components/audio-player.vue';
import VideoPlayer from '../components/video-player.vue';
import VideoTranscodingStatus from '../components/video-transcoding-status.vue';
import DocumentViewer from '../components/document-viewer.vue';
import ServiceViewer from '../components/service-viewer.vue';
import ProductViewer from '../components/product-viewer.vue';
import CommunityJoinButton from '../components/community-join-button.vue';
import CommunitySubscribeButton from '../components/community-subscribe-button';
import VideoUploader from '../components/video-uploader.vue';
import ManageFeed from '../components/manage-feed.vue';
import FileUpload from '../components/file-upload.vue';
import SearchBar from '../components/search-bar.vue';
import MembersDownloadWizard from '../components/members-download-wizard.vue';
import ExplainedSelect from '../components/explained-select.vue';
import ExplainedPicker from '../components/explained-picker.vue';
import CommunityInfoBanner from '../components/community-info-banner.vue';
import ConnectPaypalAccount from '../components/connect-paypal-account.vue';
import SubscriptionPlanForm from '../components/subscription-plan-form.vue';
import FileEventsDownloadIconBtn from '../components/file-events-download-icon-btn.vue';
import FileEventsDownloadTextBtn from '../components/file-events-download-text-btn.vue';
import ManagershipFile from '../components/managership-file.vue';
import ManagershipFileNew from '../components/managership-file-new.vue';
import ManagershipFileActionsNew from '../components/managership-file-actions-new.vue';
import Navbar from '../components/navbar.vue';
import ToggleablePasswordInput from '../components/toggleable-password-input.vue';
import SingleSelectButtons from '../components/single-select-buttons.vue';
import SingleSelectButtonsSeries from '../components/single-select-buttons-series.vue';
import ContentFormPrivacyAndPaymentSection from '../components/content-form-privacy-and-payment-section.vue';
import ContentFormPrivacyAndPaymentSectionSeries from '../components/content-form-privacy-and-payment-section-series.vue';
import CommunityPrivacyAndPaymentSection from '../components/community-privacy-and-payment-section.vue';
import FileCover from '../components/file-cover.vue';
import FileContent from '../components/file-content.vue';
import RichTextArea from '../components/rich-text-area.vue';
import GretSvg from '../components/gret-svg.vue';
import SerieForm from '../components/serie-form.vue';
import SerieFormAccessConfiguration from '../components/serie-form-access-configuration.vue';
import SerieFormEpisodes from '../components/serie-form-episodes.vue';
import Serie from '../components/serie.vue';
import CommunityPrivacyToggle from '../components/community-privacy-toggle.vue';
import PaymentToggle from '../components/payment-toggle.vue';
import ManageCommunityPlan from '../components/manage-community-plan.vue';
import ManageUserPlan from '../components/manage-user-plan.vue';
import PoweredBy from '../components/powered-by';
import CsrfInput from '../components/csrf-input';
import PaymentForm from '../components/payment-form';
import DnsConfigShowWithInstructions from '../components/dns-config-show-with-instructions.vue';
import PaymentList from '../components/payment-list';
import DnsConfigCreate from '../components/dns-config-create';
import GretInput from '../components/gret-input';
import LazyImage from '../components/lazy-image';
import ManageFeedFilterDropdown from '../components/manage-feed-filter-dropdown.vue';
import Balance from '../components/balance.vue';
import AddItem from '../components/add-item.vue';
import AddItemModal from '../components/add-item-modal.vue';
import PaymentModal from '../components/payment-modal.vue';
import PaymentModalSuccess from '../components/payment-modal-success.vue';
import PaymentModalFailed from '../components/payment-modal-failed.vue';
import ManagedCommunities from '../components/managed-communities.vue';
import CancelSubs from '../components/cancel-subs.vue';
import CancelDeleteModal from '../components/cancel-delete-modal.vue';
import DeleteCombo from '../components/delete-combo.vue';
import ModalNewUser from '../components/modal-new-user.vue';
import checkboxDownload from '../components/checkbox-download.vue';
import ComboCover from '../components/combo-cover.vue'
import ComboShare from '../components/combo-share.vue'
import SuccessNotification from '../components/success-notification.vue'

import currencyWithCode from '../filters/currency-with-code';
import date from '../filters/date';

import '../config/vee-validate';

Vue.filter('currencyWithCode', currencyWithCode);
Vue.filter('date', date);

Vue.use(Carousel);
Vue.use(VueI18n);
Vue.use(VTooltip);
Vue.use(Vue2TouchEvents);
Vue.directive('linkified', linkify);
Vue.use(VueSvgInlinePlugin);
VueSvgInlinePlugin.install(Vue, { attributes: { add: [{ name: 'focusable', value: 'false' }] } });
Rails.start();

Vue.component('community-info-banner', CommunityInfoBanner);
Vue.component('explained-select', ExplainedSelect);
Vue.component('csrf-input', CsrfInput);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('gret-input', GretInput);
Vue.component('lazy-image', LazyImage);

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#vue-app',
    components: {
      App,
      Dropdown,
      DropdownItem,
      EventFaq,
      ContentGallery,
      ContentAccessModal,
      FileDownloadButton,
      AudioPlayer,
      VideoPlayer,
      VideoTranscodingStatus,
      DocumentViewer,
      ServiceViewer,
      ProductViewer,
      CommunityJoinButton,
      CommunitySubscribeButton,
      VideoUploader,
      ManageFeed,
      FileUpload,
      SearchBar,
      MembersDownloadWizard,
      ExplainedPicker,
      ConnectPaypalAccount,
      SubscriptionPlanForm,
      FileEventsDownloadIconBtn,
      FileEventsDownloadTextBtn,
      ManagershipFile,
      ManagershipFileNew,
      Navbar,
      ToggleablePasswordInput,
      SingleSelectButtons,
      SingleSelectButtonsSeries,
      ContentFormPrivacyAndPaymentSection,
      ContentFormPrivacyAndPaymentSectionSeries,
      CommunityPrivacyAndPaymentSection,
      FileCover,
      FileContent,
      RichTextArea,
      GretSvg,
      SerieForm,
      SerieFormAccessConfiguration,
      SerieFormEpisodes,
      Serie,
      ManageCommunityPlan,
      ManageUserPlan,
      PoweredBy,
      PaymentForm,
      DnsConfigShowWithInstructions,
      PaymentList,
      DnsConfigCreate,
      CommunityPrivacyToggle,
      ManageFeedFilterDropdown,
      Balance,
      AddItem,
      AddItemModal,
      PaymentToggle,
      PaymentModal,
      PaymentModalSuccess,
      PaymentModalFailed,
      ManagershipFileActionsNew,
      ManagedCommunities,
      CancelSubs,
      CancelDeleteModal,
      DeleteCombo,
      ModalNewUser,
      checkboxDownload,
      ComboCover,
      ComboShare,
      SuccessNotification,
    },
    filters: { camelizeKeys },
    i18n: new VueI18n({
      locale: 'es',
      messages: Locales.messages,
    }),
  });

  return app;
});
require('@rails/actiontext');

trixConfig();
