const KILOBYTE = 1024;
const MEGABYTE = KILOBYTE * KILOBYTE;

export default function formatFileSize(size) {
  if (size > MEGABYTE) {
    return `${Math.floor(size / MEGABYTE)} MB`;
  } else if (size > KILOBYTE) {
    return `${Math.floor(size / KILOBYTE)} KB`;
  }

  return `${size} bytes`;
}
