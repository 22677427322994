<template>
  <div class="flex flex-col items-end w-full md:items-center md:justify-between md:flex-row">
    <!--<p class="text-lg font-bold md:text-2xl md:justify-self-start ">
      {{ balance | currencyWithCode('USD') }}
    </p>-->
    5
  </div>
</template>

<script>
  import exchangesApi from '../api/exchanges';

  export default {
    name: 'Balance',
    props: {
        country: { type: Object, default: null },
        //payments: { type: array, required: true },
    },
    data() {
        return {
        amount: null,
        currency: null,
        showModal: false,
        loading: false,
        };
    },
    computed: {
      paymentsWithoutPayout() {
        return this.payments.filter((payment) => payment.payout === null && payment.status === 'paid');
      },
      balance() {
        return this.paymentsWithoutPayout.reduce((total, current) => total + current.usdAmountAfterFeeValue, 0);
    },
    },
    methods: {
        async getAmountInNewCurrency() {
        this.loading = true;
        const { amount, currency } = await exchangesApi.getExchange(this.country && this.country.id, this.balance, 'payout');
        this.amount = amount;
        this.currency = currency;
        this.loading = false;
        },
    },
    mounted() {
      if (this.country && this.country.id) {
        this.getAmountInNewCurrency();
      }
      balance();
    },
  };
</script>
