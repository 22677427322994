<template>
  <div
    class="fixed inset-0 z-50 w-screen h-screen"
    v-show="open"
  >
    <div
      class="absolute inset-0 w-full h-full bg-gray-500 opacity-75"
    />
    <div class="z-10 h-full p-6 overflow-x-hidden overflow-y-auto sm:p-16 absolute inset-0 grid grid-cols-1">
      <div class="relative w-full h-auto max-w-xl mx-auto my-auto bg-white rounded-lg shadow-lg">
        <button
          @click="$emit('close')"
          class="absolute top-0 right-0 pl-2 p-4 focus:outline-none hover:opacity-50"
          type="button"
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" v-bind:svg-inline="''" v-bind:class="'w-8 h-8 text-gret-whiteModal fill-current'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="#2B3D4F"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </button>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>

</style>
