<template>
  <div class="fixed inset-0 z-50 w-screen h-screen">
    <div
      class="absolute inset-0 w-full h-full"
    />
    <div class="z-10 h-full p-6 overflow-x-hidden overflow-y-auto sm:p-16 absolute inset-0 mt-10 animate-fade">
      <div class="flex justify-center gap-3 items-center leading-5 tracking-wider text-gray-700 relative w-full h-auto max-w-xl mx-auto my-auto bg-white border-2 border-gret-successNoti bg-gret-successNotiTwo rounded py-5 shadow-lg">
        <gret-svg class="w-6 h-6" icon="success"></gret-svg>
        {{ text }}
      </div>
    </div>
  </div>
</template>
  
<script>
  import GretSvg from './gret-svg.vue';

  export default {
    name: 'SuccessNotification',
    components: { GretSvg },
    props: {
      text: {
        type: String,
        required: true,
      },
    },
  };
</script>
    