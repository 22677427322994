<template>
    <div
      class="w-full h-full"
    >
      <file-cover
        :community-path="communityPath"
        :community="community"
        :access="access"
        file-type="service"
        :file="file"
        :downloadable="downloadable"
        :file-url="fileUrl"
        @play="preview"
        :download-event-identifier="downloadEventIdentifier"
        :force-visible="!previewing"
        :subscribers-only="subscribersOnly"
        :file-price="filePrice"
      />
      <div
        class="absolute top-0 w-full h-full pt-20 overflow-hidden bg-opacity-50 bg-gret-black"
        :class="previewing ||'invisible'"
      >
        <div
          class="flex flex-col items-center justify-center w-full h-full bg-gret-gray-dark"
        >
          lo quiero
        </div>
      </div>
      <div
        :class="previewing && breakpoints.is === 'xs' && 'hidden'"
      >
        <file-content
          v-if="!previewing"
          :file="file"
          :long-description="longDescription"
          :questions="questions"
        />
        <slot />
      </div>
    </div>
  </template>
  
  <script>
  import pdf from 'vue-pdf';
  import breakpoints from '../utils/breakpoints';
  import FileContent from './file-content';
  import FileCover from './file-cover';
  import FileDownloadButton from './file-download-button.vue';
  
  export default {
    name: 'ServiceViewer',
    components: {
      FileContent,
      FileCover,
      FileDownloadButton,
      pdf,
    },
    data() {
      return {
        breakpoints,
        previewing: false,
        zoomedImage: false,
        pdfSrc: '',
        pdfPages: 0,
      };
    },
    props: {
      subscribersOnly: {
        type: Boolean,
        default: false,
      },
      communityPath: {
        type: String,
        default: null,
      },
      community: {
        type: Object,
        default: null,
      },
      service: {
        type: Object,
        required: true,
      },
      fileTitle: {
        type: String,
        default: null,
      },
      fileName: {
        type: String,
        default: null,
      },
      longDescription: {
        type: Object,
        default: null,
      },
      questions: {
        type: Object,
        default: null,
      },
      fileUrl: {
        type: String,
        default: '',
      },
      fileType: {
        type: String,
        default: null,
      },
      fileSize: {
        type: String,
        default: null,
      },
      downloadable: {
        type: Boolean,
        default: true,
      },
      fileId: {
        type: Number,
        default: null,
      },
      filePrice: {
        type: String,
        default: '',
      },
      downloadEventIdentifier: {
        type: String,
        default: 'file_download',
      },
    },
    methods: {
      preview() {
        this.previewing = true;
      },
      closePreview() {
        this.previewing = false;
      },
      toggleImageZoom() {
        this.zoomedImage = !this.zoomedImage;
      },
    },
    computed: {
      access() {
        return !!this.fileUrl;
      },
      previewable() {
        return (
          this.fileType === 'application/pdf' || this.fileType.indexOf('image') === 0
        );
      },
      file() {
        return { ...this.service, type: 'service' };
      },
    },
    created() {
      this.pdfSrc = pdf.createLoadingTask(this.fileUrl);
    },
    mounted() {
      if (this.pdfSrc) {
        this.pdfSrc.promise.then(pdfLoaded => {
          this.pdfPages = pdfLoaded.numPages;
        });
      }
    },
  };
  </script>
  
  <style>
  .zoom-in {
    cursor: zoom-in;
  }
  
  .zoom-out {
    cursor: zoom-out;
  }
  
  .pdf-container {
    overflow-y: auto;
  }
  
  .file-thumbnail {
    max-width: 40vh;
    max-height: 40vh;
  }
  </style>
  