<template>
  <div
    :class="dark ? 'bg-gret-gray-dark fixed w-full z-20' : 'bg-gret-gray-dark fixed w-full z-20' "
  >
    <div
      class="items-center"
      :class="[ justifyBetween ? 'justify-between' : 'justify-end flex',
                darkGallery ? 'sm:pl-5 sm:px-10' : 'container h-20 flex',
                twoRowsStyle ? 'h-12 hidden' : 'h-20 flex']"
    >
      <a
        v-if="gretLogo"
        href="/"
      >
        <img
          v-if="dark"
          class="h-8"
          :src="require('../../assets/images/logo-white.png')"
        >
        <img
          v-else
          class="h-8"
          :src="require('../../assets/images/logo-white.png')"
        >
      </a>
      <div
        v-else-if="community"
        class="flex items-center rounded-full"
      >
        <a
          v-if="!darkGallery"
          href="/"
        >
          <img
            class="h-8"
            :src="require('../../assets/images/logo-white.png')"
          >
        </a>
        <a
          class="flex text-white gap-3 items-center pl-8"
          :href="communityPath"
          v-if="darkGallery"
        >
          <img
             v-if="communityLogo"
            :class="[dark? 'h-8 sm:h-12 sm:pr-5 sm:pl-0' : 'h-8',
                     darkGallery ? 'mx-4 sm:mx-0' : '']"
            :src="communityLogo"
          >
          <gret-svg 
            v-else
            icon="default-img" 
            class="h-5 w-5"
          />
          {{ community.name }}
        </a>
        <!--<navbar-options
          v-if="darkGallery"
          :options="visibleOptions"
          :community-path="communityPath"
          :xs-visible="xs && !twoRowsStyle"
          :two-rows-style="twoRowsStyle"
        />-->
      </div>
      <navbar-dropdown
        :current-user="currentUser"
        :community="community"
        :dark="dark"
        :dark-gallery="darkGallery"
        :manage="manage"
        :notifications="notifications"
        :root-path="rootPath"
        :manage-path="managePath"
        :sign-in-path="signInPath"
        :sign-out-path="signOutPath"
        :user-plan-path="userPlanPath"
        :own="own"
      />
    </div>
    <div
      v-if="darkGallery && twoRowsStyle"
      class="grid h-10 sm:hidden"
      :class="`grid-cols-${visibleOptionsLength}`"
    >
      <a
        v-if="communityLogo"
        class="flex items-center justify-center w-full col-start-1"
        :href="communityPath"
      >
        <img
          :src="communityLogo"
          class="h-8"
        >
      </a>
      <navbar-dropdown
        :current-user="currentUser"
        :community="community"
        :dark="dark"
        :dark-gallery="darkGallery"
        :manage="manage"
        :notifications="notifications"
        :root-path="rootPath"
        :manage-path="managePath"
        :sign-in-path="signInPath"
        :sign-out-path="signOutPath"
        :user-plan-path="userPlanPath"
        :style-class="`justify-center col-start-${visibleOptionsLength}`"
      />
    </div>
    <!--<navbar-options
      v-if="twoRowsStyle"
      :options="visibleOptions"
      :community-path="communityPath"
      :xs-visible="twoRowsStyle"
      :two-rows-style="twoRowsStyle"
    />-->
  </div>
</template>
<script>
import breakpoints from '../utils/breakpoints';
import NavbarDropdown from '../components/navbar-dropdown.vue';
import NavbarOptions from '../components/navbar-options.vue';
import GretSvg from './gret-svg.vue';

export default {
  components: {
    NavbarDropdown,
    NavbarOptions,
    GretSvg
  },
  filters: {},
  props: {
    community: {
      type: Object,
      default: null,
    },
    fromCommunity: {
      type: Boolean,
      default: false,
    },
    communityLogo: {
      type: String,
      default: null,
    },
    communityPath: {
      type: String,
      default: null,
    },
    currentUser: {
      type: Object,
      default: null,
    },
    manage: {
      type: Boolean,
      default: false,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    darkGallery: {
      type: Boolean,
      default: false,
    },
    justifyBetween: {
      type: Boolean,
      required: true,
    },
    notifications: {
      type: Boolean,
      default: false,
    },
    rootPath: {
      type: String,
      default: null,
    },
    managePath: {
      type: String,
      default: null,
    },
    userPlanPath: {
      type: String,
      default: null,
    },
    signInPath: {
      type: String,
      default: null,
    },
    signOutPath: {
      type: String,
      default: null,
    },
    showAudios: {
      type: Boolean,
      default: false,
    },
    showTexts: {
      type: Boolean,
      default: false,
    },
    showServices: {
      type: Boolean,
      default: false,
    },
    showProducts: {
      type: Boolean,
      default: false,
    },
    showVideos: {
      type: Boolean,
      default: false,
    },
    own: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      breakpoints,
      dark: this.darkMode || this.darkGallery,
      gretLogo: !(this.community || this.fromCommunity),
      options: [
        { name: this.$t('message.navbar.home'),
          icon: { name: 'home' }, height: 6,
          pb: 0, path: '', show: true,
        },
        { name: 'Videos',
          icon: { name: 'videocam' }, height: 8,
          pb: 1, path: 'videos', show: this.showVideos,
        },
        { name: 'Audios',
          icon: { name: 'mic' }, height: 6, pb: 0,
          path: 'audios', show: this.showAudios,
        },
        { name: 'Archivos',
          icon: { name: 'feed' }, height: 6,
          pb: 0, path: 'documents', show: this.showTexts,
        },
        { name: 'Servicios',
          icon: { name: 'service' }, height: 6,
          pb: 0, path: 'services', show: this.showServices,
        },
        { name: 'Productos',
          icon: { name: 'product' }, height: 6,
          pb: 0, path: 'products', show: this.showProducts,
        },
      ],
    };
  },
  computed: {
    xs() {
      return breakpoints.is === 'xs';
    },
    visibleOptions() {
      return this.options.filter((option) => (option.show));
    },
    visibleOptionsLength() {
      return this.visibleOptions.length;
    },
    twoRowsStyle() {
      const optionsRowLimit = 3;

      return this.darkGallery && this.xs && this.visibleOptions.length > optionsRowLimit;
    },
  },
};
</script>
