<template>
  <input
    type="hidden"
    name="authenticity_token"
    id="authenticity_token"
    :value="csrfToken"
  >
</template>

<script>
import { csrfToken } from '@rails/ujs';

export default {
  data() {
    return { csrfToken: csrfToken() };
  },
};
</script>
