export function formatHHMMSS(seconds) {
  const date = new Date(0);
  date.setSeconds(Math.floor(seconds));

  /* eslint-disable-next-line no-magic-numbers */
  return date.toISOString().substr(11, 8);
}

export function formatMMSS(seconds) {
  const HHMMSS = formatHHMMSS(seconds);

  /* eslint-disable-next-line no-magic-numbers */
  return HHMMSS.substr(3, 8);
}
