<template>
  <modal
    :open="open"
    @close="onClose"
    :success="onUploadSuccess"
  >
    <div class="p-8 pt-16 text-center bg-gret-gray-dark">
      <div class="mb-5 text-2xl text-center text-white">
        Publicar video
      </div>
      <template v-if="!uploadSucceed">
        <div
          v-if="errorUploading"
          class="p-4 my-6 bg-red-200 rounded-md"
        >
          <div
            class="font-bold text-red-700"
          >
            {{ $t('message.files.video.uploadError') }}
          </div>
        </div>
        <div class="flex items-center justify-center">
          <div class="mr-5 text-center text-white">
            Seleccionar archivo
          </div>
          <label class="gret-button small">
            Explorar
            <input
              type="file"
              class="hidden"
              @change="setFile"
              :accept="allowedTypes"
            >
          </label>
        </div>
        <div
          v-if="file"
          class="mt-2 font-medium text-white truncate"
        >
          {{ file.name }}
        </div>
        <div
          v-if="uploading"
          class="flex items-center justify-center"
        >
          <label

            class="text-gray-200 hover:text-white"
          >
            {{ uploadProgress === 0 ? 'procesando' : `${uploadProgress} %` }}
          </label>
        </div>
        <div class="flex items-center justify-center mt-5">
          <button
            type="button"
            class="mr-5 gret-button"
            :class="{ disabled: uploading || !file }"
            @click="uploadVideo"
            :disabled="uploading || !file"
          >
            {{ uploading ? $t('message.files.video.uploading') : 'Publicar' }}
          </button>
          <a
            class="text-white cursor-pointer"
            @click="onClose"
          >
            {{ $t('message.shared.cancel') }}
          </a>
        </div>
      </template>
      <template v-else>
        <div
          class="flex items-center justify-center mt-1 text-sm font-bold text-green-600"
        >
          <img
            inline-svg
            class="w-10 h-10 mr-2 text-gray-200 fill-current"
            src="../../assets/images/check_circle.svg"
          >
          <span>
            {{ $t('message.files.video.uploadSuccess') }}
          </span>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import * as tus from 'tus-js-client';
import Modal from './modal.vue';
import generateUploadUrl from '../api/vimeo';
import getPercentage from '../utils/get-percentage';

export default {
  name: 'PublishVideoModal',
  components: {
    Modal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    duration: {
      type: [Number, String],
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    communityId: {
      type: String,
      required: true,
    },
    allowedTypes: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      open: true,
      loading: false,
      file: null,
      uploading: false,
      uploadProgress: 0,
      errorUploading: false,
      uploadSucceed: false,
    };
  },
  methods: {
    onClose() {
      this.open = false;
      this.$emit('close');
    },
    confirm() {
      this.loading = true;
      this.$emit('confirmed');
    },
    setFile(event) {
      const { target: { files } } = event;
      if (files.length > 0) {
        this.file = files[0];
      }
    },
    setProgress(bytesUploaded, bytesTotal) {
      this.uploadProgress = getPercentage(bytesUploaded, bytesTotal);
    },
    onUploadSuccess(vimeoLink) {
      this.uploading = false;
      this.uploadSucceed = true;
      this.$emit('success', vimeoLink);
    },
    onUploadError() {
      this.uploading = false;
      this.errorUploading = true;
    },
    uploadVideo() {
      this.uploading = true;
      this.errorUploading = false;
      generateUploadUrl(
        {
          name: this.title,
          description: this.description,
          size: this.file.size,
          communityId: this.communityId,
        },
      )
        .then(response => {
          const { uploadUrl, link } = response;
          if (uploadUrl) {
            const self = this;
            const upload = new tus.Upload(this.file, {
              uploadUrl,
              onError: self.onUploadError,
              onProgress: self.setProgress,
              onSuccess() {
                self.onUploadSuccess(link);
              },
            });

            upload.start();
          } else {
            this.onUploadError();
          }
        })
        .catch(this.onUploadError);
    },
  },
};
</script>
