<template>
  <div class="relative mt-8">
    <div
      v-if="!serie"
      class="z-10 file-subscription-badge"
    >
      <div
        class="px-2 py-1 rounded"
        :class="file.subscribersOnly ? 'bg-gret' : 'bg-gray-600 '"
      >
        <span class="text-xs font-medium tracking-wide text-white uppercase">
          {{ (file.privacy)? privacyDict[file.privacy] : '' }}
          {{ (file.subscribersOnly & file.privacy === null)? $t('message.files.subscription') : '' }}
        </span>
      </div>
    </div>
    <div
      class="flex flex-col px-5 duration-700 rounded-lg shadow-xl md:flex-row hover:shadow-white"
      :class="[
        selected || serie ? 'border-gret bg-gret bg-opacity-20 m-2 py-4' : 'py-8 bg-gret-gray',
        {
          'gret-shake': shake,
          'justify-between': actions,
          'border border-red-600': !file.visibleByConsumers,
        }
      ]"
    >
      <div :class="actions ? '' : 'w-full'">
        <div class="flex items-center w-full sm:flex-1">
          <div class="flex-1">
            <div class="flex items-center">
              <img
                v-svg-inline
                class="w-6 h-6 mr-2 text-gray-200 fill-current hover:text-white"
                :src="require(`../../assets/images/${icon}.svg`)"
              >
              <div class="flex flex-col">
                <a
                  target="_blank"
                  class="flex flex-col ml-4 text-lg font-medium hover:underline line-clamp-1'"
                  :class="selected ? 'text-white' : 'text-gret'"
                  :href="file.url"
                >
                  {{ file.title }}
                </a>
                <div
                  v-if="selected"
                  class="ml-4 text-base capitalize text-gret"
                >
                  {{ episode }}
                </div>
                <div
                  v-if="serie"
                  class="ml-4 text-base text-white"
                >
                  {{ episodesMessage }}
                </div>
                <span class="ml-4 text-sm text-gret-text-gray">
                  {{ file.createdAt | date }}
                </span>
              </div>
            </div>
          </div>
          <button
            class="block outline-none md:hidden"
            @click="toggle"
          >
            <img
              v-svg-inline
              viewBox="0 0 24 24"
              class="w-8 h-8 text-gray-600 fill-current"
              :src="require(`../../assets/images/${keyboardArrow}.svg`)"
            >
          </button>
        </div>
      </div>
      <div
        v-if="actions"
        class="flex justify-end"
      >
        <managership-serie-actions
          v-if="serie"
          :serie="file"
          :community-id="communityId"
          @reload="reload"
        />
        <managership-file-actions
          v-else
          :file="file"
          :community-id="communityId"
          @reload="reload"
        />
      </div>
      <div
        class="flex flex-wrap items-center md:hidden"
        v-if="actions && isVisible"
      >
        <managership-serie-actions
          v-if="serie"
          :serie="file"
          :community-id="communityId"
          @reload="reload"
          small
        />
        <managership-file-actions
          v-else
          :file="file"
          :community-id="communityId"
          @reload="reload"
          small
        />
      </div>
    </div>
  </div>
</template>
<script>
import ManagershipFileActions from '../components/managership-file-actions.vue';
import ManagershipSerieActions from '../components/managership-serie-actions.vue';
export default {
  name: 'ManagershipFile',
  components: { ManagershipFileActions, ManagershipSerieActions },
  props: {
    id: { type: Number, default: 0 },
    file: { type: Object, default: () => {} },
    icon: { type: String, default: null },
    selected: { type: Boolean, default: false },
    shake: { type: Boolean, default: false },
    actions: { type: Boolean, default: false },
    communityId: { type: Number, default: null },
    serie: { type: Boolean, default: false },
  },
  data() {
    return {
      isVisible: false,
      privacyDict: {
        'public': this.$tc('message.files.privacy.public'),
        'registered_only': this.$tc('message.files.privacy.registeredOnly'),
        'manual_authorization': this.$tc('message.files.privacy.manualAuthorization'),
        'single_payment': this.$tc('message.files.privacy.singlePayment'),
        'by_invitation': this.$tc('message.files.privacy.byInvitation'),
      },
    };
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },
    reload() {
      this.$emit('reload');
    },
  },
  computed: {
    keyboardArrow() {
      return this.isVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
    fileType() {
      return this.file.type ? this.file.type : this.file.fileType;
    },
    episode() {
      return `${this.$t(`message.files.${this.fileType}.type`)} ${this.id + 1}`;
    },
    episodesMessage() {
      if (this.serie && this.fileType) {
        return this.$tc(`message.files.${this.fileType}.episodes`,
          this.file.episodesCount,
          { count: this.file.episodesCount });
      } else if (this.serie) {
        return this.$t('message.series.noEpisodes');
      }
      return '';
    },
  },
};
</script>
<style>
.gret-shake {
  animation: shake .82s cubic-bezier(.36, .07, .19, .97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% { transform: translate3d(-1px, 0, 0); }
  20%,
  80% { transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% { transform: translate3d(-4px, 0, 0); }
  40%,
  60% { transform: translate3d(4px, 0, 0); }
}
</style>