const HOUR_DURATION_IN_MINUTES = 60;
export default function formatDuration(minutes) {
  const hours = Math.floor(minutes / HOUR_DURATION_IN_MINUTES);
  const minutesLeft = minutes - hours * HOUR_DURATION_IN_MINUTES;
  const formattedHours = hours > 0 ? `${hours} h` : '';
  const formattedSpace = hours > 0 && minutesLeft > 0 ? ', ' : '';
  const formattedMinutes = minutesLeft > 0 ? `${minutesLeft} min` : '';

  return `${formattedHours}${formattedSpace}${formattedMinutes}`;
}
