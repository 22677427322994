<template>
  <div class="text-gray-200 whitespace-no-wrap sm:text-center md:text-left">
    <div
      :class="[tabStyle, value? inactiveTabStyle : activeTabStyle]"
      @click="change(null)"
    >
      <div class="flex">
        <img
          inline-svg
          class="w-6 h-6 mr-2 text-gray-200 fill-current hover:text-white"
          src="../../assets/images/home.svg"
        >
        {{ $t('message.files.all') }}
      </div>
    </div>
    <div
      v-for="(icon, type) in fileIcons"
      :key="type"
      :class="[tabStyle, type === value ? activeTabStyle : inactiveTabStyle]"
      @click="change(type)"
    >
      <div class="flex">
        <img
          v-svg-inline
          viewBox="0 0 24 24"
          class="w-6 h-6 mr-2 text-gray-200 fill-current hover:text-white"
          :src="require(`../../assets/images/${icon.type}.svg`)"
        >
        {{ $t(`message.files.${type}s`) }}
      </div>
    </div>
  </div>
</template>

<script>
import fileIcons from '../../assets/json/file_icons.json';

export default {
  name: 'FeedTabs',
  props: {
    value: {
      type: String,
      default: null,
    },
    showEvents: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tabStyle: 'inline-block mr-2 rounded-full py-2 px-4 cursor-pointer',
      activeTabStyle: 'bg-gret hover:bg-gret text-white',
      inactiveTabStyle: 'bg-gret-gray hover:text-white hover:bg-gret-gray-light',
      fileIcons,
    };
  },
  methods: {
    change(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style>

</style>
