<template>
  <div v-if="communityPrivacy === 'public'">
    <div class="flex justify-between items-center mt-5">
      <div class="text-white font-semibold text-base leading-5">
        <div>
          Acceso al ítem
        </div>
        <div class="font-normal text-xs text-gray-400 mb-1">
          Este ítem tiene acceso:
        </div>
        <div class="flex" :key="svg">
          <div class="flex justify-center items-center font-normal text-xs bg-gray-400 p-1 rounded-sm gap-2">
            <gret-svg :icon="allTypeValue" class="w-3.5 h-3.5" />
            {{ allTypeValue === 'public' ? 'Público' : 
                allTypeValue === 'registered_only' ? 'Con registro' :
                allTypeValue === 'manual_authorization' ? 'Whatsapp' :
                allTypeValue === 'by_invitation' ? 'Por invitación' :
                allTypeValue === 'single_payment' ? 'Pago único' :
                'Membresía'
            }}
          </div>
        </div>
        <div class="font-normal text-xs text-gray-400 mt-1">
          Esta condición se mantendrá sólo si la configuración <br/> de acceso del combo corresponde a "Mixto"
        </div>
      </div>
      <button 
        class="flex items-center justify-center px-4 rounded-md w-full h-12 text-white md:w-auto bg-gret-smallHub hover:bg-gret text-base font-bold px-8 py-3.5"
        @click="changeAccess"
        type="button"
      >
        <div class="flex items-center justify-center uppercase">
          cambiar acceso
        </div>
      </button>
    </div>
    <div v-show="showModal">
      <single-select-buttons
        :attribute-name="attributeName('privacy')"
        :options="allTypeOptions"
        v-model="allTypeValue"
        :actual-value="actualValue"
        :initial-phone-number-value="initialPhoneNumberValue"
        :initial-whatsapp-msg-value="initialWhatsappMsgValue"
        :initial-whatsapp-amount-value="initialWhatsappAmountValue"
        class="mb-6"
        @confirmed="confirmAccess"
        @close="onClose"
        @refresh="refresh"
        @changeNumber="changeWspNumber"
        @changeMsg="changeWspMsg"
        @changeAmount="changeWspAmount"
      />
    </div>
    <div v-if="allTypeValue === 'manual_authorization'">
      <input
        type="hidden"
        :value="initialPhoneNumberValue"
        :name="attributeName('phone_number')"
        id="file_phone_number"
      >
      <input
        type="hidden"
        :value="initialWhatsappMsgValue"
        :name="attributeName('whatsapp_msg')"
        id="file_whatsapp_msg"
      >
      <input
        type="hidden"
        :value="initialWhatsappAmountValue"
        :name="attributeName('price')"
        id="file_whatsapp_amount"
      >
    </div>
  </div>
</template>

<script>
  import SingleSelectButtons from './single-select-buttons';
  import Modal from './modal.vue';
  import GretSvg from './gret-svg.vue';

  export default {
    components: { SingleSelectButtons, Modal, GretSvg },
    props: {
      entityName: { type: String, required: true },
      requirePaymentOptions: { type: Array, required: true },
      privacyOptions: { type: Array, required: true },
      privacy: {type: String, default: null},
      allTypeOptions: { type: Array, required: true },
      paymentTypeOptions: { type: Array, required: true },
      initialWhatsappMsg: { type: String, default: '' },
      initialPhoneNumber: { type: String, default: '' },
      initialWhatsappAmount: { type: String, default: '' },
      initialSinglePaymentPrice: { type: Number, default: null },
      enablePhoneNumberChange: { type: Boolean, default: false },
      editCommunityPath: { type: String, default: '' },
      visibleByConsumers: { type: Boolean, required: true },
      hideTitle: { type: Boolean, default: false },
      communityPrivacy: { type: String, default: null },
    },
    data() {
      return {
        requirePaymentValue: this.requirePaymentOptions.find(option => option.initialValue).value,
        privacyValue: this.privacyOptions.find(option => option.initialValue).value,
        paymentTypeValue: this.paymentTypeOptions.find(option => option.initialValue).value,
        allTypeValue: this.allTypeOptions.find(option => option.initialValue).value,
        actualValue: this.allTypeOptions.find(option => option.initialValue).value,
        showModal: false,
        actualAccessValue: '',
        svg: 1,
        initialPhoneNumberValue: this.initialPhoneNumber,
        initialWhatsappMsgValue: this.initialWhatsappMsg,
        initialWhatsappAmountValue: this.initialWhatsappAmount,
      };
    },
    computed: {
      manualAuthorization() {
        return this.privacyValue === 'manual_authorization';
      },
      singlePayment() {
        return this.paymentTypeValue === 'single_payment';
      },
    },
    methods: {
      attributeName(attribute) {
        return `${this.entityName}[${attribute}]`;
      },
      changeAccess() {
        this.showModal = true;
      },
      onClose() {
        this.showModal = false;
      },
      refresh() {
        this.svg = this.svg + 1
      },
      confirmAccess(value) {
        this.showModal = false;
        this.actualValue = value
      },
      changeWspNumber(value){
        this.initialPhoneNumberValue = value
      },
      changeWspMsg(value){
        this.initialWhatsappMsgValue = value
      },
      changeWspAmount(value){
        this.initialWhatsappAmountValue = value
      },
    },
  };
</script>