<template>
  <validation-provider
    :rules="rules"
    tag="label"
    v-slot="{ errors }"
    class="block"
  >
    <span class="mb-1 gret-dark-input-label">{{ label }}</span>
    <input
      v-bind="$attrs"
      :value="value"
      v-on="eventListeners"
      :class="errors.length === 0 ? 'border-gret-gray' : 'border-red-500'"
      class="shadow-xl gret-dark-input bg-gret-gray-light hover:shadow-white"
    >
    <p
      v-if="errors.length > 0"
      class="mt-1 text-xs text-red-500"
    >
      {{ $t(`validationErrors.${getErrors(errors)}`) }}
    </p>
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: { type: String, required: true },
    rules: { type: String, default: '' },
    label: { type: String, default: null },
  },
  computed: {
    eventListeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value),
      };
    },
  },
  methods: {
    getErrors(errors) {
      return Array.isArray(errors[0]) ? errors[0][0] : errors[0];
    },
  },
};
</script>
