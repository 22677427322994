<template>
  <modal
    :open="open"
    @close="onClose"
  >
    <div class="p-5 text-center bg-gret-blueBlack rounded-lg">
      <div class="mb-6 font-semibold text-white text-2xl tracking-wider">
        Seleccionar tipo de acceso al {{ isCommunity ? 'combo' : 'ítem' }}
      </div>
      <input
        v-if="!!currentAttributeName"
        :name="currentAttributeName"
        :value="value"
        type="hidden"
      >
      <div
        v-for="(option, index) in options"
        :key="index"
        v-tooltip="option.disabled && {
          content: 'Opción no disponible, debes actualizar tu plan del portal',
          classes: 'text-gretGrayLight text-xs bg-gray-300 p-1 rounded',
        }"
      >
        <button
          type="button"
          :disabled="option.disabled"
          class="block w-full p-6 pr-12 text-left text-white border-b top last:border-b-0 disabled:cursor-not-allowed disabled:opacity-50 mb-3 rounded-lg hover:bg-gret"
          :class="value == option.value ? 'bg-gret' : 'bg-gret-smallHub'"
          @click="emitIfEnabled(option)"
          @mouseover="hoverMouse = option.value"
          @mouseleave="hoverMouse = 'false'"
        >
          <div class="flex items-center gap-4">
            <div 
              v-if="hoverMouse === option.value" 
              class="rounded-full p-3 bg-blue-700"
            >
              <gret-svg :icon="(isCommunity === true && option.value == 'public') ? 'mixto' : option.value" class="w-6 h-6 text-transparent"/>
            </div>
            <div 
              v-else 
              class="rounded-full p-3"
              :class="value === option.value ? 'bg-blue-700' : 'bg-gret-blueBlack'"
            >
              <gret-svg :icon="(isCommunity === true && option.value == 'public') ? 'mixto' : option.value" class="w-6 h-6 text-transparent"/>
            </div>
            <div class="text-lg font-semibold leading-7 tracking-wider">
              {{ option.label }} <br/>
              <div class="text-xs text-white">{{ option.description }}</div>

              <div 
                v-if="option.subtext !== ''"
                class="font-normal text-xs leading-3 text-white pt-2"
              >
                {{ option.subtext }}
              </div>
              <div 
                v-if="option.subtextTwo"
                class="font-normal text-xs leading-3 text-white"
              >
                <label class="font-normal text-xs leading-3 text-white">{{ option.subtextTwo }}</label>
              </div>
            </div>
          </div>
          <div v-if="value === option.value">
            <div 
              v-if="value === 'manual_authorization'"
              class="pt-4"  
            >
              <hr class="pt-2.5"/>
              <label class="font-semibold text-base leading-5 tracking-wider">Precio</label><br class="block content-none -mt-1"/>
              <div class="flex">
                <div class="flex items-center justify-center bg-white text-gret w-1/6 px-2 rounded-l my-1 h-12 font-semibold leading-5">
                  $
                </div>
                <input
                  type="text"
                  :value="initialWhatsappAmountData"
                  name="phoneAmount"
                  placeholder="00.00"
                  v-on:input="e => onChangeAmount(e)"
                  id="file_whatsapp_amount_modal"
                  class="placeholder-gray-300 my-1 gret-dark-input-two rounded-r bg-transparent border-2 mb-5"
                >
              </div>
              <label class="font-semibold text-base leading-5 tracking-wider">Número de Whatsapp</label>
              <vue-tel-input 
                v-model="phone" 
                v-on:country-changed="countryChanged"
                class="tooltip-arrow mb-5"
                :inputOptions="optionsPlaceholder"
                :validCharactersOnly="true"
              >
                <template v-slot:arrow-icon>
                  <span class="ml-1 text-gret display-none"></span>
                </template>
              </vue-tel-input>
              <label class="font-semibold text-base leading-5 tracking-wider">Mensaje inicial</label><br class="block content-none -mt-1"/>
              <label class="text-sm text-white tracking-wider">Es el mensaje que te enviarán tus usuarios al contactarte por Whatsapp</label>
              <input
                type="text"
                :value="initialWhatsappMsgData"
                name="phoneMsg"
                placeholder="Ej: Quiero más información sobre el curso"
                v-on:input="e => onChangeMsg(e)"
                id="file_whatsapp_msg_modal"
                class="placeholder-gray-300 my-1 gret-dark-input-two rounded bg-transparent border-2"
              >
              <label class="text-sm text-white tracking-wider">
                Los nuevos clientes se agregan en la sección Agregar clientes de un ítem
              </label>
            </div>
            <div 
              v-else-if="value === 'by_invitation'" 
              class="pt-4"   
            >
              <hr class="pt-2.5"/>
              <label class="text-sm text-white tracking-wider">
                Los nuevos clientes se agregan en la sección Clientes y miembros
              </label>
            </div>
            <div 
              v-else-if="(value === true && isCommunity === false)"
              class="pt-4"  
            >
              <hr class="pt-2.5"/>
              <label>** PROXIMAMENTE MEMBRESÍA **</label>
              <!--<label>Precio</label>
              <input
                type="text"
                :value="initialPhoneNumber"
                :name="phoneNumber"
                :onChage="handleChange"
                id="file_phone_number"
                class="mb-6 shadow-xl gret-dark-input border-gret-gray bg-gret-gray-light hover:shadow-white disabled:cursor-not-allowed"
              >-->
            </div>
          </div>
        </button>
      </div>
      <hr class="text-red-500"/>
      <div class="flex justify-end gap-4">
        <a
          class="mt-6 inline-flex items-center w-40 hover:opacity-90 justify-center px-6 py-3 font-medium tracking-wide text-white uppercase border-2 rounded-md cursor-pointer border-gret-smallHub bg-gret-smallHub"
          @click="onClose"
        >
          Cancelar
        </a>
        <a
          class="mt-6 gret-button w-40 hover:opacity-90"
          @click="confirm"
        >
          Guardar
        </a>
      </div>
    </div>
  </modal>
</template>

<script>
  import GretSvg from './gret-svg.vue';
  import Modal from './modal.vue';
  import { VueTelInput } from 'vue-tel-input'

  export default {
    components: { GretSvg, Modal, VueTelInput },
    props: {
      attributeName: { type: String, default: null },
      options: { type: Array, required: true },
      value: { type: [Boolean, String], required: true },
      actualValue: { type: String, default: null },
      initialPhoneNumberValue: { type: String, default: null },
      initialWhatsappMsgValue: { type: String, default: null },
      initialWhatsappAmountValue: { type: String, default: null },
      phoneNumber: { type: String, default: null },
      enablePhoneNumberChange: { type: Boolean, default: true },
      isCommunity: {type: Boolean, default: false},
    },
    data() {
      return {
        hoverMouse: 'false',
        open: true,
        initialPhoneNumberData: this.initialPhoneNumberValue,
        initialWhatsappMsgData: this.initialWhatsappMsgValue,
        initialWhatsappAmountData: this.initialWhatsappAmountValue,
        phone: this.initialPhoneNumberValue,
        optionsPlaceholder: { placeholder: "" },
        isValid: false,
      };
    },
    methods: {
      emitIfEnabled(option) {
        if (option.disabled) return;

        this.$emit('refresh');
        this.$emit('input', option.value);
      },
      onClose() {
        this.phone = this.initialPhoneNumberValue;
        this.initialWhatsappMsgData = this.initialWhatsappMsgValue;
        this.initialWhatsappAmountData = this.initialWhatsappAmountValue;
        this.$emit('refresh');
        this.$emit('input', this.actualValue);
        this.$emit('close');
      },
      confirm() {
        this.$emit('changeNumber', this.phone);
        this.$emit('changeMsg', this.initialWhatsappMsgData);
        this.$emit('changeAmount', this.initialWhatsappAmountData);
        this.$emit('confirmed', this.value);
      },
      countryChanged(country) {
        this.phone = '+' + country.dialCode;
        this.optionsPlaceholder.placeholder = '+' + country.dialCode;
      },
      onChangeMsg(e){
        this.initialWhatsappMsgData = e.target.value;
      },
      onChangeAmount(e){
        this.initialWhatsappAmountData = e.target.value;
      },
    },
    computed: {
      selectedOption() {
        return this.options.find(option => option.value === this.value);
      },
      currentAttributeName() {
        return this.selectedOption.attributeName || this.attributeName;
      },
    },
  };
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>

<style>
  .vue-tel-input.tooltip-arrow {
    border-style: solid;
    z-index: 1;
    border-color: white;
    background-color: white;
    color: black;
    padding: 1px;
  }
  .vue-tel-input.tooltip-arrow input{
    background-color: #2679ff;
    color: white;
    height: 3rem;
  }
</style>
