<template>
  <div>
    <modal :open="open" @close="onClose">
      <div class="p-5 text-center bg-gret-whiteModal rounded-lg">
        <div class="flex justify-center text-2xl text-center text-gret-smallHub font-semibold tracking-wider mb-7">
          Publicar
        </div>
        <div class="text-white text-base text-gret-smallHub font-normal leading-5">
          Activa tu plan y obtén acceso a todas las funcionalidades <br /> de Gret por tan solo:
        </div>
        <div class="text-3xl text-gret-smallHub font-bold leading-10 tracking-wider my-4">
          $9 USD/mes
        </div>
        <div class="text-sm font-normal text-gret-smallHub">
          Podrás cancelar tu subscripción en cualquier momento.
        </div>
        <div class="grid grid-cols-12 mt-5">
          <div class="flex justify-center col-span-12 mt-3" ref="paypal" />
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from './modal.vue';
import api from '../api/index';

export default {
  name: 'PaymentModal',
  components: {
    Modal,
  },
  props: {
    communityId: { type: Number, required: true },
  },
  data() {
    return {
      open: true,
      loading: false,
    };
  },
  mounted() {
    const script = document.createElement('script');
    // eslint-disable-next-line vue/max-len
    const clientId = process.env.PAYPAL_CLIENT_ID;
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&vault=true&intent=subscription`;
    script.addEventListener('load', this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    onClose() {
      this.open = false;
      this.$emit('close');
    },
    setLoaded() {
      this.loaded = true;
      // eslint-disable-next-line consistent-this, no-underscore-dangle
      const _this = this;
      window.paypal
        // eslint-disable-next-line new-cap
        // HEROKU FORCE ENV UPDATE
        .Buttons({
          createSubscription(data, actions) {
            return actions.subscription.create({
              'plan_id': process.env.PAYPAL_GRET_SINGLE_PLAN,
            });
          },
          onApprove(data, actions) {
            console.table(data);
            console.table(actions);

            // eslint-disable-next-line no-alert
            if (data.orderID || data.subscriptionID) {
              _this.confirmPaypal(data.orderID, data.subscriptionID);
            } else {
              _this.paypalError();
            }
          },
          onError: err => {
            _this.paypalError(err);
          },
        })
        .render(this.$refs.paypal);
    },
    confirmPaypal(orderID, subscriptionID) {
      api({
        method: 'PATCH',
        url: `/api/v1/communities/${this.communityId}/subscribe`,
        data: {
          community: {
            public: true,
            orderId: orderID,
            subscriptionId: subscriptionID,
          },
        },
      });
      console.info('transaction finished succesfully');
      this.$emit('success');
    },
    paypalError(err) {
      console.err(`transaction finished with errors ${err}`);
      this.$emit('error');
    },
  },
};
</script>
