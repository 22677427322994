import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

export function createMembersCsvDownload(communityId) {
  return axios.post(`/api/v1/communities/${communityId}/members_csv_downloads`)
    .then((res) => camelizeKeys(res.data));
}

export function getCsvDownload(downloadId, communityId) {
  return axios.get(`/api/v1/csv_downloads/${downloadId}`, { params: decamelizeKeys({ communityId }) })
    .then((res) => camelizeKeys(res.data));
}

export function createEventsCsvDownload(fileType, fileId, communityId, eventType) {
  const body = { eventType };

  return axios.post(
    `/api/v1/communities/${communityId}/${fileType}/${fileId}/events_csv_downloads`, decamelizeKeys(body),
  ).then((res) => camelizeKeys(res.data));
}
