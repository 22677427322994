<template>
  <div class="relative">
    <input
      :type="inputType"
      v-bind="$attrs"
      v-on="$listeners"
      class="gret-input-blacks"
    >
    <button
      class="absolute top-0 right-0 flex items-center justify-center w-12 h-12 focus:outline-none"
      type="button"
      @click="toggleVisibility"
    >
      <i
        class="material-icons"
        :class="visible ? 'text-gret' : 'text-gray-500'"
      >
        visibility
      </i>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    inputType() {
      return this.visible ? 'text' : 'password';
    },
  },
  methods: {
    toggleVisibility() {
      this.visible = !this.visible;
    },
  },
};
</script>

<style scoped>
  .gret-input {
    @apply pr-12;
  }

  .gret-input-blacks {
    @apply block w-full h-12 px-4 rounded-lg outline-none bg-gret-inputBlacks text-white border border-gray-500;
  }
</style>
