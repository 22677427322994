<template>
  <div>
    <div>
      <div class="flex flex-col md:items-center md:flex-row">
        <div class="flex flex-row items-center h-12">
          <span class="text-xl font-medium text-white">$</span>
          <input
            v-if="editing"
            class="w-40 ml-1 gret-number-input"
            name="plan[price_cents]"
            id="plan_price_cents"
            type="number"
            v-model="inputValue"
          >
          <span
            v-else
            class="ml-2 text-xl font-medium text-white"
          >
            {{ priceCents }}
          </span>
          <span class="ml-2 text-sm text-white">{{ priceCurrency }}</span>
        </div>
        <div class="flex flex-row flex-grow mt-2 md:mt-0 md:justify-end">
          <div
            class="flex flex-col w-full md:w-auto md:flex-row"
            v-if="editing"
          >
            <button
              type="submit"
              class="gret-button small"
              key="submit-button"
            >
              Guardar
            </button>
            <button
              type="button"
              class="mt-2 text-red-500 bg-transparent outline-none cursor-pointer md:mt-0 md:ml-2 hover:underline"
              @click="cancelEdit"
              key="cancel-edit-button"
            >
              Cancelar
            </button>
          </div>
          <button
            v-else
            @click="startEdit"
            key="start-edit-button"
            type="button"
            class="w-full md:w-auto gret-button small"
          >
            Cambiar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionPlanForm',
  props: {
    priceCents: {
      type: Number,
      required: true,
    },
    priceCurrency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
      inputValue: this.priceCents,
    };
  },
  methods: {
    startEdit() {
      this.editing = true;
    },
    cancelEdit() {
      this.editing = false;
    },
  },
};
</script>
