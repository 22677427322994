<template>
  <div>
    <div class="flex flex-col w-full md:items-center md:flex-row">
      <div class="flex flex-row items-center">
        <div class="bg-white border border-gray-300 rounded-lg">
          <a
            href="https://www.paypal.com/in/webapps/mpp/paypal-popup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png"
              alt="PayPal Logo"
              class="w-40 p-4"
            >
          </a>
        </div>
        <div
          class="ml-4"
          v-if="paypalEmail"
        >
          <div class="text-gret-text-gray">
            Conectado con <span class="font-medium text-white">{{ paypalEmail }}</span>
          </div>
          <div class="text-sm text-gret-text-gray">
            Recibirás los pagos el día 25 de cada mes
          </div>
        </div>
        <div
          class="ml-4 text-gret-text-gray"
          v-else
        >
          Conecta tu cuenta
        </div>
      </div>
      <div
        v-if="!editing"
        class="flex flex-row flex-grow mt-4 md:mt-0 md:justify-end"
      >
        <button
          type="button"
          class="w-full md:w-auto gret-button small"
          @click="startEdit"
        >
          {{ paypalEmail ? 'Cambiar' : 'Conectar' }}
        </button>
      </div>
    </div>
    <div
      v-if="editing"
      class="mt-4"
    >
      <div class="gret-dark-input-label">
        Ingresa el mail asociado a tu cuenta de PayPal
      </div>
      <div class="flex flex-col mt-1 md:flex-row">
        <input
          class="gret-dark-input bg-gret-gray"
          name="community[paypal_email]"
          id="community_paypal_email"
          v-model="inputValue"
        >
        <button
          type="submit"
          class="w-full mt-2 md:mt-0 md:w-auto md:ml-2 gret-button small"
        >
          Guardar
        </button>
        <button
          type="button"
          class="w-full mt-2 text-red-500 bg-transparent outline-none cursor-pointer md:mt-0 md:w-auto md:ml-2 hover:underline"
          @click="cancelEdit"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConnectPaypalAccount',
  props: {
    paypalEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
      inputValue: this.paypalEmail,
    };
  },
  methods: {
    startEdit() {
      this.editing = true;
    },
    cancelEdit() {
      this.editing = false;
    },
  },
};
</script>
