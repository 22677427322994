import api from './index';

export default {
  getExchange(countryId, amount, type) {
    return api({
      method: 'GET',
      url: `/api/v1/countries/${countryId}/exchange`,
      params: { amount, type },
    });
  },
};
