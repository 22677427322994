<template>
    <div
      class="items-center"
      :class="small ?'flex ' : 'md:flex'"
    >
      <a
        class="flex p-2 mt-2 mr-2 text-gray-600 bg-gray-200 rounded outline-none cursor-pointer hover:bg-gray-300"
        :href="file.editPath"
      >
        <gret-svg
          class="w-6 h-6 my-1 text-gray-600"
          icon="create"
        />
      </a>
      <a
        v-if="file.authorizations"
        class="flex p-2 mt-2 mr-2 text-gray-600 bg-gray-200 rounded outline-none cursor-pointer hover:bg-gray-300"
        :href="file.authorizationsPath"
      >
        <gret-svg
          class="w-6 h-6 my-1"
          icon="lock"
        />
      </a>
      <a
        class="flex p-2 mt-2 text-red-600 bg-red-200 rounded outline-none cursor-pointer hover:bg-red-300"
        @click="confirmDelete"
      >
        <gret-svg
          class="w-6 h-6 my-1 text-red-600"
          icon="delete"
        />
      </a>
      <delete-confirmation-modal
        v-if="showModal"
        :title="file.title"
        :message="$t('message.files.deleteConfirmation')"
        @close="hideModal"
        @confirmed="deleteFile"
      />
    </div>
  </template>
  
  <script>
    import DeleteConfirmationModal from '../components/delete-confirmation-modal.vue';
    import GretSvg from '../components/gret-svg.vue';
    import filesApi from '../api/files';
  
    export default {
      name: 'ManagershipFileActionsNew',
      components: { DeleteConfirmationModal, GretSvg },
      props: {
        file: { type: Object, default: () => {} },
        communityId: { type: Number, default: null },
        small: { type: Boolean, default: false },
      },
      data() {
        return {
          showModal: false,
        };
      },
      methods: {
        confirmDelete() {
          this.showModal = true;
        },
        hideModal() {
          this.showModal = false;
        },
        async deleteFile() {
          await filesApi.deleteFile(this.communityId, this.file.type, this.file.id);
          this.$emit('reload');
        },
      },
    };
  </script>
  