<template>
  <a
    :href="href"
    class="flex items-center px-3 py-2 rounded-md last:mb-0"
    :class="dark ? 'hover:bg-gret-black text-gret-text-gray hover:text-white': `hover:bg-gray-200 text-${color}`"
  >
    <div
      v-if="isHighlighted"
      class="mr-2 notification-icon small"
    />
    <div class="flex-1 whitespace-nowrap">{{ label }}</div>
    <div
      v-if="icon"
      class="ml-6"
    >
      <img
        v-svg-inline
        viewBox="0 0 24 24"
        class="w-6 h-6 fill-current"
        :src="require(`../../assets/images/${icon}.svg`)"
      >
    </div>
  </a>
</template>

<script>
export default {
  name: 'DropdownItem',
  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'gray-800',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>

</style>
