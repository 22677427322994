<template>
  <div>
    <div
      class="grid grid-cols-12 bg-gret-smallHub shadow-xl flex items-center rounded-lg hover:shadow-white h-24 pl-3 cursor-pointer"
      @click="changeAccess"
      type="button"
      :key="buttonKey"
    >
      <div class="col-span-12 flex-1 text-xs font-medium text-gray-100 truncate hover:text-white tracking-wider">
        Tipo de acceso al combo
      </div>
      <div class="col-span-10 flex items-center font-semibold text-white  w-24 h-7 text-xs">
        <div class="flex bg-gray-400 rounded-sm px-4 py-1 items-center gap-1">
          <gret-svg
            class="hover:text-white mr-1 text-transparent w-5 h-5"
            :icon="actualPrivacy.value"
          />
          <div class="flex w-max">
            {{ actualPrivacy.label }}
          </div>
        </div>
      </div>
      <gret-svg
        class="col-span-1 text-gray-100 hover:text-white"
        icon="keyboard_arrow_right"
      />
    </div>
    <div v-if="showModal">
      <single-select-buttons
        attribute-name="community"
        :options="accessibilityControlCategoryOptions"
        v-model="accessibilityControlCategory"
        :actual-value="actualValue"
        :initial-phone-number-value="initialPhoneNumberValue"
        :initial-whatsapp-msg-value="initialWhatsappMsgValue"
        :initial-whatsapp-amount-value="initialWhatsappAmountValue"
        class="mb-6"
        :is-community="true"
        @confirmed="confirmAccess"
        @close="onClose"
        @refresh="refresh"
        @changeNumber="changeWspNumber"
        @changeMsg="changeWspMsg"
        @changeAmount="changeWspAmount"
      />
    </div>
    <div v-if="allTypeValue === 'manual_authorization'">
      <input
        type="hidden"
        :value="initialPhoneNumberValue"
        :name="attributeName('phone_number')"
        id="file_phone_number"
      >
      <input
        type="hidden"
        :value="initialWhatsappMsgValue"
        :name="attributeName('whatsapp_msg')"
        id="file_whatsapp_msg"
      >
      <input
        type="hidden"
        :value="initialWhatsappAmountValue"
        :name="attributeName('price')"
        id="file_whatsapp_amount"
      >
    </div>
  </div>
</template>

<script>
import SingleSelectButtons from './single-select-buttons';
import GretSvg from './gret-svg.vue';
import communitiesApi from '../api/community';

export default {
  components: { SingleSelectButtons, GretSvg },
  props: {
    originalPrivate: {
      type: [Boolean, String],
      required: true,
    },
    communityId: {
      type: Number,
      required: true,
    },
    initialWhatsappMsg: { type: String, default: '' },
    initialPhoneNumber: { type: String, default: '' },
    initialWhatsappAmount: { type: String, default: '' },
  },
  data() {
    return {
      accessibilityControlCategoryOptions: [
        { value: 'private',
          label: 'Privado',
          description: 'Oculta el contenido de tu combo y concede acceso sólo a quien quieras',
          subtext: 'Sólo tus invitados podrán ver el contenido del combo y acceder',
        },
        { value: 'public',
          label: 'Mixto',
          description: 'Incluye ítems gratuitos y de pago en el mismo combo',
          subtext: 'Configura el tipo de acceso a cada ítem del combo',
        },
        { value: 'registered_only',
          label: 'Con registro',
          description: 'Utiliza tu combo para obtener datos de personas interesadas',
          subtext: 'Tendrán que registrarse para obtener el combo',
        },
        { value: 'by_invitation',
          label: 'Por invitación',
          description: 'Concede acceso exclusivo a personas o grupos',
          subtext: 'Sólo tus invitados podrán acceder al combo',
        },
        { value: 'manual_authorization',
          label: 'WhatsApp',
          description: 'Haz que te contacten por WhatsApp las personas que quieran comprar este combo',
          subtext: 'Otorga acceso en forma manual a cada nuevo cliente',
        },
        { value: 'single_payment',
          label: 'Pago único - Próximamente',
          description: 'Vende tu combo cobrando por Gret o por tu cuenta',
          subtext: 'Cobra por Gret y otorga acceso en piloto automático.',
          subtextTwo: 'Cobra por tu cuenta y otorga acceso en forma manual.',
          disabled: true,
        },
        { value: 'true',
          label: 'Membresía - Próximamente',
          description: 'Vende tu combo por membresía',
          subtext: 'Tus clientes harán un pago mensual automático',
          disabled: true,
        },
      ],
      accessibilityControlCategory: this.originalPrivate,
      actualValue: this.originalPrivate,
      showModal: false,
      buttonKey: 1,
      initialPhoneNumberValue: this.initialPhoneNumber,
      initialWhatsappMsgValue: this.initialWhatsappMsg,
      initialWhatsappAmountValue: this.initialWhatsappAmount,
    };
  },
  methods: {
    changeAccess() {
      this.showModal = true;
    },
    onClose() {
      this.showModal = false;
    },
    refresh() {
      this.buttonKey += 1;
    },
    confirmAccess(value) {
      this.actualValue = value;
      communitiesApi.update(
        this.communityId,
        { private: value,
          price: Number(this.initialWhatsappAmountValue),
          whatsapp_msg: this.initialWhatsappMsgValue,
          phone_number: this.initialPhoneNumberValue,
        },
      ).then(() => {
        this.showModal = false;
        window.location.reload();
      })
        .catch((error) => {
          if (error.details) {
            this.errors = error.details;
          }
        });
    },
    changeWspNumber(value) {
      this.initialPhoneNumberValue = value;
    },
    changeWspMsg(value) {
      this.initialWhatsappMsgValue = value;
    },
    changeWspAmount(value) {
      this.initialWhatsappAmountValue = value;
    },
  },
  computed: {
    actualPrivacy() {
      return this.accessibilityControlCategoryOptions.find(option => option.value === this.originalPrivate);
    },
  },
};
</script>
