import api from './index';

export default {
  update(id, params) {
    return api({
      method: 'PATCH',
      url: `/api/v1/communities/${id}`,
      data: { community: params },
    });
  },
};
