<template>
  <div class="grid grid-cols-12 grid-rows-12 gap-3 px-7 pt-7">
    <div class="col-span-12 md:col-span-7">
      <div class="text-white font-normal text-xs mb-2"><label class="text-red-700">* </label>Campos obligatorios</div>
      <div
        v-if="errorUploading || fieldError"
        class="col-span-12 md:col-span-8 mb-5 p-4 my-6 bg-red-200 rounded-md"
      >
        <div
          v-if="errorUploading"
          class="font-bold text-red-700 text-center"
        >
          {{ $t('message.files.video.uploadError') }}
        </div>
        <div
          v-if="fieldError"
          class="font-bold text-red-700"
        >
          {{ $t('message.files.video.fieldError') }}
        </div>
      </div>
      <div class="grid grid-cols-12 grid-rows-12 gap-3">
        <div class="col-span-12 md:col-span-8 mb-5">
          <div class="flex">
            <label class="mb-1 gret-dark-input-label">Nombre</label>
            <div class="pl-1 text-xs text-red-700">* </div>
          </div>
          <input
            name="video_file[title]"
            id="video_file_title"
            class="block w-full h-12 px-4 text-gray-200 rounded shadow-xl outline-none bg-gret-gray-light hover:shadow-white border-gret-gray"
            placeholder="Mi video"
            v-model="title"
            @change="clearFieldError"
          >
        </div>
        <div class="col-span-12 md:col-span-4 mb-5">
          <div class="flex items-center">
            <label class="mb-1 gret-dark-input-label">Duración en minutos</label>
          </div>
          <input
            name="video_file[duration]"
            type="number"
            id="video_file_duration"
            class="shadow-xl gret-dark-input hover:shadow-white bg-gret-gray-light"
            placeholder="00"
            v-model="duration"
            @change="clearFieldError"
          >
        </div>
      </div>
      <div class="mb-6">
        <div class="flex">
          <label class="-mb-1 gret-dark-input-label">Descripción breve</label>
          <div class="pl-1 text-xs text-red-700">* </div>
        </div>
        <label class="mb-1 text-xs text-gray-400"> Máx. 280 caracteres </label>
        <input
          name="video_file[description]"
          placeholder="Información general del video"
          id="video_file_description"
          class="block w-full h-12 px-4 text-gray-200 rounded shadow-xl outline-none bg-gret-gray-light hover:shadow-white border-gret-gray"
          v-model="description"
          @keydown.enter.prevent
          @change="clearFieldError()"
        >
      </div>
      <div class="mb-6 text-white">
        <label class="-mb-1 gret-dark-input-label">Descripción detallada</label>
        <label class="mb-1 text-xs text-gray-400"> Máx. 2200 caracteres </label>
        <RichTextArea
          name="video_file[long_description]"
          :value="initialLongDescription"
          placeholder="Todo lo que deba saber el cliente sobre el video"
        ></RichTextArea>
      </div>
      <div class="mb-6 text-white">
        <label class="-mb-1 gret-dark-input-label">Recursos</label>
        <label class="mb-1 text-xs text-gray-400"> Máx. 2200 caracteres </label>
        <RichTextArea
          name="video_file[questions]"
          :value="initialResources"
          placeholder="Agrega enlaces a videos, publicaciones y otros recursos complementarios que quieras compartir con tus clientes"
        ></RichTextArea>
      </div>
      <div class="text-white">
        <ContentFormPrivacyAndPaymentSection
          :entity-name="entityName"
          :require-payment-options="requirePaymentOptions"
          :privacy-options="privacyOptions"
          :privacy="privacy"
          :payment-type-options="paymentTypeOptions"
          :all-type-options="privacyOptions"
          :initial-whatsapp-msg="initialWhatsappMsg"
          :initial-phone-number="initialPhoneNumber"
          :initial-single-payment-price="initialSinglePaymentPrice"
          :enable-phone-number-change="enablePhoneNumberChange"
          :edit-community-path="editCommunityPath"
          :visible-by-consumers="visibleByConsumers"
          :community-privacy="communityPrivacy"
          :initial-whatsapp-amount="initialWhatsappAmount"
        ></ContentFormPrivacyAndPaymentSection>
      </div>
    </div>
    <div class="hidden md:block col-span-1 bg-gray-400 border border-gray-500 w-px mx-auto">
    </div>
    <div class="col-span-12 md:col-span-4">
      <div class="text-white font-semibold text-2xl tracking-wider">Imagen de Portada</div>
      <div class="mb-2 mt-1 text-xs text-gray-400 tracking-wider">
        Esta imagen aparecerá en la página de tu video. <br/> Si no deseas agregarla, se añadirá una imagen predeterminada.
      </div>
      <div class="mb-5 text-xs text-blue-500 underline">
        <a href="/communities/portadas" target="_blank">Aquí puedes descargar portadas listas para usar.</a>
      </div>
      <FileUpload
        name="video_file[thumbnail]"
        type="image"
        button-label="button label"
        text-label="Imagen horizontal de 1280x720px"
        :value="initialThumbnailData"
        :url="initialThumbnailUrl"
      ></FileUpload>

      <div class="bg-gray-400 border border-gray-500 my-10"></div>

      <div class="mb-5 mt-1 flex">
        <label class="text-white font-semibold text-2xl tracking-wider">Video</label>
        <div class="font-semibold text-2xl text-red-700">*</div>
      </div>
      <div
        class="relative rounded-lg shadow-xl bg-gret-gray-light hover:shadow-white"
        @click="openModal"
      >
        <label
          class="flex flex-col items-center justify-center p-8 text-gretTextDark"
          :class="{ 'cursor-pointer': !newVideoUrl }"
        >
          <template v-if="!newVideoUrl">
            <img
              inline-svg
              class="w-10 h-10 text-gray-200 fill-current"
              src="../../assets/images/backup.svg"
            >
            <div
              v-if="!initialUrl"
              class="text-center text-xs text-gray-400"
            >
              Haz click para seleccionar un archivo <br /> Formatos permitidos: Mp4, M4V, AVI, WMV, MPEG, MOV
            </div>
            <div
              v-else
              class="text-center text-sm text-white"
            >
              {{ videoName && videoName.metadata && videoName.metadata.filename }}
              <label class="text-gret hover:underline file gret-file-uploader__replace-label">Reemplazar archivo</label>
            </div>
          </template>
          <template v-else>
            <div
              class="flex items-center justify-center mt-1 text-sm font-bold text-green-600"
            >
              <img
                inline-svg
                class="w-10 h-10 mr-2 text-gray-200 fill-current"
                src="../../assets/images/check_circle.svg"
              >
              <span>
                {{ $t('message.files.video.uploadSuccess') }}
              </span>
            </div>
            <div
              v-if="initialUrl"
              class="text-xs text-center text-white"
            > {{ $t('message.files.video.uploadWarning') }}</div>
            <span
              class="text-sm text-gret hover:underline file gret-file-uploader__replace-label"
            >
              {{ $t('message.files.video.changeFile') }}
            </span>
          </template>
        </label>
      </div>
      <input
        name="video_file[vimeo_link]"
        id="video_file_vimeo_link"
        type="hidden"
        v-model="uploadedVideoUrl"
      >
    </div>

    <PublishVideoModal
      v-if="showModal"
      @success="onUploadSuccess"
      @close="hideModal"
      :title="title"
      :duration="duration"
      :description="description"
      :subtitle="subtitle"
      :community-id="communityId"
      :allowed-types="allowedTypes"
    />
  </div>
</template>

<script>
import CommunityInfoBanner from './community-info-banner.vue';
import PublishVideoModal from './publish-video-modal.vue';
import RichTextArea from './rich-text-area.vue'
import FileUpload from './file-upload.vue'
import ContentFormPrivacyAndPaymentSection from './content-form-privacy-and-payment-section.vue'

export default {
  name: 'VideoUploader',
  components: {
    CommunityInfoBanner,
    PublishVideoModal,
    RichTextArea,
    FileUpload,
    ContentFormPrivacyAndPaymentSection
  },
  props: {
    communityId: {
      type: String,
      required: true,
    },
    cancelPath: {
      type: String,
      required: true,
    },
    allowedTypes: {
      type: String,
      required: true,
    },
    privacy: {
      type: String, 
      default: null
    },
    communityHasActivePlan: {
      type: Boolean,
      default: false,
    },
    infoBannerMainText: {
      type: String,
      default: null,
    },
    infoBannerSecondaryText: {
      type: String,
      default: null,
    },
    initialTitle: {
      type: String,
      default: '',
    },
    initialUrl: {
      type: String,
      default: null,
    },
    initialDuration: {
      type: [Number, String],
      default: 0,
    },
    initialDescription: {
      type: String,
      default: '',
    },
    initialLongDescription: {
      type: String,
      default: '',
    },
    initialResources: {
      type: String,
      default: '',
    },
    initialThumbnailData: {
      type: String,
      default: '',
    },
    initialThumbnailUrl: {
      type: String,
      default: '',
    },
    initialSubtitle: {
      type: String,
      default: '',
    },
    entityName: { 
      type: String, required: true 
    },
    requirePaymentOptions: { 
      type: Array, required: true 
    },
    privacyOptions: { 
      type: Array, required: true 
    },
    allTypeOptions: { 
      type: Array, required: true
    },
    paymentTypeOptions: { 
      type: Array, required: true 
    },
    initialWhatsappMsg: { 
      type: String, default: '' 
    },
    initialPhoneNumber: { 
      type: String, default: '' 
    },
    initialSinglePaymentPrice: { 
      type: Number, default: null 
    },
    enablePhoneNumberChange: { 
      type: Boolean, default: false 
    },
    editCommunityPath: { 
      type: String, default: '' 
    },
    visibleByConsumers: { 
      type: Boolean, required: true 
    },
    hideTitle: { 
      type: Boolean, default: false 
    },
    videoData: {
      type: Object, default: () => {}
    },
    communityPrivacy: { 
      type: String, default: null 
    },
    initialWhatsappAmount: { 
      type: String, 
      default: '' 
    },
  },
  data() {
    return {
      title: this.initialTitle,
      subtitle: this.initialSubtitle,
      duration: this.initialDuration,
      description: this.initialDescription,
      longDescription: this.initialLongDescription,
      file: null,
      uploadedVideoUrl: this.initialUrl,
      newVideoUrl: null,
      uploading: false,
      uploadProgress: 0,
      errorUploading: false,
      fieldError: false,
      showModal: false,
      videoName: this.videoData ? JSON.parse(this.videoData) : null,
    };
  },
  computed: {
    firstSectionValid() {
      return !!this.title && !!this.description;
    },
  },
  methods: {
    hideModal() {
      this.showModal = false;
    },
    openModal() {
      if (!this.firstSectionValid) {
        this.fieldError = true;

        return;
      }
      this.showModal = true;
    },
    onUploadSuccess(vimeoLink) {
      this.uploadedVideoUrl = vimeoLink;
      this.newVideoUrl = vimeoLink;
      this.uploading = false;
    },
    clearFieldError() {
      if (this.fieldError) this.fieldError = false;
    },
  },
};
</script>
