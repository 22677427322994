import api from './index';

export default {
  deleteFile(communityId, fileType, fileId) {
    return api({
      method: 'DELETE',
      url: `/api/v1/communities/${communityId}/${fileType}s/${fileId}`,
    });
  },
};
