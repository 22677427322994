<template>
  <div class="w-full h-full">
    <file-cover
      :community-path="communityPath"
      :community="community"
      :access="access"
      :file="file"
      file-type="video"
      :force-visible="forceVisible"
      @play="play"
      :subscribers-only="subscribersOnly"
      :file-price="filePrice"
    />
    <div
      v-show="playing"
      class="absolute top-0 flex items-start justify-center w-full gret-visible-3s "
    >
      <div class="w-full pt-20 sm:max-w-2/3 ">
        <div>
          <video
            ref="videoEl"
            v-if="fileUrl"
            :src="fileUrl"
            class="w-full h-full outline-none"
            controls
          />
          <div
            v-else-if="vimeoLink"
            id="player"
            class="w-full aspect-w-16 aspect-h-9"
            data-plyr-provider="vimeo"
            :data-plyr-embed-id="vimeoLink"
          />
        </div>
      </div>
    </div>
    <file-content
      :file="file"
      :long-description="longDescription"
      :questions="questions"
    />
  </div>
</template>

<script>
import 'vue-plyr/dist/vue-plyr.css';
import Plyr from 'plyr';
import playbackTracking from '../mixins/playback-tracking';
import FileContent from './file-content';
import FileCover from './file-cover';

export default {
  name: 'VideoPlayer',
  components: {
    FileContent,
    FileCover,
  },
  mixins: [playbackTracking],
  props: {
    communityPath: {
      type: String,
      default: null,
    },
    community: {
      type: Object,
      default: null,
    },
    video: {
      type: Object,
      required: true,
    },
    videoId: {
      type: String,
      default: null,
    },
    longDescription: {
      type: Object,
      default: null,
    },
    questions: {
      type: Object,
      default: null,
    },
    fileUrl: {
      type: String,
      default: null,
    },
    subscribersOnly: {
      type: Boolean,
      default: false,
    },
    filePrice: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      playing: false,
      player: null,
    };
  },
  mounted() {
    if (this.vimeoLink) {
      this.player = new Plyr('#player');
      if (this.trackPlaybackMetrics) {
        this.player.on('play', this.setupPlaybackTrackers);
        this.player.on('pause', this.clearStreamTimeoutAndRecalculateTimeLeftForStream);
      }
    }
  },
  methods: {
    play() {
      if (this.vimeoLink) {
        this.startPlaying(this.player);
      } else if (this.fileUrl) {
        this.startPlaying(this.$refs.videoEl);
      }
    },
    startPlaying(player) {
      const PLAYING_DELAY = 1000;
      setTimeout(() => {
        this.playing = true;
        player.play();
      }, PLAYING_DELAY);
    },
  },
  computed: {
    vimeoLink() {
      return this.videoId ? atob(this.videoId) : null;
    },
    access() {
      return !!this.fileUrl || !!this.vimeoLink;
    },
    file() {
      return { ...this.video, info: JSON.parse(this.video.videoData), type: 'video' };
    },
    forceVisible() {
      return !(this.vimeoLink || this.fileUrl);
    },
  },
};
</script>

<style>
.plyr--full-ui input[type=range] {
  @apply text-gret;
  @apply opacity-80;
}

.plyr__control--overlaid {
  @apply bg-gret-gray;
  @apply opacity-80;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  @apply bg-gret-gray;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(#ffff, .5);
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  @apply bg-gret-gray;
}
</style>
