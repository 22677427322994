<template>
  <div class="p-8 rounded-lg shadow-lg bg-gret-gray">
    <template v-if="changingPlan">
      <h2 class="mb-4 text-xl text-white">
        Plan actual: <span class="font-bold">{{ currentPlanOption.label }}</span>
      </h2>
      <validation-observer
        slim
        v-slot="{ invalid }"
      >
        <form
          ref="form"
          :action="userPlansPath"
          method="post"
          @submit.prevent="createCardTokenAndSubmit(invalid)"
        >
          <input
            name="_method"
            type="hidden"
            value="patch"
          >
          <csrf-input />
          <div class="mb-6 space-y-6">
            <div>
              <label
                for="user_plan[id]"
                class="mb-1 gret-dark-input-label"
              >
                Nuevo plan de usuario
              </label>
              <explained-select
                name="user_plan[id]"
                id="user_plan[id]"
                :options="planOptions"
                v-model="selectedPlanId"
              />
            </div>
            <template v-if="selectedPaidPlan">
              <div>
                <h2 class="mb-4 text-xl text-white">
                  Datos personales
                </h2>
                <payment-form-personal-data-inputs
                  :country-options="countryOptions"
                  :initial-country-id="initialCountryId"
                  :initial-user-name="initialUserName"
                  @country-change="getAmountInNewCurrency"
                />
              </div>
              <div>
                <h2 class="mb-4 text-xl text-white">
                  Datos de pago
                </h2>
                <gret-input
                  type="text"
                  v-model="cardHolderName"
                  label="Nombre titular de la tarjeta"
                  class="mb-6"
                  rules="required"
                />
                <label
                  for="card-field"
                  class="mb-1 gret-dark-input-label"
                >
                  Tarjeta
                </label>
                <div ref="cardField" />
                <div
                  v-if="smartFieldErrors"
                  role="alert"
                  class="text-red-700"
                >
                  {{ smartFieldErrors }}
                </div>
              </div>
            </template>
          </div>
          <div>
            <button
              type="submit"
              class="w-full mb-4 gret-button small md:w-auto md:mb-0 md:mr-2"
              :disabled="loading || invalid || invalidSmartField"
            >
              Contratar plan
            </button>
            <button
              @click="toggleChangePlan"
              class="text-gret hover:underline"
              :class="{ 'mr-2': loading }"
            >
              Cancelar cambio
            </button>
            <spinner
              v-if="loading"
              class="inline w-4 h-4"
            />
          </div>
        </form>
      </validation-observer>
    </template>
    <template v-else>
      <div class="mb-2 text-lg font-medium text-gray-200 hover:text-white">
        {{ currentPlanOption.label }} - {{ currentPlanOption.explanation }}
      </div>
      <div>
        <button
          class="w-full mb-4 gret-button small md:w-auto md:mb-0 md:mr-2"
          @click="toggleChangePlan"
        >
          Cambiar plan
        </button>
      </div>
    </template>
  </div>
</template>
<script>
import PaymentFormPersonalDataInputs from './payment-form-personal-data-inputs.vue';
import Spinner from './spinner.vue';
import exchangesApi from '../api/exchanges';

const SMART_FIELDS_STYLE = {
  base: {
    fontSize: '16px',
    color: 'rgba(229, 231, 235)',
    iconColor: '#2679ff',
    '::placeholder': {
      color: 'rgba(75, 85, 99)',
    },
  },
  invalid: {
    color: 'rgba(185, 28, 28)',
  },
};
const SMART_FIELDS_CLASSES = {
  base: 'h-12 outline-none p-4 rounded w-full shadow-xl bg-gret-gray-light hover:shadow-white',
  focus: 'border border-gray-200 shadow-white',
};
const CARD_FIELD_OPTIONS = {
  style: SMART_FIELDS_STYLE,
  classes: SMART_FIELDS_CLASSES,
  iconStyle: 'solid',
};

export default {
  components: { PaymentFormPersonalDataInputs, Spinner },
  props: {
    currentPlanId: { type: String, default: null },
    userPlansPath: { type: String, required: true },
    planOptions: { type: Array, default: () => [] },
    countryOptions: { type: Array, required: true },
    initialCountryId: { type: Number, required: true },
    initialUserName: { type: String, required: true },
  },
  data() {
    return {
      changingPlan: false,
      selectedPlanId: this.currentPlanId,
      selectedCountryId: this.initialCountryId,
      cardSmartField: null,
      smartFieldErrors: null,
      cardHolderName: '',
      DLOCAL_INSTANCE: dlocal(process.env.DLOCAL_API_KEY),
      completedSmartField: false,
      loading: false,
    };
  },
  computed: {
    currentPlanOption() {
      return this.planOptions.find(option => option.value === this.currentPlanId);
    },
    selectedPlanOption() {
      return this.planOptions.find(option => option.value === this.selectedPlanId);
    },
    selectedPrice() {
      return Number(this.selectedPlanOption.price);
    },
    selectedPaidPlan() {
      return this.selectedPrice !== 0;
    },
    selectedCountry() {
      return this.countryOptions.find(country => country.id === this.selectedCountryId);
    },
    invalidSmartField() {
      return this.selectedPaidPlan && !this.completedSmartField;
    },
  },
  methods: {
    toggleChangePlan() {
      this.changingPlan = !this.changingPlan;
    },
    async getAmountInNewCurrency(countryId) {
      const { amount, currency } = await exchangesApi.getExchange(countryId, this.selectedPrice, 'payin');
      this.selectedCountryId = countryId;
      this.amount = amount;
      this.currency = currency;
    },
    mountSmartFieldsIfChangingToPaidPlan() {
      if (this.selectedPaidPlan) {
        this.$nextTick(() => {
          const fields = this.DLOCAL_INSTANCE.fields({
            locale: 'es',
            country: this.selectedCountry.isoCode,
          });
          this.cardSmartField = fields.create('card', CARD_FIELD_OPTIONS);
          this.cardSmartField.mount(this.$refs.cardField);
          this.cardSmartField.on('change', (event) => {
            this.completedSmartField = event.complete;
            this.smartFieldErrors = event.error ? event.error.message : null;
          });
        });
      }
    },
    async createCardTokenAndSubmit(invalidForm) {
      if (this.loading || invalidForm || this.invalidSmartField) return;

      this.loading = true;

      if (this.selectedPaidPlan) {
        this.DLOCAL_INSTANCE.createToken(this.cardSmartField, { name: this.cardHolderName }).then((result) => {
          this.appendTokenInForm(result.token);
          this.$refs.form.submit();
        }).catch((result) => {
          if (result.error) {
            this.smartFieldErrors = result.error.message;
            this.loading = false;
          }
        });
      } else {
        this.$refs.form.submit();
      }
    },
    appendTokenInForm(token) {
      const tokenInput = document.createElement('input');
      tokenInput.setAttribute('type', 'hidden');
      tokenInput.setAttribute('name', 'payment[dlocal_token]');
      tokenInput.setAttribute('value', token);
      this.$refs.form.appendChild(tokenInput);
    },
  },
  watch: {
    selectedPaidPlan() {
      this.mountSmartFieldsIfChangingToPaidPlan();
    },
    changingPlan() {
      this.mountSmartFieldsIfChangingToPaidPlan();
    },
  },
};
</script>
