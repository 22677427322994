<template>  
  <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 mt-8">
    <a
      :href="file.url"
      target="_blank"
    >
      <div class="flex items-center bg-gret-downloadTo rounded-sm font-semibold text-white px-4 py-2 h-7 text-xs absolute mt-2 ml-2">
        <img
          v-svg-inline
          class="fill-current w-4 h-4 mr-1"
          :src="require(`../../assets/images/${icon}.svg`)"
        >
        {{ file.type === "text" ? "Archivo" : file.type === "video" ? "Video" : file.type === "audio" ? "Audio" : file.type === "service" ? "Servicio" : "Producto" }}
      </div>
    </a>
    <div class="flex justify-end">
      <dropdown :dark="true" :content="true">
        <template v-slot:button>
          <div
            class="absolute outline-none  mr-2 mt-10 hover:opacity-90"
          >
            <img
              v-svg-inline
              viewBox="0 0 24 24"
              class="w-8 h-8 text-gray-600 fill-current bg-gret-blueBlack rounded-full pl-2 pt-1 pr-1"
              :src="require(`../../assets/images/wheel.svg`)"
            >
          </div>
        </template>
        <template v-slot:menu>
          <div>
            <dropdown-item
              icon="create"
              label="Editar"
              :dark="true"
              :href="file.editPath"
            />
            <a
              v-if="file.authorizations"
              class="flex items-center px-3 py-2 rounded-md last:mb-0 cursor-pointer hover:bg-gret-black text-gret-text-gray hover:text-white"
              :href="file.authorizationsPath"
            >
              Agregar clientes
              <gret-svg
                class="w-7 h-7 my-1"
                icon="add_user"
              />
            </a>
            <a
              class="flex items-center px-3 py-2 rounded-md last:mb-0 cursor-pointer hover:bg-gret-black text-gret-text-gray hover:text-white"
              @click="confirmDelete"
            >
            Eliminar
            <gret-svg
              class="w-6 h-6 my-1 ml-6"
              icon="delete"
            />
            </a>
          </div>
        </template>
      </dropdown>
      <delete-confirmation-modal
        v-if="showModal"
        :title="file.title"
        :message="$t('message.files.deleteConfirmation')"
        @close="hideModal"
        @confirmed="deleteFile"
      />
    </div>
    <a
      :href="file.url"
      target="_blank"
    >
      <lazy-image
        class="text-white flex-col rounded-t-lg w-full h-36"
        :src="file.thumbnailUrl"
        :webp-src="file.thumbnailUrl"
        alt="item thumbnail"
        :rounded="true"
        :key="file.thumbnailUrl"
      />
      <div
        class="relative col-span-2 duration-700 rounded-b-lg shadow-xl md:flex-row hover:shadow-white pb-2"
        :class="[
          selected || serie ? 'border-gret bg-gret bg-opacity-20 m-2 py-4' : 'bg-gret-smallHub',
          {
            'gret-shake': shake,
            'justify-between': actions,
          }
        ]"
      >
        <div :class="actions ? '' : 'w-full'">
          <div class="flex items-center w-full sm:flex-1 text-white">
            <div class="flex-1">
              <div class="p-2">
                <div class="flex justify-center gap-1 text-xs font-medium tracking-wide text-white uppercase px-2 py-1 rounded bg-gray-400 items-center w-3/5">
                  <gret-svg class="text-gray-100 hover:text-white mr-1 h-4 w-4" :icon="file.subscribersOnly ? 'true' : file.privacy"></gret-svg>
                  <div>
                    {{ file.subscribersOnly ? 'Membresía' : privacyDict[file.privacy] }}
                  </div>
                </div>
              </div>
              <div class="flex items-center grid grid-cols-12">
                <div class="col-span-12">
                  <a
                    class="flex flex-col pl-3 text-base font-semibold hover:underline line-clamp-1"
                    :class="selected ? 'text-white' : 'text-white'"
                    :href="file.url"
                    target="_blank"
                  >
                    {{ file.title }}
                  </a>
                  <div
                    v-if="selected"
                    class="ml-4 text-base capitalize text-gret"
                  >
                    {{ episode }}
                  </div>
                  <div
                    v-if="serie"
                    class="ml-4 text-base text-white"
                  >
                    {{ episodesMessage }}
                  </div>
                </div>
                <div class="col-span-10">
                  <span class="pl-3 text-sm text-gret-text-gray">
                    {{ file.createdAt | date }}
                  </span>
                </div>
                <div class="col-span-2">
                  <a :href="file.url">
                    <gret-svg class="col-span-1 text-gray-100 hover:text-white" icon="keyboard_arrow_right"></gret-svg>
                  </a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
import GretSvg from '../components/gret-svg.vue';
import Dropdown from '../components/dropdown.vue';
import DropdownItem from '../components/dropdown-item.vue';
import DeleteConfirmationModal from '../components/delete-confirmation-modal.vue';
import filesApi from '../api/files';

export default {
  name: 'ManagershipFileNew',
  components: { GretSvg, Dropdown, DropdownItem, DeleteConfirmationModal },
  props: {
    id: { type: Number, default: 0 },
    file: { type: Object, default: () => {} },
    icon: { type: String, default: null },
    selected: { type: Boolean, default: false },
    shake: { type: Boolean, default: false },
    actions: { type: Boolean, default: false },
    communityId: { type: Number, default: null },
    serie: { type: Boolean, default: false },
  },
  data() {
    return {
      isVisible: false,
      showModal: false,
      privacyDict: {
        'public': this.$tc('message.files.privacy.public'),
        'registered_only': this.$tc('message.files.privacy.registeredOnly'),
        'manual_authorization': this.$tc('message.files.privacy.manualAuthorization'),
        'single_payment': this.$tc('message.files.privacy.singlePayment'),
        'by_invitation': this.$tc('message.files.privacy.byInvitation'),
      },
    };
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
      console.log("file", this.file)
    },
    reload() {
      this.$emit('reload');
    },
    confirmDelete() {
      console.log("file", this.file)
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    },
    async deleteFile() {
      await filesApi.deleteFile(this.communityId, this.file.type, this.file.id);
      this.$emit('reload');
    },
  },
  computed: {
    keyboardArrow() {
      return 'wheel';
    },
    fileType() {
      return this.file.type ? this.file.type : this.file.fileType;
    },
    episode() {
      return `${this.$t(`message.files.${this.fileType}.type`)} ${this.id + 1}`;
    },
    episodesMessage() {
      if (this.serie && this.fileType) {
        return this.$tc(`message.files.${this.fileType}.episodes`,
          this.file.episodesCount,
          { count: this.file.episodesCount });
      } else if (this.serie) {
        return this.$t('message.series.noEpisodes');
      }

      return '';
    },
  },
};
</script>
<style>
.gret-shake {
  animation: shake .82s cubic-bezier(.36, .07, .19, .97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.gradientImg {
  background: linear-gradient(180deg, rgba(31, 44, 59, 0.6) 1.1%, rgba(31, 44, 59, 0) 36.25%, rgba(31, 44, 59, 0) 81.36%, rgba(31, 44, 59, 0.6) 112.16%);
  background-blend-mode: multiply, normal;
}

@keyframes shake {
  10%,
  90% { transform: translate3d(-1px, 0, 0); }

  20%,
  80% { transform: translate3d(2px, 0, 0); }

  30%,
  50%,
  70% { transform: translate3d(-4px, 0, 0); }

  40%,
  60% { transform: translate3d(4px, 0, 0); }
}

</style>