<template>
    <modal
      :open="open"
      @close="onClose"
    >
      <div class="p-5 text-center bg-gret-blueBlack rounded-lg">
        <div class="text-2xl text-center text-white font-semibold tracking-wider mb-7">
          Selecciona tipo de ítem
        </div>
        <div class="grid grid-cols-12 gap-2 ">
          <a 
            class="col-span-6 add-button-item" 
            :href="videoUrl" 
            @mouseover="hoverVideo = true"
            @mouseleave="hoverVideo = false"
          >
            <div v-if="hoverVideo" class="icon-button-svg-hover"> 
              <gret-svg icon="Play2" class="h-5 w-5" />
            </div>
            <div v-else class="icon-button-svg"> 
              <gret-svg icon="Play2" class="h-5 w-5" />
            </div>
            <label class="pt-2 cursor-pointer">Video</label>
          </a>
          <a 
            class="col-span-6 add-button-item" 
            :href="audioUrl"  
            @mouseover="hoverAudio = true"
            @mouseleave="hoverAudio = false"
          >
            <div v-if="hoverAudio" class="icon-button-svg-hover"> 
              <gret-svg icon="audio" class="h-5 w-5" />
            </div>
            <div v-else class="icon-button-svg"> 
              <gret-svg icon="audio" class="h-5 w-5" />
            </div>
            <label class="pt-2 cursor-pointer">Audio</label>
          </a>
          <a 
            class="col-span-6 add-button-item" 
            :href="documentUrl"
            @mouseover="hoverDocument = true"
            @mouseleave="hoverDocument = false"  
          >
            <div v-if="hoverDocument" class="icon-button-svg-hover"> 
              <gret-svg icon="feed" class="h-5 w-5" />
            </div>
            <div v-else class="icon-button-svg"> 
              <gret-svg icon="feed" class="h-5 w-5" />
            </div>
            <label class="pt-2 cursor-pointer">Foto - Archivo</label>
          </a>
          <a 
            class="col-span-6 add-button-item"
            :href="serviceUrl"
            @mouseover="hoverService = true"
            @mouseleave="hoverService = false"  
          >
            <div v-if="hoverService" class="icon-button-svg-hover"> 
              <gret-svg icon="service" class="h-5 w-5" />
            </div>
            <div v-else class="icon-button-svg"> 
              <gret-svg icon="service" class="h-5 w-5" />
            </div>
            <label class="pt-2 cursor-pointer">Servicio</label>
          </a>
          <a 
            class="col-span-12 add-button-item w-1/2 mx-auto" 
            :href="productUrl"
            @mouseover="hoverProduct = true"
            @mouseleave="hoverProduct = false"  
          >
            <div v-if="hoverProduct" class="icon-button-svg-hover"> 
              <gret-svg icon="product" class="h-5 w-5"/>
            </div>
            <div v-else class="icon-button-svg"> 
              <gret-svg icon="product" class="h-5 w-5"/>
            </div>
            <label class="pt-2 cursor-pointer">Producto</label>
          </a>
        </div>
      </div>
    </modal>
  </template>
  
  <script>
  import Modal from './modal.vue';
  import GretSvg from './gret-svg.vue';
  
  export default {
    name: 'AddItemModal',
    components: {
      Modal,
      GretSvg
    },
    props: {
      audioUrl: {
        type: String,
        required: true,
      },
      videoUrl: {
        type: String,
        required: true,
      },
      documentUrl: {
        type: String,
        required: true,
      },
      serviceUrl: {
        type: String,
        required: true,
      },
      productUrl: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        open: true,
        loading: false,
        hoverVideo: false,
        hoverAudio: false,
        hoverDocument: false,
        hoverService: false,
        hoverProduct: false,
      };
    },
    methods: {
      onClose() {
        this.open = false;
        this.$emit('close');
      },
      confirm() {
        this.loading = true;
        this.$emit('confirmed');
      },
    },
  };
</script>

<style scoped>
  .add-button-item {
    @apply bg-gret-smallHub p-5 rounded-lg shadow-md items-center text-white font-semibold text-lg flex flex-col hover:bg-gret;
  }

  .icon-button-svg {
    @apply bg-gret-blueBlack rounded-full p-3
  }

  .icon-button-svg-hover {
    @apply bg-blue-700 rounded-full p-3
  }
</style>
  