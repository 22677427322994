<template>
  <div
    class="flex items-center gap-3"
    :class="styleClass"
  >
    <button
      v-if="currentUser && darkGallery && (community.private !== 'public' && community.private !== 'private' && !own)"
      class="w-full text-xs sm:text-base inline-flex items-center justify-center py-1 sm:py-3.5 px-7 hover:opacity-90 font-medium tracking-wide text-white uppercase border-0 rounded-md cursor-pointer bg-gradient-to-r from-gret-downloadFrom to-gret-downloadTo"
      @click="want"
    >
      LO QUIERO
    </button>
    <dropdown
      v-if="currentUser"
      :dark="dark"
    >
      <template v-slot:button>
        <div
          class="flex items-center justify-end sm:justify-center"
        >
          <img
            :src="require(`../../assets/images/account_circle.svg`)"
            class="text-gret-text-gray hover:text-white"
            :class="styleProfile"
            alt="account"
          >
          <img
            inline-svg
            class="hidden w-6 h-6 ml-1 text-white fill-current sm:inline"
            src="../../assets/images/keyboard_arrow_down.svg"
          >
          <div
            v-if="notifications"
            class="notification-icon top-right"
          />
        </div>
      </template>
      <template v-slot:menu>
        <div
          class="px-3 py-2 rounded-md"
          :class="dark ? 'bg-gret-black border-b border-gret-gray shadow-md' : 'bg-gray-200'"
        >
          <div
            class="text-sm "
            :class="dark ? 'text-gret-text-gray' : 'text-gray-600' "
          >
            {{ $t('message.navbar.hello') }}
          </div>
          <div
            class="text-sm"
            :class="dark && 'text-white'"
          >
            {{ currentUser.name || currentUser.email }}
          </div>
        </div>
        <dropdown-item
          v-if="darkGallery && manage"
          icon="settings"
          :label="$t('message.navbar.manage')"
          :dark="dark"
          :is-highlighted="notifications"
          :href="managePath"
        />
        <dropdown-item
          icon="group"
          :label="$t('message.navbar.business')"
          :dark="dark"
          :is-highlighted="notifications"
          :href="rootPath"
        />
        <dropdown-item
          icon="power_settings_new"
          :label="$t('message.navbar.signout')"
          :dark="dark"
          :href="signOutPath"
          data-testid="sign-out-link"
        />
      </template>
    </dropdown>
    <div
      v-else
      class="flex mr-3"
    >
      <a :href="signInPath">
        <div
          v-if="!darkGallery"
          class="text-sm md:text-base whitespace-nowrap text-white"
          :class="dark && 'text-white pr-10 '"
        >
          INICIAR SESIÓN
        </div>
        <div
          v-else
          class="inline-flex items-center justify-center mr-3 py-1 px-2 sm:py-3.5 sm:px-7 hover:opacity-90 font-medium tracking-wide text-white uppercase border-0 rounded-md cursor-pointer border-gret-smallHub bg-gret-smallHub"
        >
          ENTRAR
        </div>
      </a>
      <button
        v-if="community && (community.private !== 'public' && community.private !== 'private')"
        class="inline-flex items-center justify-center mr-28 sm:mr-0 w-max py-1 px-2 sm:py-3.5 sm:px-7 hover:opacity-90 font-medium tracking-wide text-white uppercase border-0 rounded-md cursor-pointer bg-gradient-to-r from-gret-downloadFrom to-gret-downloadTo"
        @click="want"
      >
        LO QUIERO
      </button>
    </div>
  </div>
</template>
<script>
import Dropdown from '../components/dropdown.vue';
import DropdownItem from '../components/dropdown-item.vue';

export default {
  components: {
    Dropdown,
    DropdownItem,
  },
  props: {
    currentUser: {
      type: Object,
      default: null,
    },
    community: {
      type: Object,
      default: null,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    darkGallery: {
      type: Boolean,
      default: false,
    },
    manage: {
      type: Boolean,
      default: false,
    },
    notifications: {
      type: Boolean,
      default: false,
    },
    rootPath: {
      type: String,
      default: null,
    },
    managePath: {
      type: String,
      default: null,
    },
    userPlanPath: {
      type: String,
      default: null,
    },
    signInPath: {
      type: String,
      default: null,
    },
    signOutPath: {
      type: String,
      default: null,
    },
    styleClass: {
      type: String,
      default: '',
    },
    styleProfile: {
      type: String,
      default: 'h-8 sm:h-12',
    },
    own: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    want() {
      //mostrar modal correspondiente
      this.$root.$emit('showAccessModal');
    },
  },
};
</script>
