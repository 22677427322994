import api from './index';

export default {
  deleteCombo(communityId) {
    return api({
      method: 'DELETE',
      url: `/api/v1/communities/${communityId}`,
    });
  },
};
