<template>
  <modal
    :open="open"
    @close="onClose"
  >
    <div class="pt-32 px-2.5 pb-14 text-center bg-gret-blueBlack rounded-lg">
      <template v-if="subscribersOnly">
        <div class="text-xl font-bold text-center text-white">
          Subscripción Mensual
          $25 USD/ mes 
          Podrás cancelar tu suscripción cuando quieras.
        </div>
        <a
          :href="subscribeCommunityUrl"
          class="mt-6 gret-button gret-button--gret"
        >Suscribirme</a>
      </template>
      <template v-else-if="manualAuthorization">
        <div class="flex justify-center pb-10">
          <gret-svg icon="manual_authorization"/>
        </div>
        <div class="text-2xl leading-8 tracking-wider text-center text-white font-semibold">
          <label>Contáctanos por WhatsApp</label> <br/>
          <label class="text-base leading-5">Para más información y finalizar la compra</label>
        </div>
        <a
          target="_blank"
          :href="whatsappUrl"
          class="inline-flex items-center justify-center mt-4 px-7 py-2.5 font-medium tracking-wide text-white uppercase rounded-md cursor-pointer bg-gradient-to-r from-gret-downloadFrom to-gret-downloadTo border-0"
        >Ir a whatsapp</a>
      </template>
      <div
        v-else-if="byInvitation"
        class="text-xl text-center text-white font-regular"
      >
        <div class="flex justify-center pb-10">
          <gret-svg icon="invitation"/>
        </div>
        <div class="text-2xl leading-8 tracking-wider text-center text-white font-semibold">
          <label>Exclusivo para invitados</label> <br class="block content-none mt-3"/>
          <label class="text-base leading-6">
            Debes recibir una invitación de <br class="block content-none -mt-2"/>
            nuestra parte para poder acceder.
          </label>
        </div>
      </div>
      <template v-else-if="singlePayment">
        <div class="text-xl font-bold text-center text-white">
          Debes realizar un pago único de
          <span class="text-gret">
            <!--{{ singlePaymentAmount | currencyWithCode(singlePaymentCurrency) }}-->
          </span>
          para poder acceder a este contenido {{ serieMessage }}
        </div>
        <a
          :href="newPaymentUrl"
          class="mt-6 gret-button gret-button--gret"
        >Pagar</a>
      </template>
      <template v-else>
        <div class="flex justify-center pb-10">
          <gret-svg icon="register"/>
        </div>
        <div class="text-2xl leading-8 tracking-wider text-center text-white font-semibold">
          <label>Regístrate</label> <br/>
          <label class="text-base leading-5">Para acceder</label>
        </div>
        <a
          :href="joinAndRedirectUrl"
          class="inline-flex items-center justify-center mt-4 px-7 py-2.5 font-medium tracking-wide text-white uppercase rounded-md cursor-pointer bg-gradient-to-r from-gret-downloadFrom to-gret-downloadTo border-0"
        >Registrarme</a>
      </template>
    </div>
  </modal>
</template>

<script>
import Modal from './modal.vue';
import GretSvg from './gret-svg.vue';

export default {
  name: 'ContentAccessModal',
  components: {
    Modal,
    GretSvg,
  },
  props: {
    communityName: {
      type: String,
      required: true,
    },
    communityLogo: {
      type: String,
      default: null,
    },
    subscribersOnly: {
      type: Boolean,
      default: false,
    },
    privacy: {
      type: String,
      required: true,
    },
    joinCommunityUrl: {
      type: String,
      required: true,
    },
    whatsappUrl: {
      type: String,
      required: true,
    },
    subscribeCommunityUrl: {
      type: String,
      required: true,
    },
    newPaymentUrl: {
      type: String,
      required: true,
    },
    useSeriePrivacy: {
      type: Boolean,
      default: false,
    },
    singlePaymentAmount: {
      type: Number,
      default: null,
    },
    singlePaymentCurrency: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    this.$root.$on('showAccessModal', () => {
      this.open = true;
    });
  },
  methods: {
    onClose() {
      this.open = false;
    },
  },
  computed: {
    joinAndRedirectUrl() {
      return `${this.joinCommunityUrl}?redir=${window.location.pathname}`;
    },
    manualAuthorization() {
      return this.privacy === 'manual_authorization';
    },
    byInvitation() {
      return this.privacy === 'by_invitation';
    },
    singlePayment() {
      return this.privacy === 'single_payment';
    },
    serieMessage() {
      return this.useSeriePrivacy === true ? 'y al de todo su grupo' : '';
    },
  },
};
</script>

<style>

</style>
