<template>
  <div>
    <div
      class="z-10 flex flex-col-reverse sm:flex-row gret-visible"
    >
      <div
        class="sm:w-1/3"
      >
        <div
          class="w-full h-full px-2 pt-2 md:pl-8 bg-gret-gray bg-gradient-to-b from-gret-black sm:from-gret-gray-dark sm:bg-gradient-to-r"
        >
          <div class="flex flex-col justify-center h-full px-3 pb-4">
            <div class="h-auto text-4xl font-bold tracking-wide text-white sm:z-10 sm:text-shadow-lg sm:w-3/5-screen sm:mt-6 md:text-xl lg:text-4xl xl:text-4xl">
              {{ communityName }}
            </div>
            <div
              class=" font-normal mt-2 text-sm md:mt-5 md:text-base sm:z-10 sm:text-shadow-lg sm:w-3/5-screen lg:text-xl xl:text-xl text-white line-clamp-5 lg:line-clamp-6"
            >
              {{ communityDescription }}
            </div>
            <div
              v-if="communityCreator"
              class="mt-2 text-sm md:mt-5 md:text-base sm:z-10 sm:text-shadow-lg sm:w-3/5-screen lg:text-base xl:text-base text-white line-clamp-5 lg:line-clamp-6"
            >
              Creado por: <label class="font-semibold leading-5 tracking-wider text-gret-label">{{ creator.name }}</label>
            </div>
            <div
              v-if="communityPrice && communityPrice !== '0' && communityPrivacy === 'manual_authorization'"
              class="flex items-center self-start mt-2 text-sm font-bold text-white md:mt-5 sm:font-bold lg:text-2xl xl:text-3xl"
            >
              $ {{ communityPrice }}
            </div>
            <div class="grid w-full sm:w-4/5 grid-cols-1 gap-2">
              <button
                v-if="communityPrivacy !== 'public' && communityPrivacy !== 'private' && !own"
                class="inline-flex flex-row items-center justify-between w-full h-12 px-2 mt-6 rounded cursor-pointer opacity-90 hover:opacity-100 gret-button download md:w-3/5 md:h-2/3"
                @click="want"
                >
                <div
                  class="flex-1 font-medium text-center text-white normal-case xl:text-lg"
                >
                  LO QUIERO
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="relative sm:w-2/3">
        <lazy-image
          class="w-full h-full aspect-w-16 aspect-h-9"
          :src="communityImage"
          :webp-src="communityImage"
          alt="image cover"
        />
        <div class="absolute top-0 grid w-full h-full grid-cols-12 gap-0">
          <div class="w-full col-start-1 col-span-3 sm:bg-gradient-to-r from-gret-gray to-transparent" />
          <div class="w-full col-span-9 sm:bg-transparent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breakpoints from '../utils/breakpoints';

export default {
  name: 'ComboCover',
  data() {
    return {
      breakpoints,
      visible: true,
      creator: JSON.parse(this.communityCreator), 
    };
  },
  props: {
    communityName: {
      type: String,
      default: null,
    },
    communityDescription: {
      type: String,
      default: null,
    },
    communityImage: {
      type: String,
      default: null,
    },
    communityCreator: {
      type: String,
      default: null,
    },
    communityPrivacy: {
      type: String,
      default: null,
    },
    communityPrice: {
      type: String,
      default: null,
    },
    own: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    want() {
      //mostrar modal correspondiente
      this.$root.$emit('showAccessModal');
    },
  },
};
</script>
