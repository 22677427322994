<template>
  <div 
    class="bg-gret-smallHub cols-span-3 shadow-md shadow-gray-500 flex justify-center items-center rounded-lg hover:shadow-white h-12 sm:w-40 w-full cursor-pointer"
    @click="share"  
  >
    <button
      class="flex" 
    >
      <div class="font-semibold text-base text-gray-100 truncate hover:text-white uppercase">Compartir</div>
      <div class="flex items-center justify-center flex-shrink-0 ml-3">
        <gret-svg class="text-gret-smallHub" icon="chain"></gret-svg>
      </div>
    </button>
    <div v-if="showCopy" class="cursor-auto">
      <success-notification 
        text="URL del combo copiada en el portapapeles"
      />
    </div>
  </div>
</template>
  
<script>
  import GretSvg from './gret-svg.vue';
  import SuccessNotification from './success-notification.vue';

  export default {
    name: 'ComboShare',
    components: { GretSvg, SuccessNotification },
    props: {
      path: {
        type: String,
        required: true,
      },
      host: {
        type: String,
        required: true,
      },
      dnsDomain: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        showCopy: false,
      };
    },
    methods: {
      async share() {
        try {
          await navigator.clipboard.writeText(this.dnsDomain ? this.dnsDomain.domain : this.host + this.path);
          console.log("path", this.path, this.host)
          //alerta de copiado
          this.showCopy = true;
          setTimeout(() => { this.showCopy = false }, 5000);
        } catch($e) {
          //alerta error
        }
      }
    },
  };
</script>
    