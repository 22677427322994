<template>
  <div
    class="mt-2 md:mt-8 "
  >
    <div class="mb-6">
      <div class="flex items-center">
        <div class="mb-1 text-white gret-input-label">
          {{ $t('message.series.title', { fileType: $t(`message.files.${fileType}.type`)}) }}
        </div>
        <div class="pl-2 text-xs text-gret-text-gray">
          ({{ $t('message.series.required') }})
        </div>
      </div>
      <input
        name="serie[title]"
        class="block w-full h-12 px-4 text-gray-200 rounded shadow-xl outline-none bg-gret-gray-light hover:shadow-white border-gret-gray"
        :placeholder="$t('message.series.titlePlaceholder', { fileType: $t(`message.files.${fileType}.type`)}) "
        v-model="title"
      >
    </div>
    <div class="mb-6">
      <div class="flex items-center">
        <div class="mb-1 text-white gret-input-label">
          {{ $t('message.series.description', { fileType: $t(`message.files.${fileType}.type`)}) }}
        </div>
        <div class="pl-2 text-xs text-gret-text-gray">
          ({{ $t('message.series.required') }})
        </div>
      </div>
      <input
        name="serie[description]"
        class="block w-full h-12 px-4 text-gray-200 rounded shadow-xl outline-none bg-gret-gray-light hover:shadow-white border-gret-gray"
        :placeholder="$t('message.series.descriptionPlaceholder', { fileType: $t(`message.files.${fileType}.type`)}) "
        v-model="description"
      >
    </div>
    <div class="mb-6">
      <div class="mb-1 text-white gret-input-label">
        {{ $t('message.series.image') }}
      </div>
      <div class="mb-1 text-gret-text-gray gret-input-label">
        {{ $t('message.series.imagePreview', { fileType: $t(`message.files.${fileType}.type`)}) }}
      </div>
      <file-upload
        name="serie[thumbnail]"
        type="image"
        :button-label="$t('message.series.selectImage')"
        v-model="thumbnail"
      />
    </div>
    <div class="mb-6">
      <serie-form-access-configuration
        :require-payment-options="requirePaymentOptions"
        :privacy-options="privacyOptions"
        :payment-type-options="paymentTypeOptions"
        :initial-phone-number="initialPhoneNumber"
        :enable-phone-number-change="enablePhoneNumberChange"
        :visible-by-consumers="visibleByConsumers"
      />
    </div>
    <slot />
  </div>
</template>
<script>
import FileUpload from './file-upload.vue';
import SerieFormAccessConfiguration from './serie-form-access-configuration.vue';

export default {
  name: 'SerieFormFields',
  components: {
    FileUpload,
    SerieFormAccessConfiguration,
  },
  props: {
    fileType: { type: String, default: null },
    requirePaymentOptions: { type: Array, required: true },
    privacyOptions: { type: Array, required: true },
    paymentTypeOptions: { type: Array, required: true },
    initialPhoneNumber: { type: String, default: '' },
    enablePhoneNumberChange: { type: Boolean, default: false },
    editCommunityPath: { type: String, default: '' },
    visibleByConsumers: { type: Boolean, required: true },
  },
  data() {
    return {
      showForm: false,
      title: '',
      description: '',
      thumbnail: null,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit', {
        title: this.title,
        description: this.description,
        thumbnail: this.thumbnail });
    },
  },
};
</script>
