<template>
  <div v-if="content === false" class="flex flex-row items-center w-full">
    <input
      name="search"
      id="search_input"
      class="flex-1 placeholder-gray-600 bg-transparent focus:outline-none"
      :placeholder="placeholder"
      v-model="searchTerm"
    >
    <button
      type="submit"
      class="pl-1 bg-transparent focus:outline-none focus-visible:ring focus-visible:ring-gray-200 focus-visible:ring-offset-4 focus-visible:ring-offset-gray-400"
      :disabled="!searchTerm"
    >
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" v-svg-inline="" v-bind:viewbox="'0 0 24 24'" v-bind:class="'w-8 h-8 text-gray-600 fill-current hover:text-gray-700 focus:outline-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
    </button>
  </div>
  <div v-else class="flex flex-row items-center w-full">
    <button
      type="submit"
      class="absolute pl-1 bg-transparent focus:outline-none focus-visible:ring focus-visible:ring-gray-200 focus-visible:ring-offset-4 focus-visible:ring-offset-gray-400"
    >
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" v-svg-inline="" v-bind:viewbox="'0 0 24 24'" v-bind:class="'w-8 h-8 text-gray-600 fill-current hover:text-gray-700 focus:outline-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
    </button>
    <input
      name="search"
      id="search_input"
      class="block w-full h-12 pl-10 rounded-lg outline-none bg-gret-inputBlack text-white border border-gray-500"
      :placeholder="placeholder"
      v-model="searchTerm"
    >
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    placeholder: {
      type: String,
      default: 'Buscar...',
    },
    previousSearch: {
      type: String,
      default: '',
    },
    content: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: this.previousSearch,
    };
  },
};
</script>
