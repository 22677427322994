<template>
  <canvas
    ref="canvas"
    class="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
    :height="initialCanvasSize"
    :width="initialCanvasSize"
  />
</template>

<script>
import { decode } from 'blurhash';

export default {
  props: {
    blurhash: { type: String, required: true },
  },
  data() {
    return {
      initialCanvasSize: 32,
    };
  },
  mounted() {
    const pixels = decode(this.blurhash, this.initialCanvasSize, this.initialCanvasSize);
    const imageData = new ImageData(pixels, this.initialCanvasSize, this.initialCanvasSize);
    const context = this.$refs.canvas.getContext('2d');
    context.putImageData(imageData, 0, 0);
  },
};
</script>
