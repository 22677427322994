<template>
  <div>
    <div
      v-if="!hideTitle"
      class="mb-6 text-lg font-medium text-white md:text-xl"
    >
      {{ $t('message.content.accessibility') }}
    </div>
    <div
      class="mb-1 gret-dark-input-label"
      :class="hideTitle && 'mt-6'"
    >
      {{ $t('message.content.thisContent') }}
    </div>
    <template v-if="!requirePaymentValue">
      <div class="mb-1 gret-dark-input-label text-gret">
        {{ $t('message.content.accessibilityOptions') }} :
      </div>
      <single-select-buttons-series
        :attribute-name="attributeName('privacy')"
        :options="privacyOptions"
        v-model="privacyValue"
        class="mb-6"
      />
      <template v-if="manualAuthorization">
        <label
          for="file_phone_number"
          class="mb-1 gret-dark-input-label"
        >
          {{ $t('message.communities.phoneNumber') }}
        </label>
        <div
          v-if="!enablePhoneNumberChange"
          class="mb-1 text-gret-text-gray gret-input-label"
        >
          {{ $t('message.communities.phoneNumberExplanation') }}
          <a
            :href="editCommunityPath"
            target="_blank"
            class="text-gret hover:underline"
          >
            {{ $t('message.communities.edit') }}
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" v-bind:svg-inline="''" v-bind:class="'inline w-3 fill-current'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1H5a2 2 0 00-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1v5c0 .55-.45 1-1 1zM14 4c0 .55.45 1 1 1h2.59l-9.13 9.13a.996.996 0 101.41 1.41L19 6.41V9c0 .55.45 1 1 1s1-.45 1-1V3h-6c-.55 0-1 .45-1 1z"/></svg>
          </a>
        </div>
        <div
          v-else
          class="mb-1 text-gret-text-gray gret-input-label"
        >
          {{ $t('message.communities.phoneNumberMissing') }}
        </div>
        <input
          type="hidden"
          :value="phone"
          :name="attributeName('phone_number')"
          id="file_phone_number"
        >
        <div class="flex">
          <vue-tel-input 
            v-model="phone" 
            v-on:country-changed="countryChanged"
            class="tooltip-arrow-series mb-5 w-full hover:opacity-90 bg-gret-blueBlack"
            :inputOptions="optionsPlaceholder"
            :validCharactersOnly="true"
          >
            <template v-slot:arrow-icon>
              <span class="ml-1 text-gret display-none"></span>
            </template>
          </vue-tel-input>
        </div>
        <label
          for="file_whatsapp_msg"
          class="mb-1 gret-dark-input-label"
        >
          {{ $t('message.content.whatsappMessage') }}
        </label>
        <div class="mb-1 text-gret-text-gray gret-input-label">
          {{ $t('message.content.whatsappMessageExplanation') }}
        </div>
        <input
          type="text"
          :value="initialWhatsappMsg"
          :name="attributeName('whatsapp_msg')"
          id="file_whatsapp_msg"
          class="mb-6 shadow-xl gret-dark-input border-gret-gray bg-gret-gray-light hover:shadow-white"
        >
      </template>
    </template>
  </div>
</template>

<script>
import SingleSelectButtonsSeries from './single-select-buttons-series';
import { VueTelInput } from 'vue-tel-input'

export default {
  components: { SingleSelectButtonsSeries, VueTelInput },
  props: {
    entityName: { type: String, required: true },
    requirePaymentOptions: { type: Array, required: true },
    privacyOptions: { type: Array, required: true },
    paymentTypeOptions: { type: Array, required: true },
    initialWhatsappMsg: { type: String, default: '' },
    initialPhoneNumber: { type: String, default: '' },
    initialSinglePaymentPrice: { type: Number, default: null },
    enablePhoneNumberChange: { type: Boolean, default: false },
    editCommunityPath: { type: String, default: '' },
    visibleByConsumers: { type: Boolean, required: true },
    hideTitle: { type: Boolean, default: false },
  },
  data() {
    return {
      requirePaymentValue: this.requirePaymentOptions.find(option => option.initialValue).value,
      privacyValue: this.privacyOptions.find(option => option.initialValue).value,
      paymentTypeValue: this.paymentTypeOptions.find(option => option.initialValue).value,
      phone: this.initialPhoneNumber,
      optionsPlaceholder: { placeholder: "" },
    };
  },
  computed: {
    manualAuthorization() {
      return this.privacyValue === 'manual_authorization';
    },
    singlePayment() {
      return this.paymentTypeValue === 'single_payment';
    },
  },
  methods: {
    attributeName(attribute) {
      return `${this.entityName}[${attribute}]`;
    },
    countryChanged(country) {
      this.phone = '+' + country.dialCode;
      this.optionsPlaceholder.placeholder = '+' + country.dialCode;
    },
  },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>

<style>
  .vue-tel-input.tooltip-arrow-series {
    border-style: solid;
    z-index: 1;
    border-color: #2B3D4F;
    background-color: #2B3D4F;
    color: black;
    padding: 1px;
  }
  .vue-tel-input.tooltip-arrow-series input{
    background-color: #1D2C3C;
    color: white;
    height: 3rem;
  }
</style>
