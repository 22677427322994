<template>
  <div
    class="p-4 mb-6 rounded-md"
    :class="isError ? 'text-red-700 bg-red-100' : 'text-gret bg-gray-200'"
  >
    <div class="flex flex-row items-start justify-between ">
      <div class="mb-2 font-bold">
        {{ mainText }}
      </div>
      <button
        v-if="canClose"
        @click="$emit('close')"
        class="relative top-0 right-0 text-gret focus:outline-none hover:opacity-50"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" v-bind:svg-inline="''" v-bind:class="'w-6 h-6 text-gray-600 fill-current'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="#2B3D4F"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
      </button>
    </div>
    <div>
      {{ secondaryText }}
    </div>
    <a
      v-if="actionPath"
      class="mt-4 gret-button gret-button--gret small"
      :href="actionPath"
    >
      {{ actionText }}
    </a>
    <a
      v-else-if="communityId"
      class="mt-4 gret-button gret-button--gret small"
      :href="planUrl"
    >
      activar suscripción
    </a>
  </div>
</template>

<script>
export default {
  props: {
    communityId: {
      type: String,
      default: null,
    },
    actionPath: {
      type: String,
      default: null,
    },
    actionText: {
      type: String,
      default: null,
    },
    mainText: {
      type: String,
      required: true,
    },
    secondaryText: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    canClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    planUrl() {
      return `/communities/${this.communityId}/manage/community_plan`;
    },
  },
};
</script>

<style>

</style>
