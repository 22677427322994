<template>
  <div>
    <input
      type="hidden"
      :name="name"
      v-model="hiddenContent"
    >
    <VueTrix
      class="h-1/3 gret-dark-input gret-dark-text-area border-gret-gray bg-gret-gray-light hover:shadow-white"
      :name="name"
      type="text"
      :placeholder="placeholder"
      :src-content="content"
      @trix-file-accept="handleFileAccept"
      v-bind="$attrs"
      v-model="content"
    />
  </div>
</template>
<script>
import VueTrix from 'vue-trix';

export default {
  components: {
    VueTrix,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      content: null,
      hiddenContent: null,
    };
  },
  methods: {
    handleFileAccept(event) {
      event.preventDefault();
    },
  },
  mounted() {
    const parsedValue = JSON.parse(this.value);
    this.content = parsedValue.body;
  },
  watch: {
    content(newContent) {
      this.hiddenContent = newContent;
    },
  },
};
</script>
<style>

trix-toolbar .trix-button--icon::before {
  opacity: 1;
}

trix-toolbar .trix-button--icon-bold::before {
  background-image: url('../../assets/images/bold.svg');
}

trix-toolbar .trix-button--icon-italic::before {
  background-image: url('../../assets/images/italic.svg');
}

trix-toolbar .trix-button--icon-strike::before {
  background-image: url('../../assets/images/strike.svg');
}

trix-toolbar .trix-button--icon-link::before {
  background-image: url('../../assets/images/link.svg');
}

trix-toolbar .trix-button--icon-bold.trix-active::before {
  @apply bg-gret-gray-dark;
}

trix-toolbar .trix-button--icon-italic.trix-active::before {
  @apply bg-gret-gray-dark;
}

trix-toolbar .trix-button--icon-strike.trix-active::before {
  @apply bg-gret-gray-dark;
}

trix-toolbar .trix-button--icon-link.trix-active::before {
  @apply bg-gret-gray-dark;
}

trix-toolbar .trix-button-group--file-tools {
  display: none;
}

trix-toolbar .trix-button-group--block-tools {
  display: none;
}

trix-toolbar .trix-button-group--history-tools {
  display: none;
}

trix-toolbar .trix-button--icon-attach {
  display: none;
}

.trix-content {
  @apply h-32;
  overflow-y: auto;
}

trix-toolbar .trix-button-group button {
  @apply bg-gret-gray-light;
  @apply border-b-0;
}

trix-toolbar .trix-button-group button:hover {
  @apply bg-gret-gray-dark;
}

trix-toolbar .trix-button-group button:focus {
  @apply bg-gret-gray-dark;
}

trix-toolbar .trix-dialog--link {
  @apply bg-gret-gray;
}

trix-toolbar .trix-dialog--link.trix-active {
  @apply bg-gret-gray;
}

trix-toolbar .trix-input--dialog {
  @apply bg-gret-gray-light;
}

trix-toolbar .trix-input--dialog.trix-validate {
  @apply text-gret-gray;
  @apply bg-gret-gray-light;
}

trix-toolbar .trix-button--dialog {
  @apply bg-gret-gray-light;
  @apply text-white;
}

.trix-content a {
  @apply text-gret;
  @apply underline;
}

</style>
