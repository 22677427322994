<template>
  <a
    class="sm:flex"
    :href="episode.url"
  >
    <div
      class="w-full max-w-xl text-white sm:flex-row hover:bg-gret-gray-lights bg-gret-gray"
    >
      <lazy-image
        class="w-full h-full aspect-w-16 aspect-h-9"
        :blurhash="episode.blurhash"
        :src="episode.thumbnail.medium.url"
        :webp-src="episode.thumbnail.webpMedium.url"
        alt="episode thumbnail"
      />
    </div>
    <div class="w-full sm:relative sm:overflow-y-scroll bg-gradient-to-b md:bg-gradient-to-r from-gret-black to-gret-gray hover:to-gret-gray-light">
      <div class="flex w-full h-full sm:px-4 md:px-6 sm:absolute">

        <div class="flex flex-col w-full h-full md:flex-row">
          <div class="h-full pb-5 md:w-2/3">
            <div
              class="inline-flex items-center h-6 px-2 mb-1 text-xs font-medium text-white uppercase align-middle rounded-sm sm:mx-4 md:mx-6 bg-gret sm:mb-2"
              :class="showSubscribersOnlyTag || 'invisible'"
            >
              {{ $t('message.files.subscribersOnly') }}
            </div>
            <div class="px-4 tracking-wide text-md sm:px-4 md:px-6 md:text-lg">
              <div class="capitalize text-gret-text-gray">
                {{ $t(`message.files.${episode.fileType}.type`) }} {{ number }}
              </div>
              <div class="my-4 font-bold text-white">
                {{ episode.title }}
              </div>
              <div class="my-4 text-white md:hidden">
                {{ episode.subtitle }}
              </div>
              <div class="my-4 text-gret-text-gray">
                {{ episode.description }}
              </div>
            </div>
          </div>
          <div class="h-full px-4 pt-5 pb-5 text-sm border-t-2 lg:text-md md:border-t-0 lg:pl-8 md:w-1/3 border-gret-gray-light">
            <div class="hidden my-4 md:inline-block text-gret-text-gray">
              {{ episode.subtitle }}
            </div>
            <div class="flex flex-row justify-between w-full md:flex-col">
              <div class="my-4 text-gret-text-gray">
                {{ formatDate(episode.createdAt) }}
              </div>
              <div
                v-if="episode.duration"
                class="p-2 border rounded-md md:my-4 max-w-min text-gret-text-gray border-gret-text-gray whitespace-nowrap"
              >
                {{ formatDuration(episode.duration) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import formatDate from '../utils/format-date';
import formatDuration from '../utils/format-duration';

export default {
  name: 'SerieContentEpisode',
  props: {
    episode: {
      type: Object,
      default: null,
    },
    number: {
      type: Number,
      required: true,
    },
    serieAccessibilityControl: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    formatDate,
    formatDuration,
  },
  computed: {
    showSubscribersOnlyTag() {
      return !this.serieAccessibilityControl && this.episode.subscribersOnly;
    },
  },
};
</script>
