<template>
  <dropdown
    dark
    button-class="bg-gret-smallHub shadow-md shadow-gray-500 flex justify-center items-center rounded-lg hover:shadow-white h-12 w-full text-white text-sm font-bold px-4 py-3.5"
  >
    <template v-slot:button>
      <div class="flex items-center justify-center w-full uppercase">
        <div>Filtrar por</div>
        <gret-svg
          class="h-6 ml-2"
          icon="keyboard_arrow_down"
        />
      </div>
    </template>
    <template v-slot:menu>
      <div
        v-for="(icon, type) in fileIcons"
        :key="type"
        class="flex items-center px-3 py-2 rounded-md last:mb-0 hover:bg-gret-black text-gret-text-gray hover:text-white"
        @click="change(type)"
      >
        <div class="flex-1 capitalize whitespace-no-wrap">
          {{ $t(`message.files.${type}s`) }}
        </div>
        <gret-svg
          class="w-6 h-6 ml-6"
          :icon="icon.type"
        />
      </div>
    </template>
  </dropdown>
</template>
<script>
import fileIcons from '../../assets/json/file_icons.json';
import GretSvg from './gret-svg.vue';
import Dropdown from './dropdown.vue';

export default {
  name: 'ManageFeedFilterDropdown',
  components: {
    GretSvg,
    Dropdown,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    showEvents: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fileIcons,
    };
  },
  methods: {
    change(value) {
      if(value == "all") value = null;
      this.$emit('input', value);
    },
  },
};
</script>
  