<template>
  <div class="w-full px-2 md:pl-8 py-4">
    <div class="text-white uppercase grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6">
      <div 
        v-for="(item, key) in sections"
        :key="`tab-${key}`"  
        class="flex justify-start"
      >
        <div
          class="text-xs transition-colors duration-500 leading-5 tracking-widest border-b-2 font-semi-bold sm:text-base lg:inline hover:border-gret-downloadFrom px-2 pb-2 pt-4 cursor-pointer"
          :class="selectedOption == key ? 'border-gret-downloadFrom' : 'border-gret-gray-dark'"
          @click="selectOption(key)"  
        >
          {{ item.title }}({{ item.files.length + item.series.length }})
        </div>
      </div>
    </div>
    <div
      class="flex flex-col justify-center lg:justify-start"
    >
      <div
        class="mt-2 mb-4 tracking-wider text-justify sm:gap-10 text-white mt-10 pl-3 uppercase text-xl font-semibold border-l-8 border-gret-downloadFrom"
      >
        {{ selectedContent.title }} <label class="capitalize font-normal text-xs leading-3 tracking-wider text-gray-300"> ({{ selectedContent.files.length + selectedContent.series.length }} Ítems)</label>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          <content-gallery-file
            v-for="(item, id) in parsedContentSelect.slice(0, itemCount)"
            :key="`file-${item.type}-${id}`"
            :file="item"
            class="mx-auto sm:mx-0"
            :serie="item.serie"
          />
      </div>
      <div 
        v-if="parsedContentSelect.length > 8 && itemCount !== parsedContentSelect.length"
        class="mt-5 text-white flex justify-center"
      >
        <button
          class="flex uppercase font-semibold tracking-widest"
          @click="seeMore"
        > 
          ver más 
          <gret-svg
            class="h-6 ml-2 text-white"
            icon="keyboard_arrow_down"
          />
        </button> 
        
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import debounce from 'lodash.debounce';
import breakpoints from '../utils/breakpoints';
import ContentGalleryFile from './content-gallery-file';
import ManagershipFileNew from './managership-file-new.vue';
import fileIcons from '../../assets/json/file_icons.json';
import GretSvg from './gret-svg.vue';

const SCROLL_DEBOUNCE_TIME = 50;

export default {
  name: 'ContentGallerySection',
  components: {
    ContentGalleryFile,
    ManagershipFileNew,
    GretSvg
  },
  data() {
    return {
      itemCount: 8,
      fileIcons,
      selectedOption: this.sections.length > 0 ? 0 : '',
      breakpoints,
      dataTypes: {
        video: 'videoData',
        audio: 'audioData',
        text: 'textDocumentData',
        service: 'serviceData',
        product: 'productData',
      },
      filesPerRow: {
        all: 1,
        xs: 1,
        sm: 3,
        md: 3,
        lg: 4,
        xl: 5,
      },
      scrolledFullyRight: null,
      scrolledFullyLeft: null,
      scrollContainerHeight: 0,
    };
  },
  props: {
    alert: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    topLine: {
      type: Boolean,
      default: false,
    },
    sections: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.calculateScrolledFullyRight();
    this.calculateScrolledFullyLeft();
    this.calculateScrollContainerHeight();
  },
  computed: {
    selectedContent() {
      return this.sections[this.selectedOption];
    },
    parsedContentSelect() {
      this.selectedContent.files = this.selectedContent.files.map(file => ({
        ...file,
        //info: file.type !== 'service' && file.type !== 'product' ? JSON.parse(file[this.dataTypes[this.type]]) : null,
        type: file.fileType,
        serie: false }));
      this.selectedContent.series = this.selectedContent.series.map(serie => ({
        ...serie,
        type: serie.fileType,
        serie: true }));
      return [...this.selectedContent.files, ...this.selectedContent.series];
    },
    scrollContainerId() {
      return `scroll-container-${this.title}`;
    },

    showScrollArrows() {
      const hiddenFiles = this.files.length > this.filesPerRow[breakpoints.is];

      return this.scrollable && this.scrollContainerHeight > 0 && hiddenFiles;
    },

    showScrollLeftArrow() {
      return this.showScrollArrows && !this.scrolledFullyLeft;
    },

    showScrollRightArrow() {
      return this.showScrollArrows && !this.scrolledFullyRight;
    },
  },
  methods: {
    seeMore() {
      this.itemCount = this.selectedContent.files.length + this.selectedContent.series.length;
    },
    selectOption(option) {
      this.selectedOption = option;
    },
    scrollContainer() {
      return document.getElementById(this.scrollContainerId);
    },
    calculateScrollContainerHeight() {
      const container = this.scrollContainer();
      this.scrollContainerHeight =container && container.clientHeight;
    },
    calculateScrolledFullyRight() {
      const container = this.scrollContainer();
      this.scrolledFullyRight = container && container.scrollWidth === container.clientWidth + container.scrollLeft;
    },
    calculateScrolledFullyLeft() {
      const container = this.scrollContainer();
      this.scrolledFullyLeft = container && container.scrollLeft === 0;
    },
    scrollRight() {
      this.scrollContainer().scrollBy({ left: this.scrollContainer().offsetWidth, behavior: 'smooth' });
    },
    scrollLeft() {
      this.scrollContainer().scrollBy({ left: -this.scrollContainer().offsetWidth, behavior: 'smooth' });
    },
    checkScrollPosition: debounce(function () {
      this.calculateScrolledFullyRight();
      this.calculateScrolledFullyLeft();
    }, SCROLL_DEBOUNCE_TIME),
  },
};
</script>

<style scoped>
.sidebar {
  min-width: 250px;
}
</style>
