<template>
  <svg width="100" height="100" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'spinner'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M92.5 50c0 23.472-19.028 42.5-42.5 42.5S7.5 73.472 7.5 50 26.528 7.5 50 7.5 92.5 26.528 92.5 50z" stroke="#EDF2F7" stroke-width="15"/><path fill-rule="evenodd" clip-rule="evenodd" d="M50 0C22.386 0 0 22.386 0 50h15c0-19.33 15.67-35 35-35V0z" fill="#2679FF"/></svg>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 1s linear infinite;
}
</style>
