<template>
  <div>
    <button
      class="mr-3 inline-flex items-center justify-center px-3 py-1 h-full text-xs font-medium tracking-wide text-white uppercase border-2 rounded cursor-pointer border-gret-grayButton bg-gret-grayButton hover:opacity-90 hover:border-gray-500"
      @click="openModal"
    >
      <gret-svg icon="delete" class="w-6 h-6" />
    </button>

    <cancel-delete-modal
      v-if="showModal"
      :title="'¿Estás seguro de eliminar ' + communityName + '?'"
      type="delete"
      @cancel="deleteCombo"
      @close="hideModal"
    />
  </div>
</template>

<script>
import CancelDeleteModal from './cancel-delete-modal.vue';
import combosApi from '../api/combos';
import GretSvg from './gret-svg.vue';

export default {
  name: 'DeleteCombo',
  components: { CancelDeleteModal, GretSvg },
  props: {
    communityId: { type: String, default: null },
    communityName: { type: String, default: null },
    reloadPath: { type: String, default: null },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    },
    async deleteCombo() {
      await combosApi.deleteCombo(this.communityId);
      window.location.href = this.reloadPath;
    },
  },
};
</script>
