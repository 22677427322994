<template>
  <file-events-download-base
    :community-id="communityId"
    :file-type="fileType"
    :file-id="fileId"
    :event-type="eventType"
  >
    <div class="flex">
      <img
        v-svg-inline
        viewBox="0 0 24 24"
        class="w-6 h-6 my-1 text-gray-700 fill-current"
        :src="require(`../../assets/images/${this.iconName}.svg`)"
      >
      <span class="hidden ml-2 md:iblock">{{ this.eventCount }}</span>
      <span class="block ml-2 md:hidden">{{ this.eventText }}</span>
    </div>
  </file-events-download-base>
</template>

<script>
import FileEventsDownloadBase from './file-events-download-base';

export default {
  name: 'FileEventsDownloadIconBtn',
  components: {
    FileEventsDownloadBase,
  },
  props: {
    communityId: {
      type: Number,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
    fileId: {
      type: Number,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
    eventCount: {
      type: Number,
      required: true,
    },
    eventText: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      required: true,
    },
  },
};
</script>
