<template>
  <div
    class="flex flex-col justify-between p-4 space-y-2 rounded-lg bg-gret-gray md:h-full"
  >
    <div class="flex flex-row justify-between">
      <div class="font-medium md:text-2xl text-gret">
        {{ titleText }}
      </div>

      <div
        class="text-sm text-center gret-button xsmall md:small"
        :class="[{disabled: !requestPayoutEnabled()}]"
        @click="requestPayout(true)"
        :disabled="!requestPayoutEnabled()"
        v-tooltip="!requestPayoutEnabled() && {
          content: 'Debes ingresar tus datos bancarios para solicitar tus fondos',
          classes: 'text-gretGrayLight text-xs bg-gray-300 p-1 rounded',
        }"
      >
        {{ $t('message.payouts.requestPayout') }}
      </div>
    </div>
    <PayoutBalance
      :balance="balance"
      :country-id="countryId"
    />
    <div class="p-2 rounded-md bg-gret-gray-dark">
      <div
        v-if="!loading && paymentsForPayoutSum <= 0"
        class="col-span-1 px-2 py-1 mx-1 text-sm text-center border rounded-md md:h-full justify-self-center text-gret bg-gret bg-opacity-20 border-gret"
      >
        {{ $t('message.payouts.noPayout') }}
      </div>
      <div
        v-else-if="!loading && !!payout"
        class="grid grid-cols-2 space-y-1 text-white md:space-y-2 justify-items-center"
      >
        <div class="justify-self-start">
          <p class="text-sm font-medium text-blueGray-100 ">
            {{ $t('message.payouts.payoutBeforeFee') }}
          </p>
          <p class="text-xs text-blueGray-300">
            ({{ $t('message.payouts.settlementPeriodDisclaimer') }}{{ settlementPeriodDate | date }})
          </p>
        </div>
        <div class="flex flex-col items-end w-full md:items-center md:justify-between md:flex-row">
          <div class="font-bold md:justify-self-end ">
            {{ centsToUnit(payout.usdAmountCents) | currencyWithCode('USD') }}
          </div>
          <div
            v-if="!!currency"
            class="text-sm text-white"
          >
            {{ centsToUnit(payout.localAmountCents) | currencyWithCode(currency) }}
          </div>
          <div v-else />
        </div>
        <p class="text-sm text-gret-orange justify-self-start">
          {{ $t('message.payouts.fees') }}
        </p>
        <div class="flex flex-col items-end w-full md:items-center md:justify-between md:flex-row">
          <div class="font-bold md:text-base text-gret-orange ">
            - {{ centsToUnit(payout.usdFeeCents) | currencyWithCode('USD') }}
          </div>
          <div
            v-if="!!currency"
            class="text-sm text-gret-orange"
          >
            - {{ centsToUnit(payout.localFeeCents) | currencyWithCode(currency) }}
          </div>
          <div v-else />
        </div>
        <div class="w-full col-span-2 my-0.5 border border-t border-gret-gray" />
        <p class="font-bold justify-self-start">
          {{ $t('message.payouts.payoutAfterFee') }}
        </p>
        <div class="flex flex-col items-end w-full md:items-center md:justify-between md:flex-row">
          <div class="text-xl font-bold">
            {{ centsToUnit(payout.usdAmountAfterFeeCents) | currencyWithCode('USD') }}
          </div>
          <div
            v-if="!!currency"
            class="text-lg"
          >
            {{ centsToUnit(payout.localAmountAfterFeeCents) | currencyWithCode(currency) }}
            <span>*</span>
          </div>
          <div v-else />
        </div>
        <p
          class="col-span-2 mt-1 text-xs text-white md:text-sm"
        >
          {{ $t('message.payouts.localAmountDisclaimer') }}
        </p>
      </div>
    </div>

    <PayoutTransitMoney
      :title-text="$t('message.payouts.lastPayout')"
      :last-payout="lastPayout"
      :country-id="countryId"
    />
  </div>
</template>

<script>
import payoutApi from '../api/payouts';
import PayoutBalance from '../components/payout-balance';

import PayoutTransitMoney from './payout-transit-money.vue';

const settlementPeriodDays = process.env.SETTLEMENT_PERIOD;
export default {
  components: { PayoutBalance, PayoutTransitMoney },
  props: {
    titleText: { type: String, required: true },
    payments: { type: Array, required: true },
    communityId: { type: Number, required: true },
    currency: { type: String, default: null },
    lastPayout: { type: Object, default: null },
    countryId: { type: Number, default: null },
  },
  data() {
    return {
      payout: null,
      loading: false,
      error: null,
      settlementPeriodDays: parseFloat(settlementPeriodDays),
    };
  },
  computed: {
    paymentsWithoutPayout() {
      return this.payments.filter((payment) => payment.payout === null && payment.status === 'paid');
    },
    settlementPeriodDate() {
      const date = new Date();

      return date.setDate(date.getDate() - this.settlementPeriodDays);
    },
    paymentsForPayout() {
      return this.paymentsWithoutPayout.filter((payment) => new Date(payment.createdAt) < this.settlementPeriodDate);
    },
    paymentsForPayoutSum() {
      return this.paymentsForPayout.reduce((total, current) => total + current.usdAmountAfterFeeValue, 0);
    },
    balance() {
      return this.paymentsWithoutPayout.reduce((total, current) => total + current.usdAmountAfterFeeValue, 0);
    },
  },
  async mounted() {
    await this.requestPayout(false);
  },
  methods: {
    centsToUnit(amount) {
      const cents = 100;

      return amount / cents;
    },
    requestPayoutEnabled() {
      return this.paymentsForPayoutSum > 0 && !!this.currency;
    },
    createPayout() {
      return payoutApi.create(
        {
          payout:
          { communityId: this.communityId,
            localAmountCurrency: this.currency,
          },
          payments: this.paymentsForPayout.map((payment) => payment.id),
        },
      )
        .then(() => {
          this.loading = false;
          window.location.reload();
        })
        .catch((error) => {
          if (error.message) {
            this.errors = error.message;
          }
        });
    },
    newPayout() {
      return payoutApi.new(
        {
          payout:
          { communityId: this.communityId,
            localAmountCurrency: this.currency,
          },
          payments: this.paymentsForPayout.map((payment) => payment.id),
        },
      )
        .then((response) => {
          this.payout = response;
          this.loading = false;
        })
        .catch((error) => {
          if (error.message) {
            this.errors = error.message;
          }
        });
    },
    requestPayout(persist) {
      this.loading = true;
      if (!persist && this.paymentsForPayoutSum > 0) {
        this.newPayout();
      } else if (persist && this.requestPayoutEnabled()) {
        this.createPayout();
      }
      this.loading = false;
    },
  },
};
</script>
