<template>
  <transition
    enter-active-class="transition-transform duration-1000 transform"
    enter-class="translate-y-full"
  >
    <a
      v-if="display"
      href="https://gret.io/?utm_source=website&utm_medium=banner&utm_id=banner-website"
      class="fixed bottom-0 right-0 z-50 w-screen py-2 overflow-hidden text-xs text-center bg-gray-200 cursor-pointer text-gret"
    >
      {{ $t('footer.text') }}
    </a>
  </transition>
</template>
<script>
const DISPLAY_DELAY = 2000;

export default {
  data() {
    return ({
      display: false,
    });
  },
  mounted() {
    setTimeout(() => {
      this.display = true;
    }, DISPLAY_DELAY);
  },
};
</script>
