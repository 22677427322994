import api from './index';

export default {
  getCountries() {
    return api({
      method: 'GET',
      url: '/api/v1/countries/',
    });
  },
};
