<template>
  <div
    class="py-5 mx-5 sm:mx-10 sm:py-10"
    :class="anyOptions && 'min-h-1/2-screen'"
  >
    <div
      class="grid grid-cols-12 flex justify-around"
    >
      <div
        v-for="(option, key) in options"
        :key="`tab-${key}`"
        class="col-span-6 flex items-center mr-2 tracking-widest text-gray-100 uppercase font-semi-bold hover:text-white"
        :class="option.show || 'hidden'"
      >
        <div
          class="text-xs transition-colors duration-500 border-b-2 font-semi-bold sm:text-base lg:mr-2 lg:inline hover:border-gret-downloadFrom px-10 pb-2 cursor-pointer"
          :class="selectedOption == key ? 'border-gret-downloadFrom' : 'border-gret-gray-dark'"
          @click="selectOption(key)"
        >
          {{ option.name }}
        </div>
      </div>
    </div>
    <div
      class="flex flex-col justify-center file-content lg:justify-start"
    >
      <div
        class="mt-2 mb-4 text-justify whitespace-pre-line sm:gap-10 text-white mt-10 pl-3 uppercase text-xl font-semibold border-l-8 border-gret-downloadFrom"
        :class="colsStyle"
        v-text="selectedContent.name"
        v-linkified:options="{ className: 'underline text-blue-500' }"
      />
      <div
        class="mt-2 mb-4 text-justify whitespace-pre-line sm:gap-10 text-white"
        :class="colsStyle"
        v-html="selectedContent.content"
        v-linkified:options="{ className: 'underline text-blue-500' }"
      />
    </div>
  </div>
</template>

<script>
import breakpoints from '../utils/breakpoints';

export default {
  name: 'FileContent',
  data() {
    return {
      breakpoints,
      selectedOption: !!this.longDescription.body ? 'details' : !!this.questions.body ? 'questions' : '',
      options: {
        details: {
          name: 'detalles',
          content: this.longDescription ? this.longDescription.body : null,
          show: !!this.longDescription.body,
          twoCols: true,
        },
        questions: {
          name: 'recursos',
          content: this.questions ? this.questions.body : null,
          show: !!this.questions.body,
          twoCols: false,
        },
      },
    };
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    longDescription: {
      type: Object,
      default: null,
    },
    questions: {
      type: Object,
      default: null,
    },
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
    },
  },

  computed: {
    selectedContent() {
      return this.options[this.selectedOption];
    },
    longDescriptionBody() {
      return this.longDescription && this.longDescription.body;
    },
    colsStyle() {
      if (breakpoints.is === 'xs') return 'w-full';
      const BREAKPOINT = 2000;
      const descriptionLength = this.longDescriptionBody ? this.longDescriptionBody.length : 0;
      if (this.options[this.selectedOption].twoCols && descriptionLength > BREAKPOINT) return 'two-col';

      return 'w-1/2';
    },
    anyOptions() {
      return Object.values(this.options).reduce((result, { show }) => result || show, false);
    },
  },
  mounted() {
    this.$el.querySelectorAll('a').forEach((a) => {
      a.target = '_blank';
    });
  },
};
</script>

<style>
.two-col {
  column-count: 2;
}

.file-content a {
  @apply underline;
  @apply text-gret;
}
</style>
