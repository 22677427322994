<template>
  <div class="b-carousel-component">
    <b-carousel
      :arrow="false"
      :autoplay="false"
      :indicator="false"
      :pause-hover="false"
      v-model="fileIndex"
    >
      <b-carousel-item
        v-for="(file, index) in files"
        :key="index"
      >
        <div
          class="sm:flex"
          v-touch:swipe="clickPrevious"
          v-touch:swipe.left="clickNext"
        >
          <div class="relative sm:w-1/2">
            <lazy-image
              class="w-full h-full aspect-w-16 aspect-h-9 b-carousel-component__cover-img"
              :blurhash="file.blurhash"
              :src="file.thumbnail.medium.url"
              :webp-src="file.thumbnail.webpMedium.url"
              alt="content carrousel thumbnail"
            />
            <div class="absolute top-0 grid w-full h-full grid-cols-2">
              <div class="w-full col-start-2 sm:bg-gradient-to-l from-gret-gray" />
            </div>
            <div
              class="absolute top-0 left-0 flex content-center invisible w-10 h-full bg-opacity-50 sm:visible bg-gret-black"
            >
              <svg width="22" height="44" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'my-auto ml-auto mr-2 outline-none opacity-75 hover:opacity-100'" @click="clickPrevious" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.42 43.126L.286 21.422 21.99.286l-.57 42.84z" fill="#fff"/></svg>
            </div>
          </div>
          <div class="sm:w-1/2">
            <div
              class="grid grid-cols-10 bg-gret-gray bg-gradient-to-b sm:bg-gradient-to-l from-gret-black sm:from-gret-gray-dark aspect-w-3 aspect-h-2 sm:aspect-w-16 sm:aspect-h-9 sm:pr-10"
            >
              <div class="flex flex-col justify-center h-full col-span-3 px-3 pb-4 from-black sm:col-start-2 sm:col-span-8">
                <div class="h-auto text-xl font-bold tracking-wide text-white sm:mt-10 md:text-xl lg:text-2xl xl:text-3xl line-clamp-2">
                  {{ file.title }}
                </div>
                <div
                  v-if="file.subtitle"
                  class="self-start mt-2 mb-1 text-white md:mt-5 sm:text-md md:text-base lg:text-xl xl:text-2xl font-semi-bold line-clamp-1"
                >
                  {{ file.subtitle }}
                </div>
                <div
                  v-if="file.description "
                  class="mt-2 text-sm text-justify md:mt-5 md:text-base lg:text-md xl:text-lg text-gret-text-gray line-clamp-3 lg:line-clamp-4"
                >
                  {{ file.description }}
                </div>
                <div
                  class="self-start mt-2 text-sm font-medium text-white md:mt-5 sm:font-bold lg:text-md xl:text-lg"
                >
                  {{ formatFileInfo(file) }}
                </div>
                <a
                  v-if="isSerie(file)"
                  class="z-10 flex self-start p-1 mt-2 opacity-75 md:mt-5 hover:opacity-100 focus:shadow-white gret-button hover:text-white"
                  :href="file.url"
                >
                  <div 
                    v-if="(file.fileType !== 'service' && file.fileType !== 'product')"
                    class="w-10 h-10 mr-2 "
                  >
                    <img
                      v-svg-inline
                      viewBox="0 0 24 24"
                      class="w-full h-full text-white fill-current"
                      :src="require(`../../assets/images/${icons[file.fileType].name}.svg`)"
                    >
                  </div>
                  <div class="pr-2 font-bold text-white normal-case sm:text-sm md:text-md lg:text-lg">
                    {{ formatSeriePlayMessage(file) }}
                  </div>
                </a>
                <a
                  v-else
                  class="z-10 flex self-start p-1 mt-2 opacity-75 md:mt-5 hover:opacity-100 focus:shadow-white gret-button hover:text-white"
                  :href="file.url"
                >
                  <div class="w-10 h-10 mr-2 ">
                    <img
                      v-svg-inline
                      viewBox="0 0 24 24"
                      class="w-full h-full text-white fill-current"
                      :src="require(`../../assets/images/${icons[file.type].name}.svg`)"
                    >
                  </div>
                  <div class="pr-2 font-bold text-white normal-case sm:text-sm md:text-md lg:text-lg">
                    {{ formatFilePlayMessage(file) }}
                  </div>
                </a>
              </div>
              <div
                class="absolute flex content-center invisible w-full h-full sm:visible"
              >
                <svg width="22" height="44" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'my-auto ml-auto mr-2 outline-none opacity-75 hover:opacity-100'" @click="clickNext" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.571 43.126l21.135-21.704L0 .286l.571 42.84z" fill="#fff"/></svg>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-item>
    </b-carousel>
    <div class="flex justify-center w-full">
      <div
        v-for="indicator in filesLength"
        :key="`indicator-${indicator}`"
        class="w-3 h-3 mx-1 my-2 transition-colors duration-100 bg-gray-500 rounded-full sm:my-3 hover:bg-gret-text-gray"
        :class="currentIndicator(indicator) && 'bg-gret-text-gray'"
        @click="()=>clickIndicator(indicator)"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import fileIcons from '../../assets/json/file_icons.json';
import formatYear from '../utils/format-year';
import formatDuration from '../utils/format-duration';
import formatFileSize from '../utils/format-filesize';

export default {
  name: 'ContentGalleryCarrousel',

  data() {
    return {
      interval: 6000,
      fileIndex: 0,
      windowFocus: true,
    };
  },
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
    audios: {
      type: Array,
      default: () => [],
    },
    texts: {
      type: Array,
      default: () => [],
    },
    services: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatFileSize,
    formatYear,
    formatDuration,
    autoplay() {
      setTimeout(() => {
        if (this.windowFocus) this.nextFile();
        this.autoplay();
      }, this.interval);
    },
    clearTimeouts() {
      const noofTimeOuts = setTimeout(() => {});
      for (let i = 0; i < noofTimeOuts; i++) {
        clearTimeout(i);
      }
    },
    clickNext() {
      this.clearTimeouts();
      this.nextFile();
      this.autoplay();
    },
    clickPrevious() {
      this.clearTimeouts();
      this.previousFile();
      this.autoplay();
    },
    nextFile() {
      this.fileIndex = (this.fileIndex + 1) % this.filesLength;
    },
    previousFile() {
      if (this.fileIndex === 0) {
        this.fileIndex = Math.max(0, this.filesLength - 1);
      } else {
        this.fileIndex -= 1;
      }
    },
    formatEpisodes(serie) {
      return this.$tc(`message.files.${serie.fileType}.episodes`, serie.episodesCount, { count: serie.episodesCount });
    },
    formatYearEpisodes(serie) {
      return `${this.formatYear(serie.createdAt)}, ${this.formatEpisodes(serie)}`;
    },
    formatYearSize(file) {
      if (file.info && file.info.metadata && file.info.metadata.size) {
        return `${this.formatYear(file.createdAt)}, ${formatFileSize(file.info.metadata.size)}`;
      }

      return this.formatYear(file.createdAt);
    },
    formatYearDuration(file) {
      if (file.duration) return `${this.formatYear(file.createdAt)}, ${this.formatDuration(file.duration)}`;

      return this.formatYearSize(file);
    },
    formatFileInfo(file) {
      if (file.type === 'serie') return this.formatYearEpisodes(file);
      else if (file.type === 'text') return this.formatYearSize(file);

      return this.formatYearDuration(file);
    },

    currentIndicator(indicator) {
      return indicator - 1 === this.fileIndex;
    },
    clickIndicator(indicator) {
      this.fileIndex = indicator - 1;
    },
    isSerie(file) {
      return file.type === 'serie';
    },
    formatFilePlayMessage(file) {
      return `${this.$t(`message.files.${file.type}.play`)} ${this.$t('message.files.now')}`;
    },
    formatSeriePlayMessage(serie) {
      return `${this.$t(`message.files.${serie.fileType}.play`)} ${this.$t('message.files.now')}`;
    },
  },

  computed: {
    icons() {
      return fileIcons;
    },
    allFiles() {
      return [
        ...this.videos.map(file => ({ ...file, info: JSON.parse(file.videoData), type: 'video' })),
        ...this.audios.map(file => ({ ...file, info: JSON.parse(file.audioData), type: 'audio' })),
        ...this.texts.map(file => ({ ...file, info: JSON.parse(file.textDocumentData), type: 'text' })),
        ...this.services.map(file => ({ ...file, type: 'service' })),
        ...this.products.map(file => ({ ...file, type: 'product' })),
        ...this.series.filter((serie) => (!!serie.fileType)).map(file => ({ ...file, type: 'serie' })),
      ].sort((a, b) => moment(b.createdAt).diff(a.createdAt));
    },
    filesLength() {
      const RECCOMMENDATIONS_LIMIT = 6;

      return Math.min(RECCOMMENDATIONS_LIMIT, this.allFiles.length);
    },
    files() {
      return this.allFiles.slice(0, this.filesLength);
    },
  },
  mounted() {
    window.addEventListener('blur', () => {
      this.windowFocus = false;
    });
    window.addEventListener('focus', () => {
      this.windowFocus = true;
    });
    this.autoplay();
  },
};
</script>
<style lang="scss">
.b-carousel-component {
  @import '../css/buefy.scss';

  .b-carousel-component__cover-img img {
    @apply h-full;
  }
}
</style>
