<template>
  <div
    class="px-4 py-2 space-y-2 border rounded-md bg-opacity-20 justify-self-center bg-gret border-gret"
  >
    <div class="flex md:justify-between">
      <div class="text-xs font-bold md:text-sm text-gret">
        {{ titleText }}
      </div>
      <div
        v-if="showLastPayout"
        class="p-2.5 text-xs text-center text-white bg-gray-700 rounded-full align-self-end justify-self-end"
      >
        {{ $t('message.payoutTransitMoney.requestedSince', { hoursSinceLastPayout: hoursSinceLastPayout.toString() }) }}
      </div>
    </div>
    <div
      v-if="showLastPayout"
      class="grid grid-cols-2"
    >
      <div>
        <p class="text-xs text-blueGray-400">
          {{ $t('message.payoutTransitMoney.requestedAmount') }}
        </p>
        <div class="flex items-end space-x-2">
          <p class="font-bold text-white text-blueGray-50">
            {{ centsToUnit(payout.usdAmountAfterFeeCents) | currencyWithCode('USD') }}
          </p>
          <p
            class="text-sm text-blueGray-300"
          >
            {{ centsToUnit(payout.localAmountAfterFeeCents) | currencyWithCode(payout.localAmountAfterFeeCurrency) }}
          </p>
        </div>
      </div>
      <div class="flex flex-col items-end">
        <p class="text-xs text-right text-end text-blueGray-400">
          {{ $t('message.payoutTransitMoney.requestDate') }}
        </p>
        <p class="text-sm font-medium text-blueGray-300">
          {{ payout.createdAt | date }}
        </p>
      </div>
    </div>
    <div
      v-else
    >
      <p class="m-2 text-sm text-center text-blueGray-200">
        {{ $t('message.payoutTransitMoney.requestEmpty') }}
      </p>
    </div>
  </div>
</template>

<script>
import { camelizeKeys } from 'humps';

const maxHoursSinceLastPayout = process.env.MAX_HOURS_SINCE_LAST_PAYOUT;
export default {
  props: {
    titleText: { type: String, required: true },
    lastPayout: { type: Object, default: null },
  },
  data() {
    return {
      payout: camelizeKeys(this.lastPayout),
      localAmount: this.lastPayout ? this.lastPayout.local_amount_cents : null,
      loading: false,
      error: null,
      maxHoursSinceLastPayout: parseFloat(maxHoursSinceLastPayout),
    };
  },
  computed: {
    hoursSinceLastPayout() {
      const difference = Math.abs(new Date(this.payout.createdAt) - new Date());

      // eslint-disable-next-line no-magic-numbers
      return Math.round(difference / (1000 * 3600));
    },
    showLastPayout() {
      return this.hoursSinceLastPayout < this.maxHoursSinceLastPayout;
    },
  },
  methods: {
    centsToUnit(amount) {
      const cents = 100;

      return amount / cents;
    },
  },
};
</script>
