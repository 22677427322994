<template>
  <div>
    <div class="relative inline-block w-full pointer">
      <select
        v-bind="$attrs"
        class="text-white border gret-dark-input border-gret-text-gray bg-gret-gray-light appearence-none"
        @change="e => $emit('input', e.target.value)"
        :value="value"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="mt-1 text-gray-200">
      {{ this.selectedOption && this.selectedOption.explanation }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExplainedSelect',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    selectedOption() {
      return this.options.find(option => option.value === this.value);
    },
  },
};
</script>
