<template>
  <div class="container mt-6">
    <div class="flex items-center justify-between p-4 mb-6 bg-yellow-300 rounded">
      <div class="font-bold text-yellow-800">
        El video se está procesando, esto puede tardar algunos minutos.<br>
        La página se actualizará automáticamente cuando el video esté listo.
      </div>
    </div>
  </div>
</template>

<script>
const POLLING_MS = 5000;

export default {
  name: 'VideoTranscodingStatus',
  props: {
    videoId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      status: true,
    };
  },
  mounted() {
    this.refreshStatus();
    setInterval(this.refreshStatus, POLLING_MS);
  },
  methods: {
    async refreshStatus() {
      const response = await fetch(`/api/v1/video_files/${this.videoId}/transcoding_status`);
      const { transcoding } = await response.json();
      if (!transcoding) location.reload();
    },
  },
};
</script>

<style>

</style>
