<template>
  <a
    class="relative flex flex-col w-full h-full max-w-xl text-white hover:bg-gret-gray-lights"
    :href="file.url"
  >
    <a
      v-if="file.serie"
      class="absolute right-0 z-10 flex items-start justify-center w-1/2 h-full mb-2 bg-opacity-40 bg-gret-seriesItem rounded-tr-lg rounded-br-lg"
      :href="file.url"
    >
      <div class="flex h-1/2">
        <div class="self-center font-medium text-3xl text-white">
          {{ file.episodesCount }} +
        </div>
      </div>
    </a>
    <lazy-image
      class="w-full h-full aspect-w-16 aspect-h-9"
      :blurhash="file.blurhash"
      :src="file.thumbnail.small.url"
      :webp-src="file.thumbnail.webpSmall.url"
      :rounded="true"
      alt="content thumbnail"
    />
    <div class="px-0 rounded-b-lg bg-gret-smallHub">
      <div class="flex items-center bg-gret-downloadTo rounded-sm font-semibold text-white px-4 py-2 h-7 text-xs mt-2 ml-2 w-1/3 gap-1 justify-center">
        <!--<gret-svg
          class="w-full h-full"
          :icon="icons[file.type].name"
          :key="key"
        />-->
          {{ file.type === "text" ? "Archivo" : file.type === "video" ? "Video" : file.type === "audio" ? "Audio" : file.type === "service" ? "Servicio" : "Producto" }}
      </div>
      <div class="flex pl-2 py-3">
        <div>
          <div class="font-bold tracking-wide text-white text-md line-clamp-1">
            {{ file.title }}
          </div>
        </div>
      </div>
      <div 
        class="flex p-2"
        :class="file.duration ? 'justify-between' : 'justify-end'"  
      >
        <div 
          v-if="file.duration"
          class="flex gap-2 items-center"
        >
          <gret-svg class="col-span-1 text-gray-100 hover:text-white w-4 h-4" icon="clock"></gret-svg>
          {{ file.duration }} min
        </div>
        <div>
          <gret-svg class="col-span-1 text-gray-100 hover:text-white" icon="keyboard_arrow_right"></gret-svg>
        </div>
      </div>
      <!--<div
        :class="{'invisible': !file.serie}"
        class="inline-flex items-center h-6 px-2 mx-16 my-2 text-xs font-medium text-white uppercase align-middle border rounded-sm bg-gret-gray-light border-gret-text-gray"
      >
        {{ $tc(`message.files.${file.type}.episodes`, file.episodesCount, { count: file.episodesCount }) }}
      </div>-->
      <!--<div
        v-if="file.type === 'text' && !file.serie"
        class="px-3 mt-2 text-sm text-gret-text-gray"
      >
        {{ fileExt(file) }}, {{ formatFileSize(file.info.metadata.size) }}
      </div>
      <div
        v-else
        class="px-3 mt-2 text-sm text-gret-text-gray"
      >
        {{ formatYear(file.createdAt) }} {{ file.duration && ',' }} {{ formatDuration(file.duration) }}
      </div>-->
    </div>
  </a>
</template>

<script>
import formatYear from '../utils/format-year';
import formatDuration from '../utils/format-duration';
import formatFileSize from '../utils/format-filesize';
import fileIcons from '../../assets/json/file_icons.json';
import GretSvg from '../components/gret-svg.vue';

export default {
  name: 'ContentGalleryFile',
  components: { GretSvg },
  data() {
    return {
      icons: fileIcons,
    };
  },
  props: {
    file: {
      type: Object,
      default: null,
    },
  },
  methods: {
    formatFileSize,
    formatYear,
    formatDuration,
    fileExt(file) {
      const parts = file.info.metadata.filename.split('.');

      return parts[parts.length - 1].toUpperCase();
    },
  },
};
</script>
