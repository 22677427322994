<template>
  <modal
    :open="open"
    @close="onClose"
  >
    <div class="flex flex-col p-8 pt-16 space-y-6 rounded-lg bg-gret-gray-dark">
      <h1 class="text-2xl text-center text-white">
        {{ $t('message.balanceDisclaimer.title') }}
      </h1>
      <p class="text-blueGray-50">
        {{ $t('message.balanceDisclaimer.description') }}
        <span class="text-gret">{{ $t('message.balanceDisclaimer.descriptionSpan') }}</span>
      </p>
      <div>
        <p class="font-bold text-gret">
          {{ $t('message.balanceDisclaimer.exampleTitle') }}
        </p>
        <p class="text-blueGray-50">
          {{ $t('message.balanceDisclaimer.example') }}
        </p>
      </div>
      <button
        class="gret-button small"
        @click="onClose"
      >
        {{ $t('message.balanceDisclaimer.understood') }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  name: 'BalanceDisclaimerModal',
  components: {
    Modal,
  },
  methods: {
    onClose() {
      this.open = false;
      this.$emit('close');
    },
  },
  data() {
    return {
      open: true,
    };
  },
};
</script>
