<template>
    <div class="mt-6">
      <div class="flex items-center">
        <input
          type="hidden"
          :name="checkbox.name"
          v-model="downloadable"
        >
        <input
          type="checkbox"
          class="w-6 h-6"
          name="checkboxDownloadable"
          :disabled="unpreviewable"
          v-model="checkboxDownloadable"
        >
        <label
          class="ml-2 text-base text-white leading-5 font-normal tracking-wider"
          for="checkboxDownloadable"
        >
          Se permiten descargas
        </label>
      </div>
      <p
        class="mt-1 text-xs text-gret-text-gray"
        :class="!unpreviewable && 'invisible'"
      >
        El tipo de archivo que estás subiendo siempre es descargable
      </p>
    </div>
</template>

<script>

export default {
  name: 'checkboxDownload',
  data() {
    return {
      downloadable: null,
      checkboxDownloadable: null,
      unpreviewable: false,
    };
  },
  props: {
    checkbox: { type: Object, default: null },
  },
  mounted() {
    this.checkboxDownloadable = this.checkbox.value;
  },
  watch: {
    checkboxDownloadable(newDownloadable) {
      this.downloadable = newDownloadable;
    },
    unpreviewable(newUnpreviewable) {
      if (newUnpreviewable) {
        this.checkboxDownloadable = true;
      }
    },
  },
};
</script>