<template>
  <div class="space-y-4">
    <div class="flex flex-row p-3 mb-4 space-x-2 border rounded-md border-gret bg-gret-gray-light">
      <div class="m-1 text-green-500">
        <img
          inline-svg
          class="w-10 h-10 text-green-500 fill-current"
          src="../../assets/images/warning.svg"
        >
      </div>
      <div class="text-white">
        {{ $t('message.dnsConfig.updateWarning') }}
      </div>
    </div>
    <div class="flex flex-col space-y-4">
      <div class="flex flex-row text-lg text-white">
        {{ $t('message.dnsConfig.domainInstructions') }}
      </div>
      <div class="flex flex-row justify-center">
        <table class="p-2 text-white border table-auto border-gret bg-gret-gray-light border-md">
          <thead>
            <tr>
              <th class="px-5 py-3 tracking-wider text-left text-white border-2 border-gret">
                {{ $t('message.dnsConfig.recordType') }}
              </th>
              <th class="px-5 py-3 tracking-wider text-left text-white border-2 border-gret">
                {{ $t('message.dnsConfig.name') }}
              </th>
              <th class="px-5 py-3 tracking-wider text-left text-white border-2 border-gret">
                {{ $t('message.dnsConfig.value') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="px-5 py-3 tracking-wider text-left text-white border-2 border-gret">
                CNAME
              </td>
              <td class="px-5 py-3 tracking-wider text-left text-white border-2 border-gret">
                {{ dnsConfig.domain }}
              </td>
              <td class="px-5 py-3 tracking-wider text-left text-white border-2 border-gret">
                {{ dnsConfig.dns_target }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex flex-col space-y-4">
      <div class="flex flex-row text-lg text-white">
        {{ $t('message.dnsConfig.dnsProviderInstructions') }}
      </div>
      <div class="flex flex-row space-x-4">
        <hub-link-front
          text-name="Namecheap"
          :href-link="getDnsProviderInstrucctions('namecheap')"
        />
        <hub-link-front
          text-name="Cloudflare"
          :href-link="getDnsProviderInstrucctions('cloudflare')"
        />
        <hub-link-front
          text-name="GoDaddy"
          :href-link="getDnsProviderInstrucctions('goDaddy')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import HubLinkFront from '../components/hub_link_front.vue';

export default {
  name: 'DnsConfigShowWithInstructions',
  components: { HubLinkFront },
  props: {
    communityId: {
      type: String,
      default: null,
    },
    dnsConfig: {
      type: Object,
      required: true,
    },
    justCreated: {
      type: Boolean,
      defualt: false,
    },
  },
  methods: {
    getDnsProviderInstrucctions(provider) {
      const instructions = {
        'namecheap':
          // eslint-disable-next-line vue/max-len
          'https://www.namecheap.com/support/knowledgebase/article.aspx/9776/2237/how-to-create-a-subdomain-for-my-domain/',
        'cloudflare':
          // eslint-disable-next-line vue/max-len
          'https://atenea.marfeel.com/atn/marfeel-press/systems-requirements/marfeel-s-content-delivery-network/create-a-cname-in-cloudflare',
        'goDaddy': 'https://cl.godaddy.com/help/agregar-un-registro-cname-19236',
      };

      return instructions[provider];
    },
  },
};
</script>
