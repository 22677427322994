<template>
  <div>
    <div class="grid grid-cols-12 gap-3 justify-between">
      <div class="col-span-12 sm:col-span-4 md:col-span-3 lg:col-span-3 relative flex items-center h-full">
        <manage-feed-filter-dropdown v-model="activeType" :show-events="false" />
      </div>
      <div
        class="col-span-12 sm:col-span-8 md:col-span-9 lg:col-span-4 relative top-0 right-0 flex items-center h-12 focus:outline-none">
        <!-- <form :action="`/communities/${communityName}/manage/content`" method="get" accept-charset="UTF-8" class="w-full">
          <search-bar placeholder="Buscar" :content="true" />
        </form> -->
        <div class="flex flex-row items-center w-full">
          <button
            class="absolute pl-1 bg-transparent focus:outline-none focus-visible:ring focus-visible:ring-gray-200 focus-visible:ring-offset-4 focus-visible:ring-offset-gray-400">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" v-svg-inline="" v-bind:viewbox="'0 0 24 24'" v-bind:class="'w-8 h-8 text-gray-600 fill-current hover:text-gray-700 focus:outline-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
          </button>
          <input name="search" id="search_input"
            class="block w-full h-12 pl-10 rounded-lg outline-none bg-gret-inputBlack text-white border border-gray-500"
            :placeholder="placeholder" v-model="searchTerm">
        </div>
      </div>
      <div class="col-span-12 sm:col-span-4 md:col-span-3 lg:col-span-2 relative flex items-center h-full">
        <div v-if="showNewSerieButton"
          class="bg-gret-smallHub shadow-md shadow-gray-500 cursor-pointer flex justify-center items-center rounded-lg hover:shadow-white h-12 w-full text-white text-base font-bold px-8 py-3.5"
          @click="buttonSelectNewSerieType">
          <div class="flex items-center justify-center uppercase">
            <div>{{ $t('message.series.newTyped', { fileType: $t(`message.files.${activeType}.type`)}) }}</div>
            <gret-svg
              class="h-6 ml-1"
              icon="series"
            />
          </div>
        </div>
        <manage-feed-serie-dropdown v-if="showNewSerieDropdown" @change="dropdownSelectNewSerieType" />
      </div>
      <slot name="menu" />
    </div>
    <div ref="itemsContainer">
      <div>
        <div
          class="flex items-center justify-start w-full px-4 py-2 mt-8 text-white cursor-pointer md:w-1/4 bg-gret bg-opacity-10"
          :class="showSeries ? 'rounded-t-md' : 'rounded-md'" @click="toggleShowSeries">
          <div class="flex w-full md:justify-between ">
            <div> {{ $tc('message.series.series', activeTypeSeriesCount, { count: activeTypeSeriesCount }) }} </div>
            <img :src="require(`../../assets/images/${keyboardArrow}.svg`)" class="h-6 ml-1">
          </div>
        </div>
        <!--<community-info-banner
          v-if="inaccessibleFiles.length > 0"
          class="mt-8"
          :main-text="$tc('message.communities.inaccessibleContentWarning.list.main', inaccessibleFiles.length)"
          :secondary-text="$tc('message.communities.inaccessibleContentWarning.list.secondary')"
          is-error
        />-->
        <div v-if="showSeries" class="overflow-y-auto rounded bg-gret bg-opacity-10 max-h-1/2-screen">
          <managership-file v-for="(serie, id) in activeTypeSeries" :key="`serie-${id}`" :file="serie"
            icon="library_add" :community-id="communityId" @reload="reload" serie actions />
        </div>
        <div class="grid grid-cols-12 gap-5">
          <managership-file-new v-for="(file, id) in activeTypeFiles" :key="`file-${id}`" :file="file"
            :icon="fileIcons[file.type].type" :community-id="communityId" @reload="reload" actions />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import fileIcons from '../../assets/json/file_icons.json';
import FeedTabs from './feed-tabs';
import GretSvg from './gret-svg.vue';
import ManagershipFile from './managership-file.vue';
import ManagershipFileNew from './managership-file-new.vue';
import ManageFeedSerieDropdown from './manage-feed-serie-dropdown.vue';
import ManageFeedFilterDropdown from './manage-feed-filter-dropdown.vue';

export default {
  name: 'ManageFeed',
  components: { 
    GretSvg,
    ManagershipFile,
    ManagershipFileNew,
    ManageFeedSerieDropdown,
    ManageFeedFilterDropdown,

  },
  props: {
    communityId: {
      type: Number,
      required: true,
    },
    videos: {
      type: Array,
      default: () => [],
    },
    audios: {
      type: Array,
      default: () => [],
    },
    texts: {
      type: Array,
      default: () => [],
    },
    services: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    path: {
      type: String,
      required: true,
    },
    newSeriePath: {
      type: String,
      required: true,
    },
    communityName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      activeType: null,
      serieType: null,
      showSeries: false,
      searchTerm: '',
      placeholder: 'Buscar',
      files: {
        video: this.videos.map(file => ({ ...file, type: 'video' })),
        audio: this.audios.map(file => ({ ...file, type: 'audio' })),
        text: this.texts.map(file => ({ ...file, type: 'text' })),
        service: this.services.map(file => ({ ...file, type: 'service' })),
        product: this.products.map(file => ({ ...file, type: 'product' })),
      },
      fileIcons,
    };
  },
  methods: {
    toggleShowSeries() {
      this.showSeries = !this.showSeries;
    },
    reload() {
      window.location.href = this.path;
    },
    redirectToNewSerie(fileType) {
      window.location.href = `${this.newSeriePath}?file_type=${fileType}`;
    },
    buttonSelectNewSerieType() {
      this.redirectToNewSerie(this.activeType);
    },
    dropdownSelectNewSerieType(serieType) {
      this.redirectToNewSerie(serieType);
    },
  },
  computed: {
    showNewSerieButton() {
      return !!this.activeType && this.files[this.activeType].length > 1;
    },
    showNewSerieDropdown() {
      return !this.activeType;
    },
    allFiles() {
      return [
        ... this.files.video,
        ... this.files.audio,
        ... this.files.text,
        ... this.files.service,
        ... this.files.product,
      ].sort((a, b) => moment(b.createdAt).diff(a.createdAt));
    },
    activeTypeFiles() {
      if (this.activeType) return this.filteredItemsAndType;
      return this.filteredItems;
    },

    filteredItemsAndType() {
      return this.files[this.activeType].filter(item => {
        return item.title.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },

    filteredItems() {
      return this.allFiles.filter(item => {
        return item.title.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },

    inaccessibleFiles() {
      return this.activeTypeFiles.filter(file => !file.visibleByConsumers);
    },
    activeTypeSeries() {
      if (this.activeType) return this.series.filter((serie) => (serie.fileType === `${this.activeType}_file`));
      return this.series;
    },
    activeTypeSeriesCount() {
      return this.activeTypeSeries.length;
    },
    keyboardArrow() {
      return this.showSeries ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
  },
  watch: {
    activeType(newActiveType) {
      if (!newActiveType) {
        this.showSeries = false;
      }
    },
    serieType(newSerieType) {
      if (!!newSerieType) {
        this.activeType = newSerieType;
      }
    },
  },
};
</script>
