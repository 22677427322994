<template>
  <div class="w-full">
    <a
      @click="confirmDelete"
    >
      <button class="flex items-center justify-center rounded-md w-full h-12 text-white bg-gret hover:bg-gret text-base font-bold px-7 py-3.5">
        <div class="flex items-center justify-center uppercase">
          <div>Nuevo ítem</div>
          <gret-svg class="ml-1 text-white" icon="plus" />
        </div>
      </button>
    </a>

    <add-item-modal
      v-if="showModal"
      :audio-url="audioUrl"
      :video-url="videoUrl"
      :document-url="documentUrl"
      :service-url="serviceUrl"
      :product-url="productUrl"
      @close="hideModal"
      @confirmed="deleteFile"
    />
  </div>  
</template>
  
<script>
  import moment from 'moment';
  import Modal from './modal.vue';
  import GretSvg from './gret-svg.vue';
  import AddItemModal from '../components/add-item-modal.vue';
  
  export default {
    name: 'AddItem',
    components: {
      Modal,
      GretSvg,
      AddItemModal
    },
    props: {
      audioUrl: {
        type: String,
        required: true,
      },
      videoUrl: {
        type: String,
        required: true,
      },
      documentUrl: {
        type: String,
        required: true,
      },
      serviceUrl: {
        type: String,
        required: true,
      },
      productUrl: {
        type: String,
        required: true,
      },
      videos: {
      type: Array,
      default: () => [],
      },
      audios: {
        type: Array,
        default: () => [],
      },
      texts: {
        type: Array,
        default: () => [],
      },
      services: {
        type: Array,
        default: () => [],
      },
      products: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        open: true,
        loading: false,
        showModal: false,
        activeType: null,
        files: {
          video: this.videos.map(file => ({ ...file, type: 'video' })),
          audio: this.audios.map(file => ({ ...file, type: 'audio' })),
          text: this.texts.map(file => ({ ...file, type: 'text' })),
          service: this.services.map(file => ({ ...file, type: 'service' })),
          product: this.products.map(file => ({ ...file, type: 'product' })),
        },
      };
    },
    methods: {
      onClose() {
        this.open = false;
        this.$emit('close');
      },
      confirm() {
        this.loading = true;
        this.$emit('confirmed');
      },
      confirmDelete() {
      this.showModal = true;
      console.log("filessss", this.files, this.allFiles)
      },
      hideModal() {
        this.showModal = false;
      },
      async deleteFile() {
        //await filesApi.deleteFile(this.communityId, this.file.type, this.file.id);
        //this.$emit('reload');
      },
    },
    computed: {
      allFiles() {
        return [
          ... this.files.video,
          ... this.files.audio,
          ... this.files.text,
          ... this.files.service,
          ... this.files.product,
        ].sort((a, b) => moment(b.createdAt).diff(a.createdAt));
      },
      activeTypeFiles() {
        if (this.activeType) return this.files[this.activeType];
        return this.allFiles;
      },
      nonItems() {
        if(this.allFiles.length === 0){
          this.showModal = true;
        } else {
          console.log("algun item")
        }
      }
    },
    watch: {
      nonItems() {
        if(this.allFiles.length === 0){
          console.log("0 items w");
          confirmDelete();
        } else {
          console.log("algun item w")
        }
      }
    }
  };
</script>
  