<template>
  <div>
    <div class="max-w-lg mx-auto">
      <div class="mb-6">
        <label class="mb-1 gret-dark-input-label">{{ $t('message.dnsConfig.domainLabel') }} </label>
        <input
          v-model="domain"
          required
          class=" gret-dark-input border-gret-gray bg-gret-gray"
          :class="{'hover:shadow-white': creating }"
          type="text"
          placeholder="example.com"
          :disabled="!creating"
        >
      </div>
      <div class="mb-6">
        <label class="mb-1 gret-dark-input-label">
          {{ $t('message.dnsConfig.subdomainLabel') }}
        </label>
        <input
          v-model="subdomain"
          required
          class="gret-dark-input border-gret-gray bg-gret-gray"
          :class="{'hover:shadow-white': creating }"
          type="text"
          placeholder="cursos"
          :disabled="!creating"
        >
        <input
          v-if="creating"
          type="hidden"
          name="dns_config[domain]"
          :value="fullDomain"
        >
      </div>
      <div class="mb-2">
        <p class="text-white">
          Este será el link por el cual podrás acceder a tu custom domain:
        </p>
        <p
          v-if="fullDomain!='.'"
          class="text-gret"
        >
          {{ fullDomain }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DnsConfigCreate',
  data() {
    return {
      domain: this.creating ? '' : this.getDomain(),
      subdomain: this.creating ? '' : this.getSubdomain(),
    };
  },
  props: {
    creating: {
      type: Boolean,
      default: false,
    },
    dnsConfig: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    fullDomain() {
      return `${this.subdomain}.${this.domain}`;
    },
  },
  methods: {
    getDomain() {
      const domain = this.dnsConfig.domain;

      return domain.substr(domain.indexOf('.') + 1, domain.length);
    },
    getSubdomain() {
      return this.dnsConfig.domain.split('.')[0];
    },
  },
};
</script>
