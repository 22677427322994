<template>
  <div class="border rounded-md">
    <input
      v-if="!!currentAttributeName"
      :name="currentAttributeName"
      :value="value"
      type="hidden"
    >
    <div
      v-for="(option, index) in options"
      :key="index"
    >
      <button
        type="button"
        :disabled="option.disabled"
        class="block w-full p-6 text-left text-white border-b top last:border-b-0 disabled:cursor-not-allowed disabled:opacity-50"
        :class="{ 'bg-gret bg-opacity-10': value === option.value }"
        @click="emitIfEnabled(option)"
      >
        <div class="flex font-bold">
          {{ option.label }}
        </div>
        <div
          v-if="option.description"
          class="text-sm"
          :class="value === option.value ? 'text-gret': 'text-gret-text-gray'"
        >
          {{ option.description }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attributeName: { type: String, default: null },
    options: { type: Array, required: true },
    value: { type: [Boolean, String], required: true },
  },
  methods: {
    emitIfEnabled(option) {
      if (option.disabled) return;
      this.$emit('input', option.value);
    },
  },
  computed: {
    selectedOption() {
      return this.options.find(option => option.value === this.value);
    },
    currentAttributeName() {
      return this.selectedOption.attributeName || this.attributeName;
    },
  },
};
</script>

<style lang="scss">
  .tooltip-arrows {
    @apply border-gray-300;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    z-index: 1;
    border-width: 5px 5px 0;
    bottom: -5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
</style>