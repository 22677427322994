import { decamelizeKeys } from 'humps';
import api from './index';

export default {
  create(params) {
    return api({
      method: 'POST',
      url: '/api/v1/payouts',
      data: params,
    });
  },
  new(params) {
    return api({
      method: 'GET',
      url: '/api/v1/payouts/new',
      params: decamelizeKeys(params),
    });
  },
};
