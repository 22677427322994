<template>
  <div class="flex flex-col p-4 space-y-2 rounded-lg bg-gret-gray">
    <div class="flex flex-row justify-between">
      <div class="font-medium md:text-2xl text-gret">
        {{ $t('message.bankAccountInformation.title') }}
      </div>
      <button
        class="gret-button md:small xsmall"
        @click="toggleModal"
      >
        {{ addBankAccountInformationMessage }}
      </button>
    </div>

    <div class="p-4 rounded-lg bg-gret-gray-dark md:h-full">
      <p
        v-if="!bankAccountInformation"
        class="col-span-1 px-6 py-1 mx-1 text-sm text-center border rounded-md bg-opacity-20 justify-self-center text-blueGray-200 bg-gret border-gret"
      >
        {{ $t('message.bankAccountInformation.noInformation') }}
      </p>
      <p
        v-else
        v-for="information in informationWithLabel"
        :key="information"
        class="text-xs text-white md:text-sm"
      >
        {{ information }}
      </p>
    </div>

    <AddBankAccountInformationModal
      @reload="reload"
      v-if="showModal"
      @close="toggleModal"
      :bank-account-information="bankAccountInformation"
    />
  </div>
</template>

<script>
import AddBankAccountInformationModal from './add-bank-account-information-modal.vue';

export default {
  components: { AddBankAccountInformationModal },
  props: {
    bankAccountInformation: { type: Object, default: null },
    country: { type: Object, default: null },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    informationWithLabel() {
      const labeledInformation = this.bankAccountInformation.pretty_information.map((info) => {
        const [label, value] = Object.entries(info)[0];

        return `${label}: ${value}`;
      });
      if (this.country) {
        labeledInformation.unshift(`País: ${this.country.name} `);
      }

      return labeledInformation;
    },
    addBankAccountInformationMessage() {
      if (this.bankAccountInformation) {
        return this.$t('message.bankAccountInformation.updateInformation');
      }

      return this.$t('message.bankAccountInformation.addInformation');
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    close() {
      this.toggleModal();
    },
    reload() {
      window.location.reload();
    },
  },
};
</script>
