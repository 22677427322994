<template>
    <modal
      :open="open"
      @close="onClose"
    >
      <div class="p-5 text-center bg-gret-blueBlack rounded-lg">
        <div class="text-2xl text-white font-semibold leading-7 tracking-wider">
          ¡Ohhhh!
        </div>
        <div class="text-base text-white font-semibold leading-5 tracking-wider p-5">
          Hubo un problema con el pago.
        </div>
        <div class="flex justify-center text-2xl text-center text-red-600 font-semibold tracking-wider mb-7">
          <gret-svg icon="error" class="mt-7" />
        </div>
        <div class="text-base text-white font-semibold leading-5 tracking-wider p-5">
          Intenta publicar el combo y realizar el pago nuevamente.
        </div>
        <hr />
        <div class="text-sm text-white font-normal leading-7 tracking-wider p-5">
          Si quieres puedes comunicarte, escríbenos a juan@gret.io
        </div>
      </div>
    </modal>
  </template>
    
  <script>
    import Modal from './modal.vue';
    import GretSvg from './gret-svg.vue';
    
    export default {
      name: 'ModalNewUserSuccess',
      components: {
        Modal,
        GretSvg
      },
      data() {
        return {
          open: true,
          loading: false,
        };
      },
      methods: {
        onClose() {
          this.open = false;
          this.$emit('close');
        },
      },
    };
  </script>
  
  <style scoped>
    .add-button-item {
      @apply bg-gret-smallHub p-5 rounded-lg shadow-md items-center text-white font-semibold text-lg flex flex-col hover:bg-gret;
    }
  
    .icon-button-svg {
      @apply bg-gret-blueBlack rounded-full p-3
    }
  
    .icon-button-svg-hover {
      @apply bg-blue-700 rounded-full p-3
    }
  </style>
      