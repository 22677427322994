<template>
  <intersect @enter.once="setSrcs">
    <div class="">
      <blurhash-canvas
        v-if="blurhash"
        :blurhash="blurhash"
        class="absolute top-0 left-0 object-cover transition-opacity duration-500"
        :class="rounded ? 'rounded-t-lg' : ''"
      />
      <div
        v-else
        class="absolute top-0 left-0 object-cover transition-opacity duration-500"
      />
      <picture>
        <source
          ref="webpSource"
          type="image/webp"
        >
        <source
          ref="jpegSource"
          type="image/jpeg"
        >
          <img
            ref="image"
            class="object-cover w-full h-full transition-opacity duration-500"
            :class="rounded ? 'rounded-t-lg' : ''"
            @load="isLoaded = true"
            v-bind="$attrs"
          >
      </picture>
    </div>
  </intersect>
</template>

<script>
import Intersect from 'vue-intersect';
import BlurhashCanvas from './blurhash-canvas.vue';

export default {
  components: {
    Intersect,
    BlurhashCanvas,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    webpSrc: {
      type: String,
      default: null,
    },
    blurhash: {
      type: String,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  methods: {
    setSrcs() {
      if (this.webpSrc) {
        this.$refs.webpSource.srcset = this.webpSrc;
      }
      if(this.src){
        this.$refs.jpegSource.srcset = this.src;
        this.$refs.image.src = this.src;
      } else{
        this.$refs.jpegSource.srcset = '/assets/default-img-2fcf13bf878caf4b04b7021ca20680f79eb449d4e98d59a7af6ee81b32071218.svg';
        this.$refs.image.src = '/assets/default-img-2fcf13bf878caf4b04b7021ca20680f79eb449d4e98d59a7af6ee81b32071218.svg';
      }
    },
  },
};
</script>

<style scoped>
  .gradientImg {
    background: linear-gradient(180deg, rgba(31, 44, 59, 0.6) 1.1%, rgba(31, 44, 59, 0) 36.25%, rgba(31, 44, 59, 0) 81.36%, rgba(31, 44, 59, 0.6) 112.16%);
    background-blend-mode: multiply, normal;
  }
</style>
