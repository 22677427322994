<template>
  <img
    v-svg-inline
    v-bind="$attrs"
    class="fill-current"
    :src="require(`../../assets/images/${icon}.svg`)"
  >
</template>
<script>

export default {
  name: 'GretSvg',
  props: {
    icon: { type: String, required: true },
  },
};
</script>
