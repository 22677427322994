import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

function api(options) {
  return axios({
    ...options,
    data: decamelizeKeys(options.data),
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },

  }).then(response => Object.assign(camelizeKeys(response.data), { headers: response.headers }));
}

export default api;
