<template>
  <div :class="type ? 'pt-24' : 'pt-0 sm:pt-10'">
    <div
      class="px-10 xl:w-1/2"
    >
      <slot name="alert" />
    </div>
    <!--<content-gallery-carrousel
      v-if="!type"
      :videos="videos"
      :audios="audios"
      :texts="texts"
      :services="services"
      :products="products"
      :series="series"
    />-->
    <combo-cover
      :community-name="communityName"
      :community-description="communityDescription"
      :community-image="communityImage"
      :community-creator="communityCreator"
      :community-privacy="communityPrivacy"
      :community-price="communityPrice"
      :own="own"
    />
    <content-gallery-section
      :sections="sections"
    />
  </div>
</template>

<script>
import ContentGalleryCarrousel from './content-gallery-carrousel.vue';
import ContentGallerySection from './content-gallery-section.vue';
import ComboCover from './combo-cover.vue';

export default {
  name: 'ContentGallery',
  components: {
    ContentGalleryCarrousel,
    ContentGallerySection,
    ComboCover
  },
  data() {
    return {
      titles: ['video', 'audio', 'text', 'service', 'product'].reduce((titles, fileType) => ({ ...titles, [fileType]: {
        home: this.$t(`message.files.${fileType}.sections.home`),
        recent: this.$t(`message.files.${fileType}.sections.recent`),
        files: this.$t(`message.files.${fileType}.sections.files`),
      } }), {}),
      files: {
        audio: this.audios,
        text: this.texts,
        video: this.videos,
        service: this.services,
        product: this.products,
      },
      ungroupedFiles: {
        audio: this.audios.filter((file) => (!file.grouped)),
        text: this.texts.filter((file) => (!file.grouped)),
        video: this.videos.filter((file) => (!file.grouped)),
        service: this.services.filter((file) => (!file.grouped)),
        product: this.products.filter((file) => (!file.grouped)),
      },
      recentLimit: 6,
    };
  },
  props: {
    home: {
      type: Boolean,
      default: false,
    },
    audios: {
      type: Array,
      default: () => [],
    },
    texts: {
      type: Array,
      default: () => [],
    },
    videos: {
      type: Array,
      default: () => [],
    },
    services: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: null,
    },
    communityName: {
      type: String,
      default: null,
    },
    communityDescription: {
      type: String,
      default: null,
    },
    communityImage: {
      type: String,
      default: null,
    },
    communityCreator: {
      type: String,
      default: null,
    },
    communityPrivacy: {
      type: String,
      default: null,
    },
    communityPrice: {
      type: String,
      default: null,
    },
    own: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sections() {
      return this.type ? this.typedSections() : this.homeSections();
    },
    allHomeSections() {
      return (
        [{ files: this.ungroupedVideos,
          series: this.videoSeries,
          scrollable: true,
          type: 'video',
          title: this.titles.video.home,
          topLine: false },
        { files: this.ungroupedAudios,
          series: this.audioSeries,
          scrollable: true,
          type: 'audio',
          title: this.titles.audio.home,
          topLine: false },
        { files: this.ungroupedTexts,
          series: this.textSeries,
          scrollable: true,
          type: 'text',
          title: this.titles.text.home,
          topLine: false },
        { files: this.ungroupedServices,
          series: this.serviceSeries,
          scrollable: true,
          type: 'service',
          title: this.titles.service.home,
          topLine: false },
        { files: this.ungroupedProducts,
          series: this.productSeries,
          scrollable: true,
          type: 'product',
          title: this.titles.product.home,
          topLine: false },
        ]
      );
    },
    audioSeries() {
      return this.series.filter((serie) => (serie.fileType === 'audio'));
    },
    textSeries() {
      return this.series.filter((serie) => (serie.fileType === 'text'));
    },
    videoSeries() {
      return this.series.filter((serie) => (serie.fileType === 'video'));
    },
    serviceSeries() {
      return this.series.filter((serie) => (serie.fileType === 'service'));
    },
    productSeries() {
      return this.series.filter((serie) => (serie.fileType === 'product'));
    },
    ungroupedAudios() {
      return this.audios.filter((file) => (!file.grouped));
    },
    ungroupedTexts() {
      return this.texts.filter((file) => (!file.grouped));
    },
    ungroupedServices() {
      return this.services.filter((file) => (!file.grouped));
    },
    ungroupedProducts() {
      return this.products.filter((file) => (!file.grouped));
    },
    ungroupedVideos() {
      return this.videos.filter((file) => (!file.grouped));
    },
  },
  methods: {
    typedSections() {
      return (
        [{ files: this.ungroupedFiles[this.type].slice(0, this.recentLimit),
          series: this.series.slice(0, this.recentLimit),
          scrollable: true,
          type: this.type,
          title: this.titles[this.type].recent,
          topLine: false },
        { files: this.ungroupedFiles[this.type],
          series: this.series,
          scrollable: false,
          type: this.type,
          title: this.titles[this.type].files,
          topLine: true,
        },
        ]
      );
    },
    homeSections() {
      return this.allHomeSections.filter((section) => section.files.length > 0 || section.series.length > 0);
    },
  },
};
</script>

<style scoped>
.sidebar {
  min-width: 250px;
}
</style>
