import Vue from 'vue';

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

const DEBOUNCE_TIME = 200;

function xs(val) {
  return val < screens.sm;
}

function sm(val) {
  return val >= screens.sm && val <= screens.md;
}
function md(val) {
  return val >= screens.md && val <= screens.lg;
}
function lg(val) {
  return val >= screens.lg && val <= screens.xl;
}
function xl(val) {
  return val >= screens.xl;
}

function getBreakpoint(width) {
  if (xs(width)) return 'xs';
  else if (sm(width)) return 'sm';
  else if (md(width)) return 'md';
  else if (lg(width)) return 'lg';
  else if (xl(width)) return 'xl';

  return 'all';
}
function debounce(func, wait) {
  let timeout;

  return () => {
    function later() {
      timeout = null;
    }
    const callNow = !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func();
  };
}

const breakpoints = Vue.observable({
  w: window.innerWidth,
  h: window.innerHeight,
  is: getBreakpoint(window.innerWidth),
});

window.addEventListener(
  'resize',
  debounce(() => {
    breakpoints.w = window.innerWidth;
    breakpoints.h = window.innerHeight;
    breakpoints.is = getBreakpoint(window.innerWidth);
  }, DEBOUNCE_TIME),
  false,
);

export default breakpoints;
