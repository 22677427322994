<template>
  <div
    class="grid grid-cols-2 p-2 space-y-1 text-white rounded-md md:space-y-0 justify-items-center bg-gret-gray-dark"
  >
    <div class="flex items-center space-x-1 justify-self-start">
      <p class="text-lg font-bold ">
        {{ $t('message.balanceDisclaimer.title') }}
      </p>
      <button @click="toggleModal">
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'w-4 h-4 fill-current'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.5 3a5 5 0 100 10 5 5 0 000-10zM1.25 8a6.25 6.25 0 1112.5 0 6.25 6.25 0 01-12.5 0z" fill="#fff"/><path d="M7.5 6.75a.625.625 0 01.625.625v3.75a.625.625 0 11-1.25 0v-3.75A.625.625 0 017.5 6.75zm.938-1.563a.937.937 0 11-1.875 0 .937.937 0 011.875 0z" fill="#fff"/></svg>
      </button>
    </div>
    <div class="flex flex-col items-end w-full md:items-center md:justify-between md:flex-row">
      <p class="text-lg font-bold md:text-2xl md:justify-self-start ">
        {{ balance | currencyWithCode('USD') }}
      </p>
      <p
        v-if="countryId && amount"
        class="text-sm text-white md:text-base"
      >
        {{ amount | currencyWithCode(currency) }}
      </p>
    </div>
    <BalanceDisclaimerModal
      v-if="showModal"
      @close="toggleModal"
    />
  </div>
</template>

<script>
import exchangesApi from '../api/exchanges';
import BalanceDisclaimerModal from '../components/balance-disclaimer-modal';

export default {
  components: { BalanceDisclaimerModal },
  props: {
    countryId: { type: Number, default: null },
    balance: { type: Number, required: true },
  },
  data() {
    return {
      amount: null,
      currency: null,
      showModal: false,
      loading: false,
    };
  },
  methods: {
    async getAmountInNewCurrency() {
      this.loading = true;
      const { amount, currency } = await exchangesApi.getExchange(this.countryId, this.balance, 'payout');
      this.amount = amount;
      this.currency = currency;
      this.loading = false;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
  mounted() {
    if (this.countryId) {
      this.getAmountInNewCurrency();
    }
  },
};
</script>
