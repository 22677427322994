<template>
  <a
    class="w-full gret-button sm:w-auto"
    :class="{ outlined: isSubscribe }"
    :href="!isSubscribe ? subscribeUrl : null"
  >
    <span>{{ isSubscribe ? 'Estás suscrito' : 'Suscríbete' }}</span>
    <span
      v-if="isMember"
      class="ml-2"
    >
      <img
        inline-svg
        class="w-8 h-8 text-white fill-current"
        src="../../assets/images/check.svg"
      >
    </span>
  </a>
</template>

<script>
export default {
  props: {
    isSubscribe: {
      type: Boolean,
      required: true,
    },
    communityId: {
      type: Number,
      required: true,
    },
    subscribeUrl: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>

</style>
