<template>
  <file-events-download-base
    :community-id="communityId"
    :file-type="fileType"
    :file-id="fileId"
    :event-type="eventType"
  >
    <div class="flex flex-col">
      <div class="mb-2 text-4xl font-medium">
        {{ this.eventCount }}
      </div>
      <div class="text-sm">
        {{ this.eventText }}
      </div>
    </div>
  </file-events-download-base>
</template>

<script>
import FileEventsDownloadBase from './file-events-download-base';

export default {
  name: 'FileEventsDownloadTextBtn',
  components: {
    FileEventsDownloadBase,
  },
  props: {
    communityId: {
      type: Number,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
    fileId: {
      type: Number,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
    eventCount: {
      type: Number,
      required: true,
    },
    eventText: {
      type: String,
      required: true,
    },
  },
};
</script>
