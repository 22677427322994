<template>
  <div class="relative my-4">
    <div class="mb-6 text-lg font-medium text-white md:text-xl">
      {{ $t('message.series.accessConfiguration.title') }}
    </div>
    <single-select-buttons-series
      attribute-name="accessibilityControlCategory"
      :options="accessibilityControlCategoryOptions"
      v-model="accessibilityControlCategory"
      :class="{ 'mb-6': !accessibilityControlCategory }"
    />
    <input
      type="hidden"
      :value="accessibilityControl"
      name="serie[accessibility_control]"
    >
    <content-form-privacy-and-payment-section-series
      v-if="accessibilityControl"
      entity-name="serie"
      :require-payment-options="requirePaymentOptions"
      :privacy-options="privacyOptions"
      :payment-type-options="paymentTypeOptions"
      :initial-whatsapp-msg="initialWhatsappMsg"
      :initial-phone-number="initialPhoneNumber"
      :initial-single-payment-price="initialSinglePaymentPrice"
      :enable-phone-number-change="enablePhoneNumberChange"
      :visible-by-consumers="visibleByConsumers"
      hide-title
    />
  </div>
</template>
<script>
import ContentFormPrivacyAndPaymentSectionSeries from './content-form-privacy-and-payment-section-series.vue';
import SingleSelectButtonsSeries from './single-select-buttons-series.vue';

const showPrivacyAndPayment = process.env.SHOW_GROUP_CONFIGURATION_OPTIONS === 'true';
export default {
  name: 'SerieFormAccessConfiguration',
  components: {
    ContentFormPrivacyAndPaymentSectionSeries,
    SingleSelectButtonsSeries,
  },
  props: {
    requirePaymentOptions: { type: Array, required: true },
    privacyOptions: { type: Array, required: true },
    paymentTypeOptions: { type: Array, required: true },
    initialWhatsappMsg: { type: String, default: '' },
    initialPhoneNumber: { type: String, default: '' },
    initialSinglePaymentPrice: { type: Number, default: null },
    enablePhoneNumberChange: { type: Boolean, default: false },
    editCommunityPath: { type: String, default: '' },
    visibleByConsumers: { type: Boolean, required: true },
    initialAccessibilityControl: { type: Boolean, default: false },
  },
  data() {
    return ({
      accessibilityControlCategory: 'single',
      accessibilityControlCategoryOptions: [
        { value: 'single',
          label: this.$t('message.series.accessConfiguration.single'),
          description: this.$t('message.series.accessConfiguration.singleDescription'),
        },
        { value: 'group',
          label: this.$t('message.series.accessConfiguration.group'),
          description: this.$t('message.series.accessConfiguration.groupDescription'),
          disabled: false,
        },
      ],
      showPrivacyAndPayment,
    }
    );
  },
  computed: {
    accessibilityControl() {
      return this.accessibilityControlCategory === 'group';
    },
  },
  mounted() {
    this.accessibilityControlCategory = this.initialAccessibilityControl ? 'group' : 'single';
  },
};
</script>
