<template>
  <div
    :class="content ? 'col-span-3 relative flex items-center w-auto h-full sm:w-auto' : 'col-span-3 relative flex items-center w-full h-full'"
  >
    <button
      class="flex items-center justify-center px-4 rounded-md"
      :class="[buttonClass || dark && 'bg-gret-gray-dark',
               !dark && 'bg-gray-200 hover:bg-gray-300',
               !dark && isOpen && 'bg-gray-300']"
      @click="open"
    >
      <slot name="button" />
    </button>
    <div
      class="absolute right-0 z-20 p-1 mt-1 border rounded-md shadow-lg top-full"
      :class="[{ hidden: !isOpen }, dark ? 'bg-gret-black border-gret-gray-dark' : 'bg-white border-gray-300']"
    >
      <slot name="menu" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    buttonClass: {
      type: String,
      default: '',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      if (!this.isOpen) {
        this.isOpen = true;
        setTimeout(() => {
          document.addEventListener('click', () => {
            this.$nextTick(() => {
              this.isOpen = false;
            });
          }, { once: true });
        }, 0);
      }
    },
  },
};
</script>
