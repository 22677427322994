<template>
  <div>
    <div class="border rounded-lg shadow-xl hover:shadow-white border-gret-gray bg-gret-gray-light gret-file-uploader">
      <input
        ref="hiddenInput"
        type="hidden"
        :value="hiddenValue"
        :name="name"
      >
      <div ref="statusBar" />
      <template v-if="metadata">
        <div class="p-6 gret-file-uploader__preview">
          <div v-if="type === 'image' && dataUrl">
            <img
              :src="dataUrl"
              class="object-contain object-left h-48 gret-file-uploader__preview-image"
            >
          </div>
          <div v-else>
            <div class="text-sm font-medium text-white gret-file-uploader__preview-filename">
              {{ metadata.filename }}
            </div>
            <div class="text-sm text-gret-text-gray gret-file-uploader__preview-filesize">
              {{ formatFileSize(metadata.size) }}
            </div>
          </div>
          <div class="relative inline-block mt-4 overflow-hidden gret-file-uploader__replace">
            <label class="text-gret hover:underline file gret-file-uploader__replace-label">
              <span>Reemplazar archivo</span>
              <input
                type="file"
                @change="onFileSelect"
                class="hidden gret-file-uploader__replace-input"
                :accept="allowedFileTypes.join(',')"
              >
            </label>
          </div>
        </div>
      </template>
      <template v-else>
        <label class="flex flex-col items-center justify-center p-8 text-gray-200 cursor-pointer hover:text-white gret-file-uploader__uploader">
          <img
            inline-svg
            class="w-10 h-10 fill-current text-gret-text-gray"
            src="../../assets/images/backup.svg"
          >
          <div class="text-center text-xs text-gray-400">Haz click para seleccionar un archivo <br/> {{ textLabel }}</div>
          <input
            type="file"
            @change="onFileSelect"
            class="hidden gret-file-uploader__uploader-input"
            :accept="allowedFileTypes.join(',')"
          >
        </label>
      </template>
    </div>
    <div
      v-if="checkbox"
      class="my-6"
    >
      <div class="flex items-center">
        <input
          type="hidden"
          :name="checkbox.name"
          v-model="downloadable"
        >
        <input
          type="checkbox"
          class="w-4 h-4"
          name="checkboxDownloadable"
          :disabled="unpreviewable"
          v-model="checkboxDownloadable"
        >
        <label
          class="ml-2 gret-dark-input-label"
          for="checkboxDownloadable"
        >
          Se permiten descargas
        </label>
      </div>
      <p
        class="mt-1 text-xs text-gret-text-gray"
        :class="!unpreviewable && 'invisible'"
      >
        El tipo de archivo que estás subiendo siempre es descargable
      </p>
    </div>
  </div>
</template>

<script>
import 'uppy/dist/uppy.min.css';
import { Core, StatusBar, AwsS3, XHRUpload } from 'uppy';
import { camelizeKeys } from 'humps';
import Spanish from '@uppy/locales/lib/es_ES';
import fileTypes from '../../assets/json/file_types.json';
import unpreviewableFileTypes from '../../assets/json/unpreviewable_file_types.json';
import formatFileSize from '../utils/format-filesize';

export default {
  name: 'FileUpload',
  data() {
    return {
      uppy: null,
      metadata: null,
      hiddenValue: '',
      environment: process.env.RAILS_ENV,
      downloadable: null,
      checkboxDownloadable: null,
      unpreviewable: false,
      dataUrl: this.url,
    };
  },
  props: {
    name: { type: String, required: true },
    type: { type: String, required: true },
    value: { type: String, default: null },
    url: { type: String, default: null },
    buttonLabel: { type: String, default: null },
    checkbox: { type: Object, default: null },
    textLabel: {type: String, default: ""}
  },
  computed: {
    allowedFileTypes() {
      return fileTypes[this.type];
    },
  },
  mounted() {
    this.mountUppy();
    if (this.value) {
      this.hiddenValue = this.value;
      this.metadata = camelizeKeys(JSON.parse(this.value).metadata);
      this.unpreviewable = this.isFileUnpreviewable();
    }
    if (this.checkbox) {
      this.checkboxDownloadable = this.checkbox.value;
    }
  },
  watch: {
    checkboxDownloadable(newDownloadable) {
      this.downloadable = newDownloadable;
    },
    unpreviewable(newUnpreviewable) {
      if (newUnpreviewable) {
        this.checkboxDownloadable = true;
      }
    },
  },
  methods: {
    mountUppy() {
      this.uppy = new Core({
        autoProceed: true,
        locale: Spanish,
        restrictions: {
          allowedFileTypes: this.allowedFileTypes,
        },
      }).use(StatusBar, { target: this.$refs.statusBar });

      if (this.environment === 'production') {
        this.uppy.use(AwsS3, { companionUrl: '/' });
      } else {
        this.uppy.use(XHRUpload, { endpoint: '/upload' });
      }

      this.uppy.on('upload-success', (file, response) => {
        let uploadedFileData;
        if (this.environment === 'production') {
          uploadedFileData = {
            id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
            storage: 'cache',
            metadata: {
              size: file.size,
              filename: file.name,
              mimeType: file.type,
            },
          };
        } else {
          uploadedFileData = response.body.data;
        }

        // set hidden field value to the uploaded file data so that it's submitted
        // with the form as the attachment
        this.$emit('input', JSON.stringify(uploadedFileData));
        this.hiddenValue = JSON.stringify(uploadedFileData);
        this.metadata = camelizeKeys(uploadedFileData.metadata);
        this.unpreviewable = this.isFileUnpreviewable();
        this.dataUrl = URL.createObjectURL(file.data);
      });
    },
    onFileSelect(event) {
      const files = Array.from(event.target.files);

      files.forEach((file) => {
        try {
          this.uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          });
        } catch (err) {
          if (err.isRestriction) {
            alert('El tipo de archivo que elegiste no está permitido.');
            console.log('Restriction error:', err);
          } else {
            console.error(err);
          }
        }
      });
    },
    isFileUnpreviewable() {
      return unpreviewableFileTypes.includes(this.metadata.mimeType);
    },
    formatFileSize,
  },
};
</script>
