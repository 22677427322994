<template>
  <div
    class="items-center"
    :class="small ? 'flex md:hidden' : 'hidden md:flex'"
  >
    <a
      class="flex p-2 mt-2 mr-2 text-gray-600 bg-gray-200 rounded outline-none cursor-pointer hover:bg-gray-300"
      :href="serie.editPath"
    >
      <gret-svg
        class="w-6 h-6 my-1 text-gray-600"
        icon="create"
      />
    </a>
    <a
      v-if="(serie.authorizations && serie.accessibilityControl)"
      class="flex p-2 mt-2 mr-2 text-gray-600 bg-gray-200 rounded outline-none cursor-pointer hover:bg-gray-300"
      :href="serie.authorizationsPath"
    >
      <gret-svg
        class="w-6 h-6 my-1"
        icon="lock"
      />
    </a>
    <a
      class="flex p-2 mt-2 mr-2 text-red-600 bg-red-200 rounded outline-none cursor-pointer hover:bg-red-300"
      @click="confirmDelete"
    >
      <gret-svg
        class="w-6 h-6 my-1 text-red-600"
        icon="delete"
      />
    </a>
    <delete-confirmation-modal
      v-if="showModal"
      :title="serie.title"
      :message="$t('message.series.deleteConfirmation') "
      @close="hideModal"
      @confirmed="deleteSerie"
    />
  </div>
</template>
<script>
import DeleteConfirmationModal from './delete-confirmation-modal.vue';
import GretSvg from './gret-svg.vue';
import seriesApi from '../api/series';

export default {
  name: 'ManagershipSerieActions',
  components: { DeleteConfirmationModal, GretSvg },
  props: {
    serie: { type: Object, default: () => {} },
    communityId: { type: Number, default: null },
    small: { type: Boolean, default: false },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    confirmDelete() {
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    },
    async deleteSerie() {
      await seriesApi.deleteSerie(this.communityId, this.serie.id);
      this.$emit('reload');
    },
  },
};
</script>
