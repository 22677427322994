<template>
  <div class="md:flex">
    <input
      type="hidden"
      :name="name"
      :value="selected"
    >
    <div
      class="flex items-start flex-1 p-4 mb-2 border border-gray-300 rounded cursor-pointer hover:border-gray-400 md:mb-0 md:mr-2 last:mb-0 last:mr-0"
      :class="{ 'border-gret hover:border-gret': option.value === selected }"
      v-for="option in options"
      :key="option.value"
      @click="select(option.value)"
    >
      <div
        class="flex items-center justify-center w-6 h-6 mr-3 bg-gray-300 rounded-full"
        :class="{ 'bg-gret': option.value === selected }"
      >
        <img
          v-if="option.value === selected"
          inline-svg
          class="w-8 h-8 text-white fill-current"
          src="../../assets/images/check.svg"
        >
      </div>
      <div class="flex-1">
        <div class="font-bold">
          {{ option.label }}
        </div>
        <div
          class="mt-1 text-sm"
          v-if="option.explanation"
        >
          {{ option.explanation }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExplainedPicker',
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    initialSelected: {
      type: [String, Boolean],
      default: null,
    },
  },
  data() {
    return {
      selected: this.initialSelected || this.options[0].value,
    };
  },
  methods: {
    select(value) {
      this.selected = value;
    },
  },
};
</script>

<style>
</style>
