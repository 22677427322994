<template>
  <div
    class="py-5 mx-5 sm:mx-10 sm:py-10 min-h-1/2-screen"
  >
    <!--<div
      class="flex"
      v-if="missingRequiredPayment"
    >
      <community-info-banner
        :action-path="paymentPath"
        action-text="Pagar"
        main-text="Este grupo requiere un pago"
        :secondary-text="infoBannerSecondaryText"
      />
    </div>-->
    <div
      class="flex justify-start"
    >
      <div
        v-for="(option, key) in options"
        :key="`tab-${key}`"
        class="flex items-center mr-2 tracking-widest text-gray-100 uppercase font-semi-bold hover:text-white"
        :class="option.show || 'hidden'"
      >
        <div
          class="text-xs transition-colors duration-500 border-b-4 font-semi-bold sm:text-base lg:mr-2 lg:inline hover:border-white"
          :class="selectedOption == key ? 'border-white' : 'border-gret-gray-dark'"
          @click="selectOption(key)"
        >
          {{ option.name }}
        </div>
      </div>
    </div>
    <div
      class="flex flex-col justify-center lg:justify-start"
    >
      <div v-if="selectedOption == 'episodes'">
        <div
          v-for="(episode, key) in orderedItems(episodes)"
          class="w-full my-5"
          :key="`episode-${key}`"
        >
          <serie-content-episode
            :episode="episode"
            :number="key + 1"
            :serie-accessibility-control="serie.accessibilityControl"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SerieContentEpisode from '../components/serie-content-episode';

export default {
  name: 'SerieContent',
  components: { SerieContentEpisode },
  data() {
    return {
      selectedOption: 'episodes',
      options: {
        episodes: {
          name: this.$t(`message.files.${this.fileType}.sections.episodes`),
          show: !!this.fileType,
        },
      },
    };
  },
  props: {
    serie: {
      type: Object,
      required: true,
    },
    episodes: {
      type: Array,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
    paymentPath: {
      type: String,
      default: null,
    },
    missingRequiredPayment: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    formattedPrice() {
      return this.$options.filters.currencyWithCode(
        this.serie.singlePaymentPriceAmount, this.serie.singlePaymentPriceCurrency,
      );
    },
    infoBannerSecondaryText() {
      return `Para acceder a todos los contenidos de este grupo debes realizar un único pago de ${this.formattedPrice}`;
    },
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
    },
    orderedItems(items) {
      return items.sort((a, b) => ((a.number > b.number) ? 1 : -1));
    },
  },
};
</script>
