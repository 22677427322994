
export default function trixConfig() {
  const Trix = require('trix');
  Trix.config.textAttributes.underline = {
    style: { textDecoration: 'underline' },
    parser: (element) => (element.style.textDecoration === 'underline'),
    inheritable: true,
  };
  addEventListener('trix-initialize', (event) => {
    const style = 'font-bold px-4 text-lg font-bold text-white underline border-l-2 border-b-2 border-gray-300';
    const button = `<button type="button" data-trix-attribute="underline" class="${style}">U</button>`;
    event.target.toolbarElement.querySelector('.trix-button-group').insertAdjacentHTML('beforeend', button);
  });
  Trix.config.lang.urlPlaceholder = 'Ingresa un link (debe incluir el http)';
}
