<template>
  <a
    class="w-full gret-button sm:w-auto"
    :class="{ outlined: isMember }"
    :href="!isMember ? joinUrl : null"
  >
    <span>{{ isMember ? $t('message.communities.member') : $t('message.communities.join') }}</span>
    <span
      v-if="isMember"
      class="ml-2"
    >
      <img
        inline-svg
        class="w-8 h-8 text-white fill-current"
        src="../../assets/images/check.svg"
      >
    </span>
  </a>
</template>

<script>
export default {
  props: {
    isMember: {
      type: Boolean,
      required: true,
    },
    communityId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    joinUrl() {
      return `/communities/${this.communityId}/join`;
    },
  },
};
</script>

<style>

</style>
