<template>
  <div class="w-full h-full">
    <file-cover
      :community-path="communityPath"
      :community="community"
      :access="access"
      :file="serie"
      :file-type="fileType"
      :show-play="false"
      serie
      :subscribers-only="serieSubscribersOnly"
    />
    <serie-content
      :serie="serie"
      :episodes="episodes"
      :file-type="fileType"
      :payment-path="paymentPath"
      :missing-required-payment="missingRequiredPayment"
    />
  </div>
</template>

<script>
import breakpoints from '../utils/breakpoints';
import FileCover from './file-cover';
import SerieContent from './serie-content';

export default {
  name: 'Serie',
  data() {
    return { breakpoints };
  },
  components: { FileCover, SerieContent },
  props: {
    communityPath: {
        type: String,
        default: null,
      },
      community: {
        type: Object,
        default: null,
      },
    serie: {
      type: Object,
      required: true,
    },
    episodes: {
      type: Array,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
    paymentPath: {
      type: String,
      default: null,
    },
    missingRequiredPayment: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    serieSubscribersOnly() {
      return this.serie.accessibilityControl && this.serie.subscribersOnly;
    },
  },
};
</script>
