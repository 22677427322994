import { render, staticRenderFns } from "./managership-file-actions-new.vue?vue&type=template&id=3fa1b268&"
import script from "./managership-file-actions-new.vue?vue&type=script&lang=js&"
export * from "./managership-file-actions-new.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports