<template>
  <button
    class="flex items-center justify-center px-4 py-2 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300"
    :class="{ 'cursor-default opacity-50': downloadId || downloadUrl }"
    @click="generateDownload"
    :disabled="downloadId || downloadUrl"
  >
    <template v-if="downloadUrl">
      <div>
        {{ $t('message.members.downloaded') }}
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" fill="#FFF" v-svg-inline="" v-bind:viewbox="'0 0 24 24'" v-bind:class="'w-12 h-12 ml-1 text-gray-600 fill-current focus:outline-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/></svg>
    </template>
    <template v-else-if="downloadId">
      <div class="mr-4">
        {{ $t('message.members.downloading') }}
      </div>
      <spinner class="w-4 h-4" />
    </template>
    <template v-else>
      <div>
        {{ $t('message.members.download') }}
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" fill="#FFF" v-svg-inline="" v-bind:viewbox="'0 0 24 24'" v-bind:class="'w-12 h-12 ml-2 text-gray-600 fill-current focus:outline-none focus-visible:ring focus-visible:ring-gray-200 focus-visible:ring-offset-4 focus-visible:ring-offset-gray-400'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
    </template>
  </button>
</template>

<script>
import Spinner from './spinner';
import {
  createMembersCsvDownload,
  getCsvDownload,
} from '../api/csv-downloads';

const DOWNLOAD_POLLING_INTERVAL = 5000;
const RESET_TIMEOUT = 3000;

export default {
  name: 'MembersDownloadWizard',
  components: {
    Spinner,
  },
  props: {
    communityId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      downloadId: null,
      downloadUrl: null,
      pollingInterval: null,
    };
  },
  methods: {
    generateDownload() {
      createMembersCsvDownload(this.communityId)
        .then(({ csvDownload }) => {
          const { id } = csvDownload || {};
          if (id) {
            this.downloadId = id;
            this.startPolling();
          }
        });
    },
    getDownloadStatus() {
      if (this.downloadId) {
        getCsvDownload(this.downloadId, this.communityId)
          .then(({ csvDownload }) => {
            const { readyForDownload, url } = csvDownload || {};
            if (readyForDownload && url) {
              window.location.href = url;
              this.downloadUrl = url;
              this.downloadId = null;
              this.stopPolling();
              setTimeout(() => this.restart(), RESET_TIMEOUT);
            }
          });
      }
    },
    startPolling() {
      this.getDownloadStatus();
      this.pollingInterval = setInterval(this.getDownloadStatus, DOWNLOAD_POLLING_INTERVAL);
    },
    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    },
    restart() {
      this.downloadId = null;
      this.downloadUrl = null;
      this.stopPolling();
    },
  },
  beforeDestroy() {
    this.stopPolling();
  },
};
</script>
