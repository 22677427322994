<template>
  <modal
    :open="open"
    @close="onClose"
  >
    <div class="p-8 pt-24 text-center bg-gret-blueBlack rounded-lg">
      <div class="text-3xl font-semibold text-center text-white">
        {{ message }}
      </div>
      <div class="text-3xl font-semibold text-center text-white">
        "{{ title }}"?
      </div>
      <div class="grid grid-cols-2 gap-2 ">
        <a
          class="mt-16 inline-flex items-center justify-center px-6 py-3 font-medium tracking-wide text-white uppercase border-2 rounded-md cursor-pointer border-gret-smallHub bg-gret-smallHub"
          @click="onClose"
        >
          {{ $t('message.shared.cancel') }}
        </a>
        <a
          class="mt-16 gret-button"
          :class="loading && 'opacity-50'"
          @click="confirm"
        >
          {{ $t('message.shared.delete') }}
        </a>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  name: 'DeleteConfirmationModal',
  components: {
    Modal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      open: true,
      loading: false,
    };
  },
  methods: {
    onClose() {
      this.open = false;
      this.$emit('close');
    },
    confirm() {
      this.loading = true;
      this.$emit('confirmed');
    },
  },
};
</script>
