<template>
  <div
    class="justify-center"
    :class="xsVisible ? `h-10 sm:hidden grid grid-cols-${options.length}`: 'hidden sm:flex lg:justify-start'"
  >
    <a
      v-for="(option, key) in options"
      :key="key"
      class="items-center mx-2 text-gray-100 uppercase font-semi-bold hover:text-white"
      :class="twoRowsStyle || 'flex tracking-widest sm:mx-10'"
      :href="`${communityPath}/${option.path}`"
    >
      <div
        :class="twoRowsStyle? 'flex justify-center w-full': 'w-6 h-6 mr-4 '"
      >
        <!--<img
          :src="require(`../../assets/images/${option.icon.name}.svg`)"
          class="h-4 transition-colors duration-500 hover:border-b-2 border-gretBlack hover:border-white lg:border-b-0 text-white"
          :class="[`pb-${option.icon.pb}`, twoRowsStyle? 'h-4': `h-6 lg:h-${option.icon.height} lg:pb-0`]"
        >-->
        <gret-svg
          class="h-4 transition-colors duration-500 hover:border-b-2 border-gretBlack hover:border-white lg:border-b-0 text-white"
          :class="[`pb-${option.icon.pb}`, twoRowsStyle? 'h-4': `h-6 lg:h-${option.icon.height} lg:pb-0`]"
          :icon="option.icon.name"
        />
      </div>
      <div
        class="text-center transition-colors duration-500 border-b-4 font-semi-bold border-gret-black hover:border-white"
        :class="twoRowsStyle ? 'text-xs': 'hidden text-md lg:ml-2 lg:inline'"
      >
        {{ option.name }}
      </div>
    </a>
  </div>
</template>
<script>
import GretSvg from '../components/gret-svg.vue';

export default {
  components: { GretSvg },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    communityPath: {
      type: String,
      default: null,
    },
    twoRowsStyle: {
      type: Boolean,
      default: false,
    },
    xsVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
