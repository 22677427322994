<template>
  <a
    :href="fileUrl"
    @click="download"
    class="inline-flex flex-row items-center justify-between w-full h-12 px-2 mt-6 rounded opacity-75 cursor-pointer hover:opacity-100 gret-button downloadTwo sm:w-3/5 sm:h-2/3"
    download
  >
    <span
      class="flex-1 font-medium text-center text-gret normal-case xl:text-lg"
    >
      DESCARGAR
    </span>
  </a>
</template>

<script>
import ahoy from 'ahoy.js';
import { decamelizeKeys } from 'humps';

export default {
  name: 'FileDownloadButton',
  props: {
    filesize: {
      type: String,
      default: null,
    },
    fileUrl: {
      type: String,
      default: null,
    },
    fileId: {
      type: Number,
      default: null,
    },
    downloadEventIdentifier: {
      type: String,
      default: 'file_download',
    },
  },
  methods: {
    download(event) {
      if (this.fileUrl) {
        ahoy.track(this.downloadEventIdentifier, decamelizeKeys({ fileId: this.fileId }));
      } else {
        event.preventDefault();
        this.$root.$emit('showAccessModal');
      }
    },
  },
};
</script>

<style>

</style>
