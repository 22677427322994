<template>
  <div class="mb-2 flex items-center px-2.5 py-5 tracking-wider rounded-lg shadow-xl bg-gret-smallHub hover:shadow-white">
    <a 
      class="flex-1"
      :href="rootManagePath"  
    >
      <div class="flex-1 px-2.5 font-semibold text-white text-2xl leading-8 truncate hover:text-white">
        {{ communitys.name }} <br/>
        <label class="text-sm font-normal leading-4 tracking-wider">
          Estado: 
        </label>
      </div>
    </a>
    <!--<button 
      class="mr-3 inline-flex items-center justify-center p-2.5 text-xs font-medium tracking-wide text-white uppercase border-2 rounded cursor-pointer border-gret bg-gret hover:opacity-90"
      href="google.cl"  
    >
      Activar suscripción
    </button>-->
    <button class="mr-3 inline-flex items-center justify-center p-2.5 text-xs font-medium tracking-wide text-white uppercase border-2 rounded cursor-pointer border-gret-grayButton bg-gret-grayButton hover:opacity-90 hover:border-gray-500">
      Cancelar suscripción
    </button>
    <button class="inline-flex items-center justify-center p-2.5 text-xs font-medium tracking-wide text-white uppercase border-2 rounded cursor-pointer border-gret-grayButton bg-gret-grayButton hover:opacity-90 hover:border-gray-500">
      Eliminar combo
    </button>
  </div>  
</template>
  
<script>  
  export default {
    name: 'ManagedCommunities',
    props: {
      rootManagePath: {
        type: String,
        required: true,
      },
      communitys: {
        type: Array,
        default: null,
      },
    },
  };
  </script>
  