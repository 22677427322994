<template>
  <div class="relative flex items-center justify-center w-16 h-8 ">
    <input
      :id="id"
      type="checkbox"
      class="hidden"
      :value="value"
      @input="() => this.$emit('input', !value)"
    >
    <label
      :for="id"
      class="inline-block w-10 h-6 transition-colors duration-300 ease-in-out bg-gray-400 rounded-full shadow-inner cursor-pointer"
      :class="{'bg-gret': !value }"
    />
    <label
      :for="id"
      class="absolute inline-block w-6 h-6 transition-transform duration-300 ease-in-out bg-white rounded-full shadow cursor-pointer right-1"
      :class="{ 'transform -translate-x-full': value }"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: { type: Number, required: true },
    value: { type: Boolean, default: false },
  },
};
</script>
