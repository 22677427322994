<template>
  <a
    class="flex items-center p-2 rounded-lg shadow-xl bg-gret-gray md:p-3 hover:shadow-white"
    :href="hrefLink"
    target="_blank"
  >
    <div class="flex-1 font-medium text-gray-100 truncate hover:text-white">
      {{ textName }}
    </div>
    <img
      v-svg-inline
      class="ml-2 text-gray-100 fill-current hover:text-white"
      :src="require(`../../assets/images/keyboard_arrow_right.svg`)"
    >
  </a>
</template>
<script>
export default {
  name: 'HubLinkFront',
  props: {
    hrefLink: {
      type: String,
      required: true,
    },
    textName: {
      type: String,
      required: true,
    },
  },
};
</script>
