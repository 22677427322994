<template>
  <modal
    :open="open"
    @close="onClose"
  >
    <div class="p-8 text-center rounded bg-gret-gray-light">
      <div class="text-xl font-bold text-white ">
        {{ $t('message.bankAccountInformation.description') }}
      </div>
      <div class="mb-2">
        <label class="gret-dark-input-label text-gret">
          País
        </label>
        <select
          required
          v-if="countries"
          v-model="selectedCountryId"
          class="text-white border gret-dark-input border-gret-text-gray bg-gret-gray-light"
        >
          <option
            v-for="country in countries.countries"
            :key="country.id"
            :value="country.id"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
      <div
        v-for="field in fields"
        :key="field.id"
        class="flex flex-col mb-2"
      >
        <div class="flex items-start gret-dark-input-label text-gret">
          {{ field.label }}
        </div>
        <div>
          <input
            v-if="field.type=='text'"
            class="text-white border gret-dark-input border-gret-text-gray bg-gret-gray-light"
            type="text"
            v-model="bankAccountInformationResp[field.id]"
            required
          >
          <select
            v-if="field.type=='select'"
            class="text-white border gret-dark-input border-gret-text-gray bg-gret-gray-light"
            v-model="bankAccountInformationResp[field.id]"
            required
          >
            <option
              v-for="option in field.options"
              :key="option.id"
              :value="option.id"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
        <small
          class="text-white"
          v-if="field.hint"
        >
          {{ field.hint }}</small>
      </div>
      <button
        @click="saveBankAccountInformation"
        class="mt-2 gret-button small"
      >
        {{ $t('message.bankAccountInformation.save') }}
      </button>
      <button
        @click="open = false"
        class="mt-2 text-gret"
      >
        {{ $t('message.bankAccountInformation.cancel') }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from './modal.vue';
import countriesApi from '../api/countries';
import bankAccountInformationApi from '../api/bank-account-information';

export default {
  name: 'AddBankAccountInformationModal',
  components: {
    Modal,
  },
  props: {
    bankAccountInformation: { type: Object, default: null },
  },
  data() {
    return {
      open: true,
      loading: false,
      errors: null,
      countries: null,
      selectedCountryId: null,
      bankAccountInformationResp: {},
    };
  },
  computed: {
    selectedCountry() {
      return this.countries ?
        this.countries.countries.find(country => country.id === this.selectedCountryId) :
        null;
    },
    fields() {
      return this.selectedCountry ? this.selectedCountry.countryRequirements : [];
    },
  },
  methods: {
    onClose() {
      this.open = false;
      this.$emit('reload');
    },
    objectWithFieldIds(fieldIds, allFieldKeys) {
      fieldIds.reduce((total, current) => {
        if (!Object.keys(total).includes(current)) {
          total[current] = null;
        }

        return total;
      }, allFieldKeys);
    },
    setCountriesFields(countries) {
      let allFieldKeys = {};
      countries.forEach(country => {
        const fieldIds = country.countryRequirements.map((field) => field.id);
        const fieldIdsObj = this.objectWithFieldIds(fieldIds, allFieldKeys);
        allFieldKeys = { ...allFieldKeys, ...fieldIdsObj };
      });

      this.bankAccountInformationResp = Object.assign({}, this.bankAccountInformationResp, allFieldKeys);
    },
    createBankAccountInformation() {
      bankAccountInformationApi.create(
        { information: this.bankAccountInformationResp, countryId: this.selectedCountryId },
      ).then(() => {
        this.loading = false;
        this.onClose();
      })
        .catch((error) => {
          if (error.details) {
            this.errors = error.details;
          }
        });
    },
    updateBankAccountInformation() {
      bankAccountInformationApi.update(
        this.bankAccountInformation.id,
        { information: this.bankAccountInformationResp, countryId: this.selectedCountryId },
      ).then(() => {
        this.loading = false;
        this.onClose();
      })
        .catch((error) => {
          if (error.details) {
            this.errors = error.details;
          }
        });
    },
    async saveBankAccountInformation() {
      this.loading = true;
      if (this.bankAccountInformation) {
        await this.updateBankAccountInformation();
      } else {
        await this.createBankAccountInformation();
      }
    },

  },
  async beforeMount() {
    this.loading = true;

    countriesApi.getCountries()
      .then((response) => {
        this.countries = response;
        this.setCountriesFields(response.countries);
      })
      .catch((error) => {
        if (error.detils) {
          this.errors = error.details;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
