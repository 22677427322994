<template>
  <button
    class="relative p-2 bg-gray-300 outline-none text-gret-gray-light hover:text-gret-gray hover:bg-gray-400"
    :class="{ 'cursor-default': downloadId || downloadUrl }"
    @click="generateDownload"
    :disabled="downloadId || downloadUrl"
  >
    <div :class="{ 'invisible': downloadUrl || downloadId }">
      <slot />
    </div>
    <div
      v-if="downloadUrl || downloadId"
      class="absolute top-0 left-0 flex items-center justify-center w-full h-full"
    >
      <img
        v-if="downloadUrl"
        inline-svg
        class="w-10 h-10 text-gray-700 fill-current"
        src="../../assets/images/check.svg"
      >
      <spinner
        v-else-if="downloadId"
        class="w-4 h-4 m-1"
      />
    </div>
  </button>
</template>

<script>
import Spinner from './spinner';
import {
  createEventsCsvDownload,
  getCsvDownload,
} from '../api/csv-downloads';

const DOWNLOAD_POLLING_INTERVAL = 5000;
const RESET_TIMEOUT = 3000;

export default {
  name: 'FileEventsDownloadBase',
  components: { Spinner },
  props: {
    communityId: {
      type: Number,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
    fileId: {
      type: Number,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      downloadId: null,
      downloadUrl: null,
      pollingInterval: null,
    };
  },
  methods: {
    generateDownload() {
      createEventsCsvDownload(this.fileType, this.fileId, this.communityId, this.eventType)
        .then(({ csvDownload }) => {
          const { id } = csvDownload || {};
          if (id) {
            this.downloadId = id;
            this.startPolling();
          }
        });
    },
    getDownloadStatus() {
      if (this.downloadId) {
        getCsvDownload(this.downloadId, this.communityId)
          .then(({ csvDownload }) => {
            const { readyForDownload, url } = csvDownload || {};
            if (readyForDownload && url) {
              window.location.href = url;
              this.downloadUrl = url;
              this.downloadId = null;
              this.stopPolling();
              setTimeout(() => this.restart(), RESET_TIMEOUT);
            }
          });
      }
    },
    startPolling() {
      this.getDownloadStatus();
      this.pollingInterval = setInterval(this.getDownloadStatus, DOWNLOAD_POLLING_INTERVAL);
    },
    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    },
    restart() {
      this.downloadId = null;
      this.downloadUrl = null;
      this.stopPolling();
    },
  },
  beforeDestroy() {
    this.stopPolling();
  },
};
</script>
