import ahoy from 'ahoy.js';
import { decamelizeKeys } from 'humps';

const STREAM_DURATION = 60000;

export default {
  props: {
    trackPlaybackMetrics: {
      type: Boolean,
      default: false,
    },
    fileId: {
      type: Number,
      default: null,
    },
    playbackStartIdentifier: {
      type: String,
      default: null,
    },
    previouslyTrackedStart: {
      type: Boolean,
      default: null,
    },
    playbackStreamIdentifier: {
      type: String,
      default: null,
    },
    previouslyTrackedStream: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      trackedStart: this.previouslyTrackedStart,
      trackedStream: this.previouslyTrackedStream,
      millisecondsLeftForStream: STREAM_DURATION,
      streamTimeout: null,
      lastPlayTimestamp: null,
    };
  },
  methods: {
    trackFirstPlay() {
      if (!this.trackedStart) {
        ahoy.track(this.playbackStartIdentifier, decamelizeKeys({ fileId: this.fileId }));
        this.trackedStart = true;
      }
    },
    trackFirstStream() {
      if (!this.trackedStream) {
        ahoy.track(this.playbackStreamIdentifier, decamelizeKeys({ fileId: this.fileId }));
        this.trackedStream = true;
      }
    },
    setupPlaybackTrackers() {
      this.trackFirstPlay();
      if (this.millisecondsLeftForStream > 0) {
        this.lastPlayTimestamp = new Date();
        this.streamTimeout = setTimeout(this.trackFirstStream, this.millisecondsLeftForStream);
      }
    },
    clearStreamTimeoutAndRecalculateTimeLeftForStream() {
      clearTimeout(this.streamTimeout);
      this.millisecondsLeftForStream -= (new Date() - this.lastPlayTimestamp);
    },
  },
};
