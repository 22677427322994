<template>
  <dropdown
    dark
    button-class="bg-gret-smallHub shadow-md shadow-gray-500 flex justify-center items-center rounded-lg hover:shadow-white h-12 w-full text-white text-base font-bold px-8 py-3.5"
  >
    <template v-slot:button>
      <div class="flex items-center justify-center w-full uppercase">
        <div>{{ $t('message.series.new') }}</div>
        <gret-svg
          class="h-6 ml-2"
          icon="serie"
        />
      </div>
    </template>
    <template v-slot:menu>
      <div
        v-for="option in options"
        :key="`dropdown-item-${option}`"
        class="flex items-center px-3 py-2 rounded-md last:mb-0 hover:bg-gret-black text-gret-text-gray hover:text-white"
        @click="select(option)"
      >
        <div class="flex-1 capitalize whitespace-no-wrap">
          {{ $t(`message.files.${option}.type`) }}
        </div>
        <gret-svg
          class="w-6 h-6 ml-6"
          :icon="fileIcons[option].type"
        />
      </div>
    </template>
  </dropdown>
</template>
<script>
import fileIcons from '../../assets/json/file_icons.json';
import GretSvg from './gret-svg.vue';
import Dropdown from './dropdown.vue';

export default {
  name: 'ManageFeedSerieDropdown',
  components: {
    GretSvg,
    Dropdown,
  },
  data() {
    return {
      fileIcons,
      options: ['video', 'audio', 'text', 'service', 'product'],
    };
  },
  methods: {
    select(option) {
      this.$emit('change', option);
    },
  },
};
</script>
