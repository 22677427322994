export default {
  message: {
    shared: {
      cancel: 'Cancelar',
      delete: 'Eliminar',
      irreversibleAction: 'Esta acción es definitiva',
      continue: 'Continuar',
      registrationCongrats: 'Felicidades creaste tu primer combo',
      registrationSuccess: 'Ahora agrega el primer ítem a tu combo',
      registration: 'Agregar ítem'
    },
    navbar: {
      hello: 'Hola de nuevo',
      manage: 'Administrar',
      business: 'Mis combos',
      signout: 'Cerrar sesión',
      signin: 'Iniciar sesión',
      home: 'Inicio',
      videos: 'Videos',
      audios: 'Podcasts',
      documents: 'Biblioteca',
      userPlan: 'Mi Plan',
    },
    communities: {
      member: 'Eres miembro',
      join: 'Únete',
      inaccessibleContentWarning: {
        list: {
          main: 'Tus usuarios no pueden acceder a {count} de tus contenidos.',
          secondary: `Tienen una configuración de acceso de un plan más avanzado. Actualiza tu plan para que sean
            accesibles.`,
        },
        edit: {
          main: `Este contenido tiene una configuración no disponible en tu plan actual y no podrá ser visto por tus
            usuarios.`,
          secondary: 'Actualiza tu plan o cambia la opción de acceso del contenido',
        },
      },
      phoneNumber: 'Número de teléfono',
      phoneNumberExplanation: `Este número está definido a nivel del portal y se usa el mismo para todos
      los contenidos de este tipo. Puedes cambiarlo al`,
      edit: 'editar el portal',
      phoneNumberMissing: `No tienes configurado un número para tu portal. Puedes agregarlo a continuación
      y se usará el mismo número para todos los contenidos de este tipo`,
      privacy: {
        privateCommunity: 'Privado',
        publicCommunity: 'Público',
        privacyChange: 'Cambio de Portal',
        publicExplanation: `Permite a tus usuarios ingresar al portal y ver qué contenido has subido. Puedes configurar
        condiciones de acceso a cada contenido.`,
        privateExplanation: `Este portal requiere login. Solo podrán crear una cuenta para acceder al portal
        las personas que tu invites a través de la sección "Miembros".`,
        changeMessage: 'Estás cambiando desde un portal',
        successMainMessage: 'Se cambió la privacidad con éxito',
        successSecondaryMessage: 'Ahora tu portal es {privacy}',
        successSecondaryMessageDisclaimer: ' y podrás invitar personas desde "Miembros" ',
        privacyChangeWarning: 'Estás cambiando desde un portal {oldPrivacy} a uno {newPrivacy}.',
      },
    },
    payments: {
      yourCommision: 'Tus ganacias',
      noMovements: 'No existen movimientos aún',
      statuses: {
        pending: 'Pago pendiente',
        paid: 'Pagado',
        rejected: 'Rechazado',
        cancelled: 'Cancelado',
        expired: 'Expirado',
        authorized: 'Autorizado',
      },
      types: {
        singlePayment: 'Pago único',
      },
    },
    payouts: {
      requestPayout: 'Solicitar fondos',
      currentPayout: 'Retiro de fondos',
      lastPayout: 'Dinero en tránsito a tu banco',
      payoutsDisabled: 'No se pueden solicitar fondos hasta que se ingrese una cuenta bancaria.',
      payoutPending: 'Esperando autorización',
      noLastPayout: 'No se han registrado solicitudes de fondos',
      noPayout: 'No existen fondos para solicitar',
      payoutAfterFee: 'Saldo a retirar',
      fees: 'Comisiones',
      payoutBeforeFee: 'Saldo disponible para retiro',
      localAmountDisclaimer: `* El pago se realizará en moneda local y
      queda sujeto al valor del cambio en las siguentes 48 horas.`,
      settlementPeriodDisclaimer: ' Pagos recibidos hasta el ',
    },
    payoutTransitMoney: {
      requestedAmount: 'Monto solicitado',
      requestDate: 'Fecha de solicitud',
      requestedSince: 'Solicitado hace {hoursSinceLastPayout} horas',
      requestEmpty: 'No tienes dinero en tránsito a tu banco',
    },
    paymentList: {
      movements: 'Movimientos',
    },
    balanceDisclaimer: {
      title: 'Balance',
      description: 'Ventas realizadas y que podrás retirar. Tu dinero estará disponible para que puedas retirarlo en',
      descriptionSpan: '30 días a partir de la fecha en que hayas realizado la venta.',
      exampleTitle: 'Ejemplo',
      example: `Si vendes un contenido el 8 FEB a $10 y otros $10 el 9 FEB,
      el 8 MAR tu Balance será $20 y tu Saldo disponible para retiro $10`,
      understood: 'Entendido',
    },
    bankAccountInformation: {
      title: 'Datos bancarios',
      description: 'Ingresa tus datos bancarios',
      noInformation: 'No hay información de cuenta bancaria',
      addInformation: 'Ingresar',
      updateInformation: 'Actualizar',
      save: 'Guardar',
      cancel: 'Cancelar',
    },
    content: {
      accessibility: 'Seleccionar tipo de acceso al ítem',
      thisContent: 'Este contenido',
      accessibilityOptions: 'Condiciones de acceso disponibles',
      paymentTypeOptions: 'Tipos de pago disponibles',
      whatsappMessage: 'Mensaje inicial',
      whatsappMessageExplanation: `Es el mensaje que te enviarán tus usuarios al contactarte por Whatsapp a
      través de la plataforma`,
      singlePaymentPrice: 'Precio en dólares',
    },
    series: {
      new: 'Agrupar',
      newTyped: 'Agrupar',
      drag: 'Arrastra aquí el contenido que quieres agrupar',
      serie: 'Grupo | Grupos',
      series: 'Sin Grupos| {count} Grupo | {count} Grupos',
      noEpisodes: 'Sin Episodios',
      typedSerie: 'Grupo de {fileType}s',
      typedSeries: 'Sin Grupos| {count} Grupo de {fileType}s | {count} Grupos de {fileType}s',
      required: 'Obligatorio',
      title: 'Título del grupo de {fileType}s',
      titlePlaceholder: 'Mi grupo de {fileType}s ',
      description: 'Descripción breve',
      descriptionPlaceholder: 'Ingresa una breve descripción del grupo de {fileType}s',
      longDescription: 'Descripción detallada',
      longDescriptionPlaceholder: 'Ingresa una descripción detallada del grupo de {fileType}s',
      questions: 'Preguntas frecuentes',
      questionsPlaceholder: 'Ingresa dudas, preguntas frecuentes y sus respuestas',
      image: 'Imagen de portada',
      imagePreview: ' Esta aparecerá en la previsualización del grupo de {fileType}s',
      selectImage: 'Seleccionar imagen',
      create: 'Crear grupo de {fileType}s',
      update: 'Guardar grupo de {fileType}s',
      cancel: 'Cancelar',
      deleteConfirmation: '¿Estás seguro de eliminar esta serie?',
      accessConfiguration: {
        title: 'Configuración de acceso al contenido del Grupo',
        single: 'Cada contenido mantendrá su configuración previa',
        singleDescription: `Podrás agrupar contenidos, pero cada uno mantendrá la configuración de
                            acceso original. Te permite tener un grupo con contenidos con diferentes accesos.
                            Ej: Dos contenidos públicos y uno pagado.`,
        groupNotEnabled: '** Próximamente ** Configuración de grupo',
        group: 'Configuración de grupo',
        groupDescription: `Los contenidos pierden su configuración de acceso original y
                           obedecen la configuración que definas para el grupo. Quienes
                           cumplan la condición podrán acceder a todos los contenidos del
                           grupo de una vez. Ideal para quienes ofrecen cursos con varios episodios.`,
      },

    },
    files: {
      alls: 'Todo',
      videos: 'Videos',
      audios: 'Audios',
      texts: 'Archivos',
      services: 'Servicios',
      products: 'Productos',
      subscription: 'Suscripción',
      subscribersOnly: 'Sólo para suscriptores',
      public: 'Público',
      privacy: {
        registeredOnly: 'Registro',
        public: 'Público',
        manualAuthorization: 'Whatsapp',
        byInvitation: 'Invitación',
        singlePayment: 'Pago único',
      },
      longDescription: 'Descripción',
      content: 'Contenido',
      questions: 'Preguntas',
      required: 'Obligatorio',
      deleteConfirmation: '¿Estás seguro de eliminar ',
      audio: {
        type: 'Audio',
        play: 'Escuchar',
        episodes: 'Sin Audios| {count} Audio | {count} Audios',
        sections: {
          home: 'Audios',
          recent: 'Nuevos audios',
          files: 'Todos los audios',
          episodes: 'Audios',
        },
      },
      service: {
        type: 'Servicio',
        play: 'Ver',
        episodes: 'Sin servicios| {count} Servicio | {count} Servicios',
        sections: {
          home: 'Servicios',
          recent: 'Nuevos servicios',
          files: 'Todos los servicios',
          episodes: 'Servicios',
        },
      },
      product: {
        type: 'Producto',
        play: 'Ver',
        episodes: 'Sin productos| {count} Producto | {count} Productos',
        sections: {
          home: 'Productos',
          recent: 'Nuevos productos',
          files: 'Todos los productos',
          episodes: 'Productos',
        },
      },
      home: {
        type: 'Todo',
      },
      text: {
        type: 'Archivo',
        play: 'Ver',
        episodes: 'Sin Archivos| {count} Archivo  | {count} Archivos',
        sections: {
          home: 'Archivos',
          recent: 'Nuevos archivos',
          files: 'Todos los archivos',
          episodes: 'Archivos',
        },
      },
      video: {
        type: 'video',
        title: 'Título del video',
        titlePlaceholder: 'Mi video',
        subtitle: 'Presentador del video',
        subtitlePlaceholder: 'Nombre del presentador del video',
        duration: 'Duración en minutos',
        description: 'Descripción breve del video',
        descriptionPlaceholder: 'Ingresa una breve descripción del video',
        details: 'Detalles del video',
        play: 'Ver',
        changeFile: 'Haz click para cambiar el archivo',
        uploadSuccess: 'Subido correctamente',
        uploadError: 'Ha ocurrido un error, por favor intenta de nuevo',
        uploading: 'Subiendo video...',
        fieldError: 'Debes completar todos los campos requeridos',
        next: 'Siguiente',
        episodes: 'Sin Videos| {count} Video  | {count} Videos',
        sections: {
          home: 'Videos',
          recent: 'Nuevos videos',
          files: 'Todos los videos',
          episodes: 'Videos',
        },
        uploadWarning: 'Para que se haga efectivo el cambio debes hacer click en GUARDAR VIDEO',
        replaceVideo: 'Haz click para reemplazar el video anterior',
        uploadVideo: 'Haz click para seleccionar un video',

      },
      now: 'ahora',
    },
    members: {
      downloaded: 'Descarga completa',
      downloading: 'Generando descarga...',
      download: 'Descargar miembros',
    },
    dnsConfig: {
      domainLabel: 'Dominio *',
      subdomainLabel: '¿Qué nombre quieres que tenga tu dominio? *',
      updateWarning: `Gret puede demorar hasta 4 horas aprox para redirigir a tu dominio,
      si esto no funciona contacta a contacto@gret.io para recibir ayuda.`,
      domainInstructions: `Tu solicitud ha sido aceptada! Para terminar de configurar el dominio debes entrar a tu
      proveedor de DNS (donde tengas configurado tu dominio).
      Puedes hacerlo en goDaddy, Cloudfare,  Google domains, NameCheap, BlueHost u otro)
      y agregar el siguiente record.`,
      recordType: 'Record type',
      name: 'Name',
      value: 'Valor',
      dnsProviderInstructions: `A continuación encuentras instrucciones de cómo
      agregar un record CNAME en algunos proveedores:`,
      dnsSent: 'Solicitud enviada',
    },
  },
  footer: {
    text: '⭐ Crea tu propio portal como este con Gret',
  },
  validationErrors: {
    required: 'No puede estar vacío',
    email: 'El email no es válido',
  },
};
