<template>
  <div>
    <label class="block mb-1">
      <span class="mb-1 gret-dark-input-label">País</span>
      <select
        name="payment[country_id]"
        class="shadow-xl gret-dark-input border-gret-gray bg-gret-gray-light hover:shadow-white"
        @change="e => $emit('country-change', e.target.value)"
        v-model="countryId"
      >
        <option
          v-for="{ id, name: countryName } in countryOptions"
          :key="id"
          :value="id"
        >
          {{ countryName }}
        </option>
      </select>
    </label>
    <gret-input
      type="text"
      v-model="name"
      name="payment[name]"
      id="name"
      label="Nombre"
      class="mb-6"
      rules="required"
    />
    <gret-input
      type="text"
      v-model="phone"
      name="payment[phone]"
      id="phone"
      label="Teléfono"
      class="mb-6"
      rules="required"
    />
    <gret-input
      type="text"
      v-model="document"
      name="payment[document]"
      id="document"
      label="Documento Nacional de Identidad"
      class="mb-6"
      rules="required"
    />
  </div>
</template>

<script>
export default {
  props: {
    countryOptions: { type: Array, required: true },
    initialCountryId: { type: Number, required: true },
    initialUserName: { type: String, required: true },
  },
  data() {
    return {
      countryId: this.initialCountryId,
      name: this.initialUserName,
      phone: '',
      document: '',
    };
  },
};
</script>
