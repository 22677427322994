<template>
  <modal
    :open="open"
    @close="onClose"
  >
    <div class="flex flex-col p-8 pt-16 space-y-6 bg-gret-gray-dark">
      <div class="mb-5 text-2xl text-center text-white">
        <span class="font-bold"> {{ $t('message.communities.privacy.privacyChange') }}:</span>
        {{ $t('message.communities.privacy.privateCommunity') }} vs
        {{ $t('message.communities.privacy.publicCommunity') }}
      </div>
      <div class="flex flex-row">
        <div class="flex flex-col items-start mr-2">
          <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'w-10 h-10 fill-current '" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect x="24" y="23.5" width="23" height="23" rx="11.5" transform="rotate(180 24 23.5)" fill="#2679FF"/><g filter="url(#filter0_dd_1439_10276)"><ellipse cx="12.455" cy="12" rx="9" ry="9.036" fill="#fff"/></g><g clip-path="url(#clip0_1439_10276)"><path d="M12.462 14.672a1.066 1.066 0 000-2.132 1.067 1.067 0 000 2.132zm3.184-4.795h-.53V8.81a2.66 2.66 0 00-2.654-2.664A2.66 2.66 0 009.81 8.811v1.066h-.53c-.584 0-1.062.48-1.062 1.065v5.328c0 .586.478 1.065 1.061 1.065h6.368c.584 0 1.062-.48 1.062-1.065v-5.328c0-.586-.478-1.065-1.062-1.065zM10.817 8.81a1.65 1.65 0 011.645-1.651c.908 0 1.645.74 1.645 1.651v1.066h-3.29V8.81zm4.83 7.459H9.277v-5.328h6.368v5.328z" fill="#334155"/></g><defs><clipPath id="clip0_1439_10276"><path fill="#fff" transform="translate(6.094 5.615)" d="M0 0h12.736v12.786H0z"/></clipPath><filter id="filter0_dd_1439_10276" x=".455" y=".964" width="24" height="24.071" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1439_10276"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="1.5"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/><feBlend in2="effect1_dropShadow_1439_10276" result="effect2_dropShadow_1439_10276"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_1439_10276" result="shape"/></filter></defs></svg>
        </div>
        <div class="flex flex-col">
          <p class="font-bold text-gret">
            {{ $t('message.communities.privacy.publicCommunity') }}
          </p>
          <p class="text-white">
            {{ $t('message.communities.privacy.publicExplanation') }}
          </p>
        </div>
      </div>

      <div class="flex flex-row">
        <div class="flex flex-col self-start mr-2">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'w-10 h-10 fill-current'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect x="1" y=".5" width="22" height="22" rx="11" fill="#E2E8F0"/><g filter="url(#filter0_dd_1439_10268)"><circle cx="11.861" cy="11.361" r="8.861" fill="#fff"/></g><g clip-path="url(#clip0_1439_10268)"><path d="M11.959 13.857a1.04 1.04 0 001.037-1.037 1.04 1.04 0 00-1.037-1.038 1.04 1.04 0 00-1.038 1.038c0 .57.467 1.037 1.038 1.037zm3.113-4.67h-.519V8.15a2.595 2.595 0 00-5.189 0h.986A1.61 1.61 0 0111.96 6.54a1.61 1.61 0 011.608 1.609v1.038H8.846a1.04 1.04 0 00-1.038 1.037v5.189c0 .57.467 1.038 1.038 1.038h6.226a1.04 1.04 0 001.038-1.038v-5.189a1.04 1.04 0 00-1.038-1.037zm0 6.227H8.846v-5.189h6.226v5.189z" fill="#334155"/></g><defs><clipPath id="clip0_1439_10268"><path fill="#fff" transform="translate(5.732 5.037)" d="M0 0h12.453v12.453H0z"/></clipPath><filter id="filter0_dd_1439_10268" x="0" y=".5" width="23.722" height="23.722" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1439_10268"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="1.5"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/><feBlend in2="effect1_dropShadow_1439_10268" result="effect2_dropShadow_1439_10268"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_1439_10268" result="shape"/></filter></defs></svg>
        </div>
        <div class="flex-col">
          <p class="font-bold text-gret">
            {{ $t('message.communities.privacy.privateCommunity') }}
          </p>
          <p class="text-white">
            {{ $t('message.communities.privacy.privateExplanation') }}
          </p>
        </div>
      </div>

      <div class="flex flex-row items-center p-3 mb-4 space-x-2 border rounded-md border-gret bg-gret-gray-light">
        <div class="mr-1">
          <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'w-8 h-8 text-sm fill-current'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 24h29L14.5 0 0 24zm15.818-3.79h-2.636v-2.526h2.636v2.526zm0-5.052h-2.636v-5.053h2.636v5.053z" fill="#F4BF1A"/></svg>
        </div>
        <div class="text-white">
          {{ $t('message.communities.privacy.privacyChangeWarning',
                {oldPrivacy: privacyText(newPrivate),
                 newPrivacy: privacyText(!newPrivate)
                })
          }}
        </div>
      </div>
      <div class="flex flex-row items-center justify-between text-white">
        <button
          class="text-lg"
          @click="onClose()"
        >
          {{ $t('message.shared.cancel') }}
        </button>
        <button
          class="gret-button small"
          @click="changePrivacy()"
        >
          {{ $t('message.shared.continue') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from './modal.vue';
import communitiesApi from '../api/community';

export default {
  name: 'ChangePrivacyModal',
  components: {
    Modal,
  },
  props: {
    newPrivate: {
      type: Boolean,
      required: true,
    },
    communityId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      open: true,
    };
  },
  methods: {
    onClose() {
      this.open = false;
      this.$emit('close');
    },
    onChangeSuccess() {
      this.$emit('success');
    },
    changePrivacy() {
      communitiesApi.update(
        this.communityId,
        { private: this.newPrivate },
      ).then(() => {
        this.loading = false;
        this.onChangeSuccess();
      })
        .catch((error) => {
          if (error.details) {
            this.errors = error.details;
          }
        });
    },
    privacyText(privacy) {
      return privacy ?
        this.$t('message.communities.privacy.publicCommunity') :
        this.$t('message.communities.privacy.privateCommunity');
    },
  },
};
</script>
