import api from './index';

export default {
  create(params) {
    return api({
      method: 'POST',
      url: '/api/v1/bank_account_informations',
      data: { bankAccountInformation: params },
    });
  },
  update(id, params) {
    return api({
      method: 'PATCH',
      url: `/api/v1/bank_account_informations/${id}`,
      data: { bankAccountInformation: params },
    });
  },
};
